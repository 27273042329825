<form #f="ngForm" (ngSubmit)="onSubmit(f)">
    <input type="hidden" name="id" [(ngModel)]="quote.id">
    <div class="row">
        <div class="col-md-8">
            <div class="form_sections">
                <div class="row">
                    <div class="col-md-6">
                        <h6>Quote Details</h6>
                        <div class="form-group w-100">
                            <input type="text" readonly required name="policy_start_date" [disabled]="quote_type == 6"
                                [(ngModel)]="quote.quote_versions[0].policy_start_date" #policy_start_date="ngModel"
                                placeholder="Policy Start Date" class="form-control form-control-sm datepicker"
                                [ngClass]="{ 'is-invalid': f.submitted && policy_start_date.invalid }" matInput
                                (focus)="myDatepicker.open()" [matDatepicker]="myDatepicker" [min]="minPolicyDate"
                                [max]="maxPolicyDate" (dateChange)="onPolicyStartDateChange($event)">
                            <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #myDatepicker></mat-datepicker>
                            <div *ngIf="f.submitted && policy_start_date.invalid" class="invalid-feedback">
                                Policy date is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" *ngIf="!isBroker">
                            <mat-form-field class="mt-4 w-100">
                                <mat-label>Broker</mat-label>
                                <mat-select [(ngModel)]="quote.company_id" class="form-control form-control-sm"
                                    name="broker_id" (selectionChange)="onChangeBroker($event)"
                                    [ngClass]="{ 'is-invalid': f.submitted && broker.invalid }" #broker="ngModel"
                                    required [disabled]="quote_type == 3 || quote_type == 4 || quote_type == 5 ||  quote_type == 6">
                                    <mat-option [value]="">Select Broker</mat-option>
                                    <mat-option *ngFor="let brk of brokers" [value]="brk.id">
                                        {{brk.name}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="f.submitted && broker.invalid" class="invalid-feedback">
                                    Broker field is required.
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"
                        *ngIf="quote_type == 3 || quote_type == 4 || quote_type == 5 ||  quote_type == 6 || QuoteTypeVisibility">
                        <mat-form-field class="w-100">
                            <mat-label>Transaction Type</mat-label>
                            <mat-select [(ngModel)]="quote_type" class="form-control form-control-sm" name="quote_type"
                                [disabled]="!QuoteTypeVisibility"
                                (selectionChange)="onQuoteTypeChange($event)">
                                <mat-option [value]="1">New Business</mat-option>
                                <mat-option [value]="2">Renewal</mat-option>
                                <mat-option [value]="3" [hidden]="QuoteTypeVisibility">MTA</mat-option>
                                <mat-option [value]="4" [hidden]="QuoteTypeVisibility">Cancelled</mat-option>
                                <mat-option [value]="5" [hidden]="QuoteTypeVisibility">Lapsed</mat-option>
                                <mat-option [value]="6" [hidden]="QuoteTypeVisibility">NTU</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6" *ngIf="quote_type == 3 || quote_type == 4 || quote_type == 5">
                        <mat-form-field class="w-100">
                            <mat-label>Policy Reason</mat-label>
                            <mat-select [(ngModel)]="quote.quote_versions[0].mta_reason_id"
                                class="form-control form-control-sm" name="mta_reason_id"
                                [ngClass]="{ 'is-invalid': f.submitted && mta_reason_id.invalid }"
                                #mta_reason_id="ngModel" required>
                                <mat-option *ngFor="let rsn of policyReasons" [value]="rsn.id">
                                    {{rsn.reasons}}
                                </mat-option>
                                <mat-option [value]="1">Other</mat-option>
                            </mat-select>
                            <div *ngIf="f.submitted && mta_reason_id.invalid" class="invalid-feedback">
                                Please select a specific reason.
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 mt-2" *ngIf="(quote_type == 1 || quote_type == 2) && !isBroker &&  tigerlabfield">
                        <input type="text" name="tigerlab_policy_reference" placeholder="Legacy Policy Reference"
                            class="form-control form-control-sm"
                            [(ngModel)]="quote.quote_versions[0].tigerlab_policy_reference"
                            [ngClass]="{ 'is-invalid': f.submitted && tigerlab_policy_reference.invalid }"
                            #tigerlab_policy_reference="ngModel" required>
                        <div *ngIf="f.submitted && tigerlab_policy_reference.invalid" class="invalid-feedback">
                            Legacy policy reference is required.
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="quote.quote_versions[0].mta_reason_id == '1'">
                    <div class="col-md-12">
                        <div class="form-group">
                            <textarea name="other_reason" placeholder="Please specify reason" class="form-control"
                                [(ngModel)]="quote.quote_versions[0].mta_reason_other"
                                [ngClass]="{ 'is-invalid': f.submitted && other_reason.invalid }"
                                #other_reason="ngModel" required></textarea>
                            <div *ngIf="f.submitted && other_reason.invalid" class="invalid-feedback">
                                Please provide a specific reason.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_sections">
                <h6>Personal Details</h6>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="first_name" placeholder="First Name"
                                class="form-control form-control-sm"
                                (keyup)="firstnameToTotleCase($event)"
                                [(ngModel)]="quote.quote_versions[0].policy_holder.first_name"
                                [ngClass]="{ 'is-invalid': f.submitted && first_name.invalid }" #first_name="ngModel"
                                required appTitleCase [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6">
                            <div *ngIf="f.submitted && first_name.invalid" class="invalid-feedback">
                                First Name is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="last_name" placeholder="Last Name"
                                class="form-control form-control-sm"
                                (keyup)="lastnameToTotleCase($event)"
                                [(ngModel)]="quote.quote_versions[0].policy_holder.last_name"
                                [ngClass]="{ 'is-invalid': f.submitted && last_name.invalid }" #last_name="ngModel"
                                required appTitleCase [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6">
                            <div *ngIf="f.submitted && last_name.invalid" class="invalid-feedback">
                                Lastname is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="email" name="email" required
                                [(ngModel)]="quote.quote_versions[0].policy_holder.email" #email="ngModel"
                                pattern="^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$"
                                [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
                                class="form-control form-control-sm" placeholder="Email"
                                [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6"
                                oninput="this.value = this.value.toLowerCase()">
                            <div *ngIf="f.submitted && email.errors && (email.invalid || email.touched)"
                                class="invalid-feedback">
                                <div *ngIf="email.invalid && !email.touched">Email is required.</div>
                                <div *ngIf="email.errors.pattern && email.touched">Please provide a valid email address
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input required type="text" name="phone" placeholder="Contact Number"
                                class="form-control form-control-sm"
                                [(ngModel)]="quote.quote_versions[0].policy_holder.phone" #phone="ngModel"
                                [ngClass]="{ 'is-invalid': f.submitted && phone.invalid }"
                                [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6">
                            <div *ngIf="f.submitted && phone.invalid" class="invalid-feedback">
                                Contact number is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" required name="dob" readonly
                                [(ngModel)]="quote.quote_versions[0].policy_holder.dob" #dob="ngModel" placeholder="DOB"
                                class="form-control form-control-sm datepicker" matInput (focus)="dobDatepicker.open()"
                                [matDatepicker]="dobDatepicker" [ngClass]="{ 'is-invalid': f.submitted && dob.invalid }"
                                [max]="dob_max" [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6">
                            <mat-datepicker-toggle [for]="dobDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #dobDatepicker></mat-datepicker>
                            <div *ngIf="f.submitted && dob.invalid" class="invalid-feedback">
                                DOB is required.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_sections">
                <h6>Horse Details</h6>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="horse_name" placeholder="Horse Name"
                                class="form-control form-control-sm"
                                (keyup)="horsenameToTotleCase($event)"
                                [(ngModel)]="quote.quote_versions[0].horse_details.name" #horse_name="ngModel" required
                                [ngClass]="{ 'is-invalid': f.submitted && horse_name.invalid }" appTitleCase
                                [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6">
                            <div *ngIf="f.submitted && horse_name.invalid" class="invalid-feedback">
                                Horse name is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="text" appNumbers name="horse_value" placeholder="Horse Value"
                                    class="form-control form-control-sm" autocomplete="off"
                                    [(ngModel)]="quote.quote_versions[0].horse_details.value"
                                    (blur)="horseValueChange($event)" #horse_value="ngModel" required
                                    [ngClass]="{ 'is-invalid': f.submitted && horse_value.invalid }"
                                    [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6">
                            </div>

                            <div *ngIf="f.submitted && horse_value.invalid" class="invalid-feedback">
                                Horse value is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field class="w-100">
                            <mat-label>Horse Age</mat-label>
                            <mat-select [(ngModel)]="quote.quote_versions[0].horse_details.age"
                                class="form-control form-control-sm" name="horse_age"
                                (selectionChange)="onHorseAgeChange($event)" #horse_age="ngModel" required
                                [ngClass]="{ 'is-invalid': f.submitted && horse_age.invalid }"
                                [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6">
                                <mat-option [value]="">Horse Age</mat-option>
                                <mat-option *ngFor="let age of horseAge" [value]="age">
                                    {{age}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="f.submitted && horse_age.invalid" class="invalid-feedback">
                                Horse age is required.
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="form_sections">
                <h6>Main Horse Use</h6>
                <mat-radio-group class="row" aria-label="Select an option" name="horse_class_id"
                    [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6"
                    [(ngModel)]="quote.quote_versions[0].horse_class_id" (change)="classOfUseOnChange($event)">
                    <div class="col-md-4" *ngFor="let uses of classOfUse; let idx = index">
                        <div class="quote_radio w-90 mb-3">
                            <mat-radio-button [value]="uses.id">{{uses.name}}</mat-radio-button>
                        </div>
                        <span class="ml-1" (click)="helpTextPopup(content,uses.tooltip)"><i
                                class="mdi mdi-comment-question-outline"></i></span>
                    </div>
                </mat-radio-group>
                <div class="form-group row" *ngIf="!BloodStockDetails">
                    <div class="col-md-8">
                        <mat-form-field class="w-100">
                            <mat-label>Primary Cover</mat-label>
                            <mat-select [(ngModel)]="quote.quote_versions[0].horse_class_sub_id"
                                class="form-control form-control-sm" name="horse_class_sub_id"
                                [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6"
                                (selectionChange)="horseSubClassOnChange($event)" #horse_class_sub_id="ngModel" required
                                [ngClass]="{ 'is-invalid': f.submitted && horse_class_sub_id.invalid }">
                                <mat-option [value]="">Please Select Primary Cover</mat-option>
                                <mat-option *ngFor="let subClass of horseSubClasses" [value]="subClass.id">
                                    {{subClass.name}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="f.submitted && horse_class_sub_id.invalid" class="invalid-feedback">
                                Primary cover is required.
                            </div>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="BloodStockDetails">
                    <div class="row">
                        <div class="col-md-8">
                            <mat-form-field class="w-100">
                                <mat-label>Bloodstock</mat-label>
                                <mat-select [(ngModel)]="quote.quote_versions[0].blood_stock_id"
                                    class="form-control form-control-sm" name="blood_stock_id"
                                    (selectionChange)="onBloodStockChange($event)" #blood_stock_id="ngModel" required
                                    [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6"
                                    [ngClass]="{ 'is-invalid': f.submitted && blood_stock_id.invalid }">
                                    <mat-option [value]="">Please Select Use</mat-option>
                                    <mat-option *ngFor="let bloodStock of bloodStocks" [value]="bloodStock.id">
                                        {{bloodStock.name}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="f.submitted && blood_stock_id.invalid" class="invalid-feedback">
                                    Bloodstock is required.
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="form_sections">
                <h6>Policy Cover Details</h6>
                <mat-radio-group class="row" aria-label="Select an option" name="policy_cover_id"
                    [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6"
                    [(ngModel)]="quote.quote_versions[0].policy_cover_id" (change)="policyCoverChange($event)">
                    <div class="col-md-6" *ngFor="let policy of policyCover; let idx = index">
                        <div
                            [hidden]="policy.cover_index == 2 && (BloodStockDetails || (quote.quote_versions[0].horse_details.age >=14 && quote_type != 2) || (quote.quote_versions[0].horse_details.age >=15 && quote_type == 2))">
                            <div class="quote_radio w-90 mb-3">
                                <mat-radio-button [value]="policy.id">{{policy.name}}</mat-radio-button>
                            </div>
                            <span class="ml-1" (click)="helpTextPopup(content,policy.help_text)"><i
                                    class="mdi mdi-comment-question-outline"></i></span>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
            <div class="form_sections" *ngIf="!BloodStockDetails">
                <h6>Vet Fees Cover</h6>
                <div class="row">
                    <div class="col-md-6"
                        [hidden]="quote.quote_versions[0].horse_details.age > 21 && vetFees.vet_fee_cover_index == 3"
                        [class.disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6"
                        *ngFor="let vetFees of vetFeesCover; let i = index;">
                        <div class="form-group" (click)="onCheckboxChange(vetFees.id, vetFeesCover)">
                            <mat-checkbox name="vet_fees_cover_{{i}}"
                                (change)="onVetFeeCoverChange(vetFees.id,vetFeesCover, $event)" value="{{vetFees.id}}"
                                [(ngModel)]="vetFees.is_checked" [checked]="vetFees.is_checked">
                                {{vetFees.description}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="quote.quote_versions[0].horse_details.age < 22">
                <div [class.disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6"
                    [hidden]="(vetFees.life_saving_index == 1 && !BloodStockDetails) "
                    *ngFor="let vetFees of vetFeeCoverSpecial">
                    <div class="form_sections" *ngIf="vetFees.life_saving_index == 1">
                        <div class="row">
                            <div class="col-md-8 quote_radio">
                                <h6> Life Saving Surgery/Colic Surgery</h6>
                                <mat-checkbox name="vet_fees_coverspecial_life_saving" value="{{vetFees.id}}"
                                    [(ngModel)]="vetFees.is_checked"
                                    (change)="onvetFeeCoverSpecialChange(vetFees.life_saving_index,vetFees.vet_indemnity_data.value,$event,vetFees.description)">
                                    {{vetFees.description}}
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="form_sections" *ngIf="vetFees.life_saving_index == 2 && !BloodStockDetails"
                        [hidden]="quote.quote_versions[0].horse_details.age >=17">
                        <div class="row">
                            <div class="col-md-8 quote_radio">
                                <h6> Life Saving Surgery/Colic Surgery</h6>
                                <mat-checkbox name="vet_fees_coverspecial_top_up" value="{{vetFees.id}}"
                                    [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6"
                                    [(ngModel)]="quote.quote_versions[0].life_saving_annual_surgery_id"
                                    [checked]="(quote.quote_versions[0].horse_details.value > 2500 && vetFeeCoverIndex == 2  && vetFees.life_saving_index == 2) ?true:vetFees.is_checked"
                                    (change)="onvetFeeCoverSpecialChange(vetFees.life_saving_index,vetFees.vet_indemnity_data.value,$event,vetFees.description)">
                                    {{vetFees.description}}
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_sections" *ngIf="!BloodStockDetails" [hidden]="quote.LifeSaving7500">
                <h6>Vet Fee Excess</h6>
                <div class="row" [class.disabled]="disableVetFeeExcess">
                    <div class="col-md-3 quote_radio" [class.disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6"
                        [hidden]="vetFeeCoverIndex == 3 && i==0" *ngFor="let excess of vetFeeExcess; let i = index">
                        <div class="form-group" (click)="onCheckboxChange(excess.id, vetFeeExcess)">
                            <mat-checkbox name="company_excess_{{i}}" value="{{excess.id}}"
                                [checked]="excess.is_checked" [(ngModel)]="excess.is_checked"
                                (ngModelChange)="excess.is_checked"
                                (change)="vetFeeExcessOnChange(excess.discount, $event, excess.excess_amount)">
                                &pound;{{excess.excess_amount}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_sections" *ngIf="!BloodStockDetails">
                <h6>Co-Insurance Towards Lameness Diagnostics</h6>
                <div class="form-group quote_radio">
                    <mat-checkbox name="company_co_pay_excess_id" [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6"
                        value="{{quote.quote_versions[0].company_co_pay_excess_id}}"
                        [checked]="quote.company_co_insurance" (change)="onCoInsuranceChange($event)">
                        Add 20%
                        co-insurance for lameness diagnostics
                    </mat-checkbox>
                </div>
            </div>
            <div class="form_sections">
                <h6 class="mt-4"> Public Liability Cover <small>(£250 Excess Applies)</small></h6>
                <div class="row">
                    <div class="col-md-3 quote_radio" [class.disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6"
                        *ngFor="let liability of publicLiability; let i = index;">
                        <div class="form-group" (click)="onCheckboxChange(liability.id, publicLiability)">
                            <mat-checkbox name="public_liability_cover_{{i}}" value="{{liability.id}}"
                                [(ngModel)]="liability.is_checked"
                                (change)="onPublicLiabilityChange(liability.addon_public_personal_data.value, $event,liability.description)">
                                {{liability.description}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_sections">
                <h6>Personal Accident Cover <small>(Additional £1500 Dental Cover applies) </small></h6>
                <div class="row">
                    <div class="col-md-4 quote_radio" [class.disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6"
                        *ngFor="let accident of accidentCover; let i = index;">
                        <div class="form-group" (click)="onCheckboxChange(accident.id, accidentCover)">
                            <mat-checkbox name="personal_accident_cover_{{i}}" value="{{accident.id}}"
                                [(ngModel)]="accident.is_checked"
                                (change)="onPersonalAccidentChange(accident.addon_public_personal_data.value, $event,accident.description)">
                                {{accident.description}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_sections">
                <h6>Saddlery and Tack <small>(£100 Standard Excess applies) </small></h6>
                <div class="form-group">
                    <label class="toggle-switch toggle-switch-dark">
                        <input type="checkbox" checked="" name="does_addon_saddlery_on"
                            [(ngModel)]="quote.quote_versions[0].does_addon_saddlery_on"
                            (change)="saddlery_tack($event)" [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6">
                        <span class="toggle-slider round"></span>
                    </label>
                </div>
                <div class="form-group row" *ngIf="quote.quote_versions[0].does_addon_saddlery_on">
                    <div class="col-md-4">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-success text-white">&pound;</span>
                            </div>
                            <input type="text" name="addon_saddlery_value"
                                [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6"
                                [(ngModel)]="quote.quote_versions[0].addon_saddlery_value"
                                class="form-control form-control-sm" placeholder="Value"
                                (change)="onChangeSaddlery($event)" appDecimalNumbers>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_sections">
                <h6 class="mt-4">Trailer and Horsedrawn Vehicle Cover <small>(£250 Standard Excess Applies)</small></h6>
                <div class="form-group">
                    <label class="toggle-switch toggle-switch-dark">
                        <input type="checkbox" name="does_addon_trailer_on"
                            [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6"
                            [(ngModel)]="quote.quote_versions[0].does_addon_trailer_on"
                            (change)="trailer_cover($event)">
                        <span class="toggle-slider round"></span>
                    </label>
                </div>
                <div class="form-group row" *ngIf="quote.quote_versions[0].does_addon_trailer_on">
                    <div class="col-md-4">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-success text-white">&pound;</span>
                            </div>
                            <input type="text" name="addon_trailer_value" class="form-control form-control-sm"
                                placeholder="Value" [(ngModel)]="quote.quote_versions[0].addon_trailer_value"
                                (change)="onChangeTrailer($event)" appDecimalNumbers
                                [disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6">
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_sections">
                <h6 class="mt-4">Commission</h6>
                <div class="form-group row">
                    <div class="col-md-4">Broker Comission</div>
                    <div class="col-md-6">
                        <div *ngIf="quote_type == 4 || quote_type == 3 || quote_type == 5 ||  quote_type == 6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">%</span>
                                </div>
                                <input type="text" readonly class="form-control form-control-sm"
                                    [value]="quote.quote_versions[0].financial_details.broker_commission">
                            </div>
                        </div>
                        <div *ngIf="quote_type != 4 && quote_type != 3 && quote_type != 5 && quote_type != 6">
                            <mat-slider name="broker_commission" thumbLabel
                                [value]="quote.quote_versions[0].financial_details.broker_commission"
                                [(ngModel)]="quote.quote_versions[0].financial_details.broker_commission"
                                (change)="onBrokerComissionChange($event)" [displayWith]="formatSliderLabel" min="0"
                                max="{{slider_broker_comm_max}}" step="0.5">
                            </mat-slider>
                        </div>
                    </div>
                </div>

                <div *ngIf="visibleOnlytoAdmin">
                    <div class="row form-group">
                        <div class="col-md-4">Peliwica pay away commission</div>
                        <div class="col-md-6">
                            <div *ngIf="quote_type == 4 || quote_type == 3 || quote_type == 5 || quote_type == 6">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">%</span>
                                    </div>
                                    <input type="text" readonly class="form-control form-control-sm"
                                        [value]="quote.quote_versions[0].financial_details.peliwica_pay_away_commission">
                                </div>
                            </div>
                            <div *ngIf="quote_type != 4 && quote_type != 3 && quote_type != 5 && quote_type != 6">
                                <mat-slider name="peliwica_pay_away_commission" thumbLabel
                                    [value]="quote.quote_versions[0].financial_details.peliwica_pay_away_commission"
                                    [(ngModel)]="quote.quote_versions[0].financial_details.peliwica_pay_away_commission"
                                    [displayWith]="formatSliderLabel" min="0" max="{{max_ppa_comission}}" step="0.5"
                                    (change)="onPPayAwayComissionChange($event)">
                                </mat-slider>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-4">Peliwica pay away gross commission</div>
                        <div class="col-md-6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>

                                <input type="text" name="payaway_gross_commision" placeholder="Gross Commission"
                                    class="form-control form-control-sm"
                                    [disabled]="quote_type == 4 || quote_type == 3 || quote_type == 5 || quote_type == 6"
                                    [value]="(quote.quote_versions[0].financial_details.policy_premium * quote.quote_versions[0].financial_details.peliwica_pay_away_commission)/100 | number:'1.2-2'"
                                    readonly>
                            </div>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-md-4">Peliwica retained commission</div>
                        <div class="col-md-6">
                            <div *ngIf="quote_type == 4 || quote_type == 3 || quote_type == 5 || quote_type == 6">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">%</span>
                                    </div>
                                    <input type="text" readonly class="form-control form-control-sm"
                                        [value]="quote.quote_versions[0].financial_details.peliwica_retained_commission">
                                </div>
                            </div>
                            <div *ngIf="quote_type != 4 && quote_type != 3 && quote_type != 5 && quote_type != 6">
                                <mat-slider name="peliwica_retained_commission" thumbLabel [disabled]="quote_type == 4"
                                    [value]="quote.quote_versions[0].financial_details.peliwica_retained_commission"
                                    [(ngModel)]="quote.quote_versions[0].financial_details.peliwica_retained_commission"
                                    [displayWith]="formatSliderLabel" min="0" [max]="max_prt_comission" step="0.5"
                                    (change)="onPRetainedComissionChange($event)">
                                </mat-slider>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-4">Peliwica retained gross commission</div>
                        <div class="col-md-6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="text" name="retained_gross_commision" placeholder="Gross Commission"
                                    class="form-control form-control-sm"
                                    [disabled]="quote_type == 4 || quote_type == 3 || quote_type == 5 || quote_type == 6"
                                    [value]="(quote.quote_versions[0].financial_details.policy_premium * quote.quote_versions[0].financial_details.peliwica_retained_commission)/100 | number:'1.2-2'"
                                    readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-4">Premium Loading</div>
                        <div class="col-md-6">
                            <div *ngIf="quote_type == 4 || quote_type == 3 || quote_type == 5 || quote_type == 6">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">%</span>
                                    </div>
                                    <input type="text" readonly class="form-control form-control-sm"
                                        [value]="quote.quote_versions[0].financial_details.premium_loading_percentage">
                                </div>
                            </div>
                            <div *ngIf="quote_type != 4 &&  quote_type != 3 &&  quote_type != 5 &&  quote_type != 6">
                                <mat-slider name="premium_loading" thumbLabel
                                    [value]="quote.quote_versions[0].financial_details.premium_loading_percentage"
                                    [(ngModel)]="quote.quote_versions[0].financial_details.premium_loading_percentage"
                                    [displayWith]="formatSliderLabel" min="-100" max="100" step="0.5"
                                    (change)="onPreLoadingChange($event)">
                                </mat-slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-group" *ngIf="(quote_type == 1 || quote_type == 2) && !isBroker && tigerlabfield">
                    <div class="col-md-4">Legacy Loading</div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-success text-white">&pound;</span>
                            </div>
                            <input type="number" class="form-control form-control-sm"
                                (blur)="ontTgerLabPremLoadingbChange($event)"
                                [value]="quote.quote_versions[0].financial_details.tigerlab_premium_loading">
                        </div>
                    </div>
                </div>
                <div *ngIf="visibleOnlytoAdmin">
                    <div class="row form-group">
                        <div class="col-md-4">Total Premium (Excl IPT)</div>
                        <div class="col-md-6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="text" name="premium_loading" placeholder="Total Premium"
                                    class="form-control form-control-sm"
                                    [disabled]="quote_type == 4 || quote_type == 3 || quote_type == 5 || quote_type == 6"
                                    [value]="(quote.quote_versions[0].is_aeio == 0)?indicativeCalculationsDetails.full_total_gross_primum:indicativeCalculationsDetails.aeio_total_gross_primum"
                                    readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-4">Underwriting Fee</div>
                        <div class="col-md-6">
                            <div *ngIf="quote_type == 4 || quote_type == 3 || quote_type == 5 || quote_type == 6">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">&pound;</span>
                                    </div>
                                    <input type="text" readonly class="form-control form-control-sm"
                                        [value]="quote.quote_versions[0].financial_details.uw_fee">
                                </div>
                            </div>
                            <div *ngIf="quote_type != 4 && quote_type != 3 && quote_type != 5 && quote_type != 6">
                                <mat-slider name="uw_fee" thumbLabel [disabled]="quote_type == 4"
                                    [value]="quote.quote_versions[0].financial_details.uw_fee"
                                    [(ngModel)]="quote.quote_versions[0].financial_details.uw_fee"
                                    [displayWith]="formatUWSliderLabel" min="0" max="{{max_uw_fee}}" step="0.5">
                                </mat-slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div *ngIf="quote_type != 4 && quote_type != 5 && quote_type != 6">
                    <button name="aeio_quote" *ngIf="quote.quote_versions[0].is_aeio == 1 || quote.indicativePage"
                        (click)="ProceedWithAEIOQuoteBtn(f.valid,f)" class="btn btn-success btn-gray mr-3">Proceed with
                        AEIO
                        quote</button>
                    <button *ngIf="quote.full_quote_section" (click)="ProceedWithFullQuoteBtn(f.valid,f)"
                        class="btn btn-default btn-gray">Proceed with
                        Full
                        quote</button>
                </div>
                <div *ngIf="quote_type == 4">
                    <button class="btn btn-default btn-gray">Cancel</button>
                </div>
                <div *ngIf="quote_type == 5">
                    <button class="btn btn-default btn-gray">Lapse</button>
                </div>
                <div *ngIf="quote_type == 6">
                    <button class="btn btn-default btn-gray">Submit for NTU</button>
                </div>
            </div>

        </div>
        <div class="col-md-4">
            <app-calculations [indicativeCalculationsDetails]="indicativeCalculationsDetails"
                [quoteInfo]="quoteDetails"></app-calculations>

        </div>
    </div>
    <ng-template #content let-modal>
        <div class="modal-body">
            <div class="helptext" [innerHTML]="helptext"></div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
        </div>
    </ng-template>
</form>