<div class="horizontal-menu">
    <nav class="navbar top-navbar p-0 row">
        <div class="col-md-3">
            <a class="ml-2" href="dashboard">
                <img src="assets/images/logo.jpg" alt="logo" width="180" class="img-fluid">
            </a>
        </div>
        <div class="col-md-3" >
            <a class="ml-2" href="dashboard" *ngIf="companylogoURL">
                <img [src]="companylogoURL" alt="company logo" width="180" class="img-fluid">
            </a>
        </div>
        <div class="col-md-6 navbar-menu-wrapper d-flex align-items-center justify-content-between">

            <ul class="navbar-nav navbar-nav-right mr-3">
                <li class="nav-item nav-profile dropdown">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
                        <span class="nav-profile-name">{{ userInfo.username }}</span>
                        <span class="online-status"></span>
                        <img src="assets/images/user_icon.png" alt="profile"/>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                        <a class="dropdown-item">
                            <i class="mdi mdi-settings text-primary"></i>
                            Settings
                        </a>
                        <a class="dropdown-item" href="/logout">
                            <i class="mdi mdi-logout text-primary"></i>
                            Logout 
                        </a>
                    </div>
                </li>
            </ul>
            <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="horizontal-menu-toggle">
                <span class="mdi mdi-menu"></span>
            </button>
        </div>
    </nav>
</div>
