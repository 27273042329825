import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ElementRef } from '@angular/core';
import { Quote } from '../../model/quote/quote.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { AlQuoteService } from '../al-quote.service';
import { QuoteService } from '../../../quote.service';
import { ActivatedRoute } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GridOptions } from "ag-grid-community";
import { formatDate } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { UserInfoService } from '../../../../services/user-info.service';

@Component({
  selector: 'app-admin-al-sof',
  templateUrl: './al-sof.component.html',
  styleUrls: ['../../../quote.component.css']
})
export class AdminAlSofComponent implements OnInit {
  @Input() quoteDetails: Quote;
  @Output() BackToFullDetails = new EventEmitter();
  @Output() proceedToQuotationTab = new EventEmitter();
  @Output() quoteDetailsUpdated = new EventEmitter<Quote>();

  constructor(private modalService: NgbModal, private quoteService: AlQuoteService, private adminQuoteService: QuoteService, private el: ElementRef, 
    private sessionSt: LocalStorageService, private route: ActivatedRoute, private _snackBar: MatSnackBar, private userService: UserInfoService) { }
  statementOfFacts = [];
  quesTitles = [];
  rowData: any = [];

  ngOnInit(): void {
    this.quote = this.quoteDetails;
  }

  ngDoCheck() {
    if (this.statementOfFacts && this.quote) {
      let temp;
      this.statementOfFacts.forEach((res, index) => {
        // Assign Section Titles
        if (res.product_type == 2 && temp != res.product_type) {
          if(this.quote.quote_versions[0].about_alpaca.does_vet_treatments_covered == 1) {
            res.quesTitles = 'ALPACA / LLAMA mortality and limited theft with vets fees statement of facts';
          } else {
            res.quesTitles = 'ALPACA / LLAMA mortality';
          }          
        } else if (res.product_type == 3 && temp != res.product_type) {
          res.quesTitles = 'Public; Product and Employers Liability statement of Facts';
        } else if (res.product_type == 4 && temp != res.product_type) {
          res.quesTitles = 'Trailer Cover';
        }
        temp = res.product_type;
      });
    }

  }

  quote;
  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;
    if (this.quote.company_id) {
      this.sessionSt.store('al_broker_company_id', this.quote.company_id);
      this.quoteService.getStatementOfFacts()
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.statementOfFacts = response.result.statement_of_fact;
              if (this.quote.quote_versions[0].statement_of_facts.length <= 1) {
                this.statementOfFacts.forEach((res, index) => {                  
                  this.quote.quote_versions[0].statement_of_facts.push({
                    id: '',
                    quote_id: '',
                    question_id: res.id,
                    response: null,
                    response_bool: false,
                    response_text: '',
                    display_order: res.display_order
                  });

                });
              }
            }

          },
          (error) => {
            console.log(error);
          }
        );
    }

    this.quote.quote_versions[0].statement_of_facts.forEach((res, index) => {
      if (res.response == 0) {
        res.response = null;
      }
    });


    if (this.quote.quote_versions[0].statement_of_facts.length <= 1) {
      this.statementOfFacts.forEach((res, index) => {
        this.quote.quote_versions[0].statement_of_facts.push({
          id: '',
          quote_id: '',
          question_id: res.id,
          response: null,
          response_bool: false,
          response_text: '',
          display_order: res.display_order
        });

      });
    }

    if (this.route.snapshot.params.id && this.quote) { 
      // GET QUOTE DOCUMENTS       
      if (this.quote.quote_versions.length > 0 && this.quote.quote_versions[0].id) {
        this.adminQuoteService.getQuoteDocuments(this.quote.quote_versions[0].id)
          .subscribe(
            (response: any) => {
              if (response.success) {
                let data = [];
                response.result.forEach((res, index) => {
                  if (res.is_static == 0) {
                    data.push(res);
                  }
                });
                
                this.rowData = data;
               
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }      
    } else {
      if (this.quote.quote_versions[0].statement_of_facts.length == 1) {
        this.quote.quote_versions[0].statement_of_facts = [];
      }
    }
  }

  /*
  * Back To Full Details
  */
  backToFullDetailsTab() {
    this.BackToFullDetails.emit();
  }

  /*
 * HELP TEXT POPUP
 */
  helptext = '';
  helpTextPopup(content, text) {
    const modalRef = this.modalService.open(content, { centered: true });
    this.helptext = text;
  }

  /*
  * Decline Selection - Hide BTN
  */
  disableBtn: boolean = false;
  onQuestionsChange(event, is_decline) {
    if (event.value == 2 && is_decline == 1) {
      this.disableBtn = true;
    } else {
      this.disableBtn = false;
    }
  }

  // UPLOAd DOCUMENT
  docTitle: string = '';
  fileToUpload: File = null;
  uploadDocumentBtn(documents) {
    this.documentID = '';
    this.docTitle = '';
    this.fileToUpload = null;
    this.documentsEditMode = false;
    this.quote.quote_document = '';
    this.modalService.open(documents, { centered: true });
  }

  getUploadedFile(files) {
    this.fileToUpload = files.item(0);
    this.quote.quote_document = this.fileToUpload.name;
  }


  documentUpload() {
    let formData = new FormData();
    formData.append("file_name", this.fileToUpload);
    formData.append("quote_id", this.quote.quote_versions[0].id);
    formData.append("broker_id", this.quote.quote_versions[0].broker_id);
    formData.append("title", this.docTitle);

    this.adminQuoteService.saveQuoteDocument(formData)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          if (response.success) {
            this.quote.quote_document = response.result.file_name;
            this.gridOptions.rowData.unshift({
              'id': response.result.id,
              'title': response.result.title,
              'quote_version': this.quote.quote_versions[0].sequence_number,
              'created_date': response.result.created_at,
              'download_path': response.result.file_path + '/' + response.result.file_name,
            });
            this.gridOptions.api.setRowData(this.gridOptions.rowData);
            this.gridOptions.api.refreshCells();
            this._snackBar.open('Document uploaded.', '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open('Unable to upload document.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  // List Quote Versions
  gridOptions: GridOptions = {
    onGridReady: () => {
      //this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    { headerName: 'Document Name', field: 'title', sortable: true, width: 300 },
    { headerName: 'Document Version', field: 'quote_version', sortable: true },
    {
      headerName: 'Creation Date', sortable: true, filter: true,
      cellRenderer: function (params) {
        return formatDate(params.data.created_date, 'dd-MM-yyyy', 'en-US');
      }
    },
    {
      headerName: null,
      width: 130,
      cellRenderer: function (params) {
        let links = '<a  title="Edit Documents" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit"></i></a>';
        // links += '<a class="ml-2" target="_blank" href="' + environment.imageURL + params.data.download_path + '" title="Download Document" style="font-size: 18px;color: #138a51;"><i class="mdi mdi-cloud-download"  download_path="' + params.data.download_path + '"></i></a>';
        links += '<a class="ml-2" title="Download Document" style="font-size: 18px;color: #138a51;"><i class="mdi mdi-cloud-download"  data-action-type="download"></i></a>';
        links += '<a class="ml-2" title="Delete Documents" style="font-size: 18px;"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete"></i></a>';
        return links;
      }
    }
  ];


  /*
  * GRID BUTTONS ACTION
  */
  documentsEditMode: boolean = false;
  documentID: string = '';
  onGridRowClicked(e: any, documents,documentsDelete) {
    if (e) {
      let download_path = e.event.target.getAttribute("download_path");
      let actionType = e.event.target.getAttribute("data-action-type");
      this.documentID = e.data.id;
      this.docTitle = e.data.title;
      switch (actionType) {
        case "download":
          {
            this.adminQuoteService.downloadDocs(this.documentID,this.docTitle);
            break;
          }
        case "edit": {          
          this.documentsEditMode = true;
          this.modalService.open(documents, { centered: true });
          break
        }
        case "delete": {
          this.modalService.open(documentsDelete, { centered: true });
          break;
        }
      }
    }
  }

  /*
  * UPDATE DOCUMENT
  */
  updateDocument() {
    let data: any = '';
    data = {
      document_id: this.documentID,
      title: this.docTitle
    };
    this.adminQuoteService.updateDocument(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this.rowData.forEach((res) => {
              if (this.documentID == res.id) {
                res.title = this.docTitle
              }
            });
            this.gridOptions.api.setRowData(this.rowData);
            this.gridOptions.api.refreshCells();
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open('Unable to update title. Please try again later.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
  * Delete Document
  */
  deleteDocument() {
    let data: any = '';
    data = {
      document_id: this.documentID
    };
    this.adminQuoteService.deleteDocument(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            const selectedRow = this.gridOptions.api.getFocusedCell()
            const id = this.gridOptions.rowData[selectedRow.rowIndex].i

            this.gridOptions.rowData.splice(selectedRow.rowIndex, 1)
            this.gridOptions.api.setRowData(this.gridOptions.rowData)
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
  * SAVE DETAILS
  */
  onSubmit(f: NgForm) {
    if (!f.form.valid) {
      let temp = '';
      for (const key of Object.keys(f.controls)) {
        // remove condition later
        if (key == 'statementOfFact_105d89da-0f2b-4a8f-a766-c53df74f4958') {
          temp = key;
        }
        if (f.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');
          if (invalidControl) {
            invalidControl.focus();
            invalidControl.scrollIntoView({ behavior: 'smooth' });
          }
          break;
        }
      }
      if (temp == '') { // remove if condition later
        return false;
      }
    }

    this.quote.quote_versions[0].quote_page_index = 2;
    this.quote.insurance_status_id = '80c0db90-ba5d-4387-b09a-4c008a664932';
    let quote_temp = this.quote;

    let data: any = ''

    data = {
      quote_data: this.quote
    };
    this.quoteService.saveQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            var temp;
            temp = this.quote;
            this.quote = response.result.quote_data;
            this.quote.calculations = quote_temp.calculations;
            this.quoteDetailsUpdated.emit(this.quote);
            this.proceedToQuotationTab.emit();
          }

        });
  }
}
