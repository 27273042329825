import { Injectable } from '@angular/core';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class BordereauService {

  constructor(private sharedService: SharedService) { }


  // Save Indicative Quote
  download(data){
    
    return this.sharedService.postDownload('bordereau/download', data);
  }
}

