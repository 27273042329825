import { Component, OnInit } from '@angular/core';
import { QuoteService } from './quote.service';
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { environment } from '../../environments/environment';
import { UserInfoService } from '../services/user-info.service';

@Component({
  selector: 'app-quote-new',
  templateUrl: './quote-new.component.html',
  styles: [
  ]
})
export class QuoteNewComponent implements OnInit {
  agreedQuestions;
  constructor(private quoteService: QuoteService,private router: Router, private route: ActivatedRoute, private userService: UserInfoService) { }
  
  ngOnInit(): void {
    this.userService.getUserDetails();
   // GET QUOTE DETAILS
   if (this.route.snapshot.params.id) {
    this.quoteService.getQuoteDetails(this.route.snapshot.params.id)
      .subscribe(
        (response: any) => {
          if (response.success) {
            
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }


    // GET AGREED QUESTIONS
    this.quoteService.getAgreedQues(environment.EQUINE_ID)
      .subscribe(
        (response: any) => {
          if (response.success) {
             this.agreedQuestions = response.result.agreed_questions;
          } 
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /*
  * Agreed Button Functionality
  */
 agreedBtnAction() {
  if (this.route.snapshot.params.id && !this.route.snapshot.params.quote_type) {
    this.router.navigate(['/quote', this.route.snapshot.params.id]);
  } else if(this.route.snapshot.params.quote_type) {
    this.router.navigate(['/quote', this.route.snapshot.params.id, this.route.snapshot.params.quote_type]);
  } else {
  this.router.navigateByUrl("/quote");
  }
 }

}
