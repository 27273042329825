import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(private sharedService: SharedService, private sessionSt: LocalStorageService) { }

  getUserDetails() {
    return this.sharedService.getAPI('user/' + this.sessionSt.retrieve('userInfo').id).subscribe(
        (response: any) => {
          if (response.success) {
            this.sessionSt.store('userInfo', response.result.user);
            this.sessionSt.store('companyProducts', response.result.products_assigned);
            this.sessionSt.store('company_role', response.result.company_role_name);
            this.sessionSt.store('user_group', response.result.user_group_name);
            if(response.result.user.company != null) {
              this.sessionSt.store('broker_company_id', response.result.user.company.id);
              this.sessionSt.store('al_broker_company_id', response.result.user.company.id);
            }
            this.sharedService.getUserType();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
