import { Component, OnInit, Output, EventEmitter, SimpleChanges, Input } from '@angular/core';
import { Quote } from '../../model/quote/quote.model';

@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['../quote.component.css'],
})
export class SubmissionComponent implements OnInit {
  @Input() quoteDetails: Quote;
  @Output() quoteDetailsUpdated = new EventEmitter<Quote>();
  quote:Quote;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;
  }

  ngOnInit(): void {
  }

}
