import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ElementRef } from '@angular/core';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { Quote } from '../../model/quote/quote.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { AlQuoteService } from '../al-quote.service';
import { AlCalculationsDetails } from '../al-calculations/al-calculations.model';
import { AlpacaDetails } from '../../model/quote/alpaca_details.model';
import { HorseDetailsTrailer } from '../../model/quote/horse_details_trailer.model';
import { AlpacaLiability } from '../../model/quote/alpaca_liability.model';
import { TrailerCover } from '../../model/quote/trailer_cover.model';
import { LocalStorageService } from 'ngx-webstorage';
import { UserInfoService } from '../../../../services/user-info.service';

@Component({
  selector: 'app-admin-al-indicative-quote-details',
  templateUrl: './al-indicative-quote-details.component.html',
  styleUrls: ['../../../quote.component.css']
})
export class AdminAlIndicativeQuoteDetailsComponent implements OnInit {
  @Input() quoteDetails: Quote;
  @Output() quoteDetailsUpdated = new EventEmitter<Quote>();
  @Output() BackToIndicativeQuote = new EventEmitter();
  @Output() ProceedWithFullDetails = new EventEmitter();
  Calculations = new AlCalculationsDetails();
  isBroker: boolean = false;
  brokers = [];
  @Input()
  set allbrokers(value: any) {
    this.brokers = value;
  }

  policyReasons = [];
  @Input()
  set allPolicyReasons(value: any) {
    this.policyReasons = value;
  }

  ratesTextFields = [];
  ratesRadioFields = [];
  alpacaLiabiities = [];
  alpacaRates;
  alpacaAllLiabiities = [];
  allClaims = [];
  modelBtnText: string = 'Close';
  modelBtnClass: string = 'btn-default btn-gray';
  minimum_mortality_premium: number = 0;

  @Input()
  set staticData(value: any) {
    if (value) {

      // Alpaca Rates
      this.alpacaRates = value.alpaca_rates;
      // Alapca Liabiity
      this.alpacaAllLiabiities = value.alpaca_liability;
      value.alpaca_liability.forEach((res) => {
        res.is_checked = false;
      })
      this.alpacaLiabiities = value.alpaca_liability.filter(x => x.indeminity_limit == '1 Million');

      // IPT/Administrative Fee      
      this.Calculations.ipt_charges = Number(value.peliwica_ipt_commission);
      this.Calculations.administrative_charges = Number(value.broker_uw_fees);
      this.Calculations.calculate_data(this.mta_quote_data);

      // Claims
      this.allClaims = value.claim_loading;

      this.minimum_mortality_premium = Number(value.minimum_mortality_premium);
    }
  }

  constructor(private modalService: NgbModal, private quoteService: AlQuoteService, private _snackBar: MatSnackBar, private userService: UserInfoService,
    private el: ElementRef, private route: ActivatedRoute, private sessionSt: LocalStorageService, private router: Router, private alquoteService: AlQuoteService) { }

  ngOnInit(): void {
    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      this.isBroker = true;
    }
    // GET STATIC DATA
    if (!this.alpacaRates) {
      this.alquoteService.getStaticData()
        .subscribe(
          (response: any) => {
            if (response.success) {
              //this.staticData = response.result;
              // Alpaca Rates
              this.alpacaRates = response.result.alpaca_rates;
              // Alapca Liabiity
              this.alpacaAllLiabiities = response.result.alpaca_liability;
              response.result.alpaca_liability.forEach((res) => {
                res.is_checked = false;
              })
              this.alpacaLiabiities = response.result.alpaca_liability.filter(x => x.indeminity_limit == '1 Million');

              // IPT/Administrative Fee
              this.Calculations.ipt_charges = Number(response.result.peliwica_ipt_commission);


              // Claims
              this.allClaims = response.result.claim_loading;

              this.minimum_mortality_premium = Number(response.result.minimum_mortality_premium);
              this.Calculations.minimum_mortality_premium = this.minimum_mortality_premium;
              this.Calculations.calculate_data(this.mta_quote_data);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }


  quote;
  minPolicyDate;
  maxPolicyDate;
  mta_quote_data;
  disableIfCancelAndNTU: boolean = false;
  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;

    this.mta_quote_data = this.quoteDetails;
    this.Calculations.mta_date = this.quote.quote_versions[0].policy_start_date;
    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      let maxDate = new Date().setDate(new Date().getDate() + 30);
      this.maxPolicyDate = new Date(maxDate);
      this.minPolicyDate = new Date();
    }
    this.quote.calculations = this.Calculations;


    if (!this.quote.quote_versions[0].alpaca_liability) {
      this.quote.quote_versions[0].alpaca_liability = new AlpacaLiability();
    }
    if (!this.quote.quote_versions[0].trailer_cover) {
      this.quote.quote_versions[0].trailer_cover = new TrailerCover();
    }

    // Remove calculations is mortality/Pubic liability/Trailer is deselected
    if (this.quote.quote_versions[0].alpaca_theft_cover != 1) {
      this.Calculations.age_7_90_rate = 0;
      this.Calculations.age_7_90_sum_insured = 0;
      this.Calculations.age_7_90_total_count = 0;
      this.Calculations.age_above_90_rate = 0;
      this.Calculations.age_above_90_sum_insured = 0;
      this.Calculations.age_above_90_total_count = 0;
      this.Calculations.age_13_rate = 0;
      this.Calculations.age_13_sum_insured = 0;
      this.Calculations.age_13_total_count = 0;
      this.Calculations.age_14_rate = 0;
      this.Calculations.age_14_sum_insured = 0;
      this.Calculations.age_14_total_count = 0;
      this.Calculations.male_economic_slaughter_rate = 0;
      this.Calculations.male_economic_slaughter_sum_insured = 0;
      this.Calculations.life_saving_surgical_fees = 0;
      this.Calculations.veterinary_fees = 0;
      this.Calculations.trailer_rate = 0;
      this.Calculations.trailer_minimum_premium = 0;
    }
    else {
      this.Calculations.minimum_mortality_premium = this.minimum_mortality_premium;
    }
    if (this.quote.quote_versions[0].alpaca_public_liability_cover != 1) {
      this.Calculations.acrage = 0;
      this.Calculations.basic_liabilty_minimum_premium = 0;
      this.Calculations.basic_liabilty_excess_acrage = 0;
      this.Calculations.employer_liability = 0;
      this.Calculations.alpaca_experience_minimum_premium = 0;
      this.Calculations.alpaca_excess_acrage = 0;
      this.Calculations.visit_insured_premises = 0;
      this.Calculations.visit_to_extrenal_venues = 0;
      this.Calculations.promoting_selling_related_products = 0;
      this.Calculations.trade_stand = 0;
    }

    if (this.quote.quote_versions[0].alpaca_trailer_cover != 1) {
      this.Calculations.trailer_rate = 0;
      this.Calculations.trailer_minimum_premium = 0;
      this.Calculations.trailer_sum_insured = 0;
    }
    this.Calculations.calculate_data(this.mta_quote_data);

    if (this.route.snapshot.params.quote_id) {
      // Assign Financial Detail Values       
      this.setFinancialDetails();

      // Set SmallHolding Activities
      this.setSmallHoldingActivities();
    } else {
      let uw_fee = 0;
      if (this.quote.company_id) {
        let brokers_data = this.brokers.filter(x => x.id == this.quote.company_id);
        if (brokers_data.length > 0) {
          uw_fee = brokers_data[0].uw_fees;
        }
        this.Calculations.administrative_charges = Number(uw_fee);
        this.Calculations.calculate_data(this.mta_quote_data);
      }
    }

    // Enable MTA/Cancel/Lapse/NTU 
    this.policyActions();
  }

  /*
  * On Change Broker Dropdown
  */
  onChangeBroker(event) {
    let uw_fee = 0;
    let uw_fees = 0;
    if (event.value != undefined) {
      let brokers_data = this.brokers.filter(x => x.id == event.value);
      if (brokers_data.length > 0) {
        uw_fee = brokers_data[0].uw_fees;
        uw_fees = brokers_data[0].uw_fees;
        this.quote.company_id = event.value;
      }
      this.quote.quote_versions[0].financial_details.administrative_charges = Number(uw_fees);
      this.Calculations.administrative_charges = Number(uw_fees);
      this.Calculations.calculate_data(this.mta_quote_data);
    }
  }

  /*
  * On Quote Type Change
  */
  onQuoteTypeChange(event) {
    this.quote_type = event.value;
    this.quote.quote_versions[0].type = event.value;
  }

  /*
  * SET fINANCIAL DETAILS IN CASE OF EDIT QUOTE
  */
  setFinancialDetails() {
    // Alpaca Rates
    if (this.quote.quote_versions[0].alpaca_theft_cover == 1) {
      if (this.alpacaRates) {
        let rates = this.alpacaRates.filter(x => x.type == 1);
        if (rates.length > 0) {
          this.Calculations.age_7_90_rate = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.cria_rate);
          this.Calculations.age_7_90_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.alpaca_7dto90d) * Number(rates[0].value);
          this.Calculations.age_7_90_total_count = Number(this.quote.quote_versions[0].about_alpaca.alpaca_7dto90d);
        }
      }

      this.Calculations.age_above_90_rate = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.alpaca_rate);
      this.Calculations.age_above_90_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.sum_insured);
      this.Calculations.age_above_90_total_count = Number(this.quote.quote_versions[0].about_alpaca.alpaca_91dto12y);

      this.Calculations.age_13_rate = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.age_13_rate);
      this.Calculations.age_13_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.sum_insured_13y);
      this.Calculations.age_13_total_count = Number(this.quote.quote_versions[0].about_alpaca.alpaca_13y);

      this.Calculations.age_14_rate = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.age_14_rate);
      this.Calculations.age_14_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.sum_insured_14y);
      this.Calculations.age_14_total_count = Number(this.quote.quote_versions[0].about_alpaca.alpaca_14y);


      this.Calculations.male_economic_slaughter_rate = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.economic_slaughter_rate);
      this.Calculations.male_economic_slaughter_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.economic_slaughter_sum_insured);

      this.Calculations.life_saving_surgical_fees = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.life_saving_rate);
      this.Calculations.veterinary_fees = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.veterinary_rate);
    
      // Claim
      this.Calculations.claim_loading_percentage = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.claim_loading_percentage);

    }

    // Public Liability
    if (this.quote.quote_versions[0].alpaca_public_liability_cover == 1) {
      //Acrage
      if (this.quote.quote_versions[0].alpaca_liability) {
        this.Calculations.acrage = this.quote.quote_versions[0].alpaca_liability.acerage;
      }

      // Smallholding Activities
      this.Calculations.alpaca_experience_minimum_premium = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.minimum_alpaca_premium);
      this.Calculations.alpaca_excess_acrage = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.minimum_excess_acerage);
      this.Calculations.visit_insured_premises = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.visit_insured_premises_premium);
      this.Calculations.visit_to_extrenal_venues = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.visit_to_external_premises_premium);
      this.Calculations.promoting_selling_related_products = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.promoting_and_selling_premium);
      this.Calculations.trade_stand = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.visit_to_trade_stand_premium);

      // Liability Limits
      this.Calculations.basic_liabilty_minimum_premium = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.minimum_basic_liability);
      this.setLiabilityLimitValues();   
    }

    //Trailer
    if (this.quote.quote_versions[0].alpaca_trailer_cover == 1) {
      this.Calculations.trailer_rate = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.trailer_rate);
      this.Calculations.trailer_minimum_premium = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.trailer_minimum_basic_premium);
      if (this.quote.quote_versions[0].trailer_cover) {
        this.Calculations.trailer_sum_insured = Number(this.quote.quote_versions[0].trailer_cover.trailer_insurance_cover);
      }
    }

    this.Calculations.ipt_charges = Number(this.quote.quote_versions[0].financial_details.ipt_fee);
    this.Calculations.ipt_amount = Number(this.quote.quote_versions[0].financial_details.ipt_value);
    this.Calculations.administrative_charges = Number(this.quote.quote_versions[0].financial_details.uw_fee);
    this.Calculations.calculate_data(this.mta_quote_data);
  }

  /*
  * Set SmallHolding Activities In Case Of Edit Quote
  */
  setSmallHoldingActivities() {
    this.alpacaLiabiities.forEach((res) => {
      let id = res.id;
      if ((id == '52f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '62f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '72f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.quote_versions[0].smallholding_activities.is_alpaca_experience_sessions == 1) {
        res.is_checked = true;
      }
      if ((id == '53f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '63f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '73f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.quote_versions[0].smallholding_activities.is_group_visits_insured_premises == 1) {
        res.is_checked = true;
      }
      if ((id == '54f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '64f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '74f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.quote_versions[0].smallholding_activities.is_visits_external_venues == 1) {
        res.is_checked = true;
      }
      if ((id == '56f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '66f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '76f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.quote_versions[0].smallholding_activities.is_promoting_selling_products == 1) {
        res.is_checked = true;
      }
      if ((id == '55f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '65f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '75f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.quote_versions[0].smallholding_activities.is_attending_events_trade_stand == 1) {
        res.is_checked = true;
      }

    });
  }

  /*
  * Set MTA Parameters
  */
  quote_type = 0;
  policyActions() {
    // ENABLE MTA AND CANCEL
    if (this.route.snapshot.params.quote_type) {
      this.quote_type = Number(this.route.snapshot.params.quote_type);
    } else {
      this.quote_type = Number(this.quote.quote_versions[0].type);
    }

    if (this.quote_type == 4 || this.quote_type == 5 || this.quote_type == 6) {
      this.disableIfCancelAndNTU = true;
    }

    switch (this.quote_type) {
      case 3:
        this.Calculations.is_mta = true;
        break;
      case 4:
        this.Calculations.is_cancel = true;
        if (this.sessionSt.retrieve('company_role') == 'Broker') {
          let minDate = new Date().setDate(new Date().getDate() - 14);
          this.minPolicyDate = new Date(minDate);
        }
        this.quote.quote_versions[0].policy_start_date = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
        break;
      case 6:
        this.enableNTU();
        this.minPolicyDate = this.quote.quote_versions[0].policy_start_date;
        this.maxPolicyDate = this.quote.quote_versions[0].policy_end_date;
        break;
      default:
        this.Calculations.is_mta = false;
        this.Calculations.is_cancel = false;
        break;
    }
    this.Calculations.calculate_data(this.mta_quote_data);
  }

  /*
 * ENABLE NTU IN CASE OF CANCEL POLICY
 */
  enableNTU() {
    if (this.quote.quote_versions[0].policy_start_date && this.sessionSt.retrieve('company_role') == 'Broker') {
      let currentDate = new Date()
      let policy_start_date = new Date(this.quote.quote_versions[0].policy_start_date);
      var Difference_In_Time = currentDate.getTime() - policy_start_date.getTime();
      if (Difference_In_Time / (1000 * 3600 * 24) < 14) {
        this.Calculations.is_NTU = true;
      }
    } else {
      this.Calculations.is_NTU = true;
    }
  }

  /*
  * Set Liability Limit Financials
  */
  setLiabilityLimitValues() {
    if (this.quote.quote_versions[0].alpaca_liability) {
      switch (this.quote.quote_versions[0].alpaca_liability.public_liability_cover) {
        case 1:
          this.alpacaLiabiities = this.alpacaAllLiabiities.filter(x => x.indeminity_limit == '1 Million');
          this.alpacaLiabiities.forEach((liability) => {
            if (liability.id == '51f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.basic_liabilty_minimum_premium = Number(liability.minimum_basic_premium);
              this.Calculations.basic_liabilty_excess_acrage = Number(liability.excess_acerage);
            }
            if (liability.id == '57f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.employer_liability = Number(liability.minimum_basic_premium);
            }
          });
          break;
        case 2:
          this.alpacaLiabiities = this.alpacaAllLiabiities.filter(x => x.indeminity_limit == '2 Million');
          this.alpacaLiabiities.forEach((liability) => {
            if (liability.id == '61f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.basic_liabilty_minimum_premium = Number(liability.minimum_basic_premium);
              this.Calculations.basic_liabilty_excess_acrage = Number(liability.excess_acerage);
            }
            if (liability.id == '67f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.employer_liability = Number(liability.minimum_basic_premium);
            }
          });
          break;
        case 5:
          this.alpacaLiabiities = this.alpacaAllLiabiities.filter(x => x.indeminity_limit == '5 Million');
          this.alpacaLiabiities.forEach((liability) => {
            if (liability.id == '71f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.basic_liabilty_minimum_premium = Number(liability.minimum_basic_premium);
              this.Calculations.basic_liabilty_excess_acrage = Number(liability.excess_acerage);
            }
            if (liability.id == '77f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.employer_liability = Number(liability.minimum_basic_premium);
            }
          });
          break;
      }
    }
  }


  /*
* HELP TEXT POPUP
*/
  helptext = '';
  helpTextPopup(content, text) {
    this.modalService.open(content, { centered: true });
    this.helptext = text;
  }

  /*
  * BACK TO QUOTE TAB
  */

  backToQuoteTab() {
    this.BackToIndicativeQuote.emit();
  }

  /*
    * POSTCODE LOOKUP
    */
  addresses;
  postcodeLookup(event) {
    let postcode = event.target.value;
    this.quoteService.getAddressPostcodeLookup(postcode)
      .subscribe(
        (response: any) => {
          this.addresses = response.addresses;
        },
        (error) => {
          console.log(error);
        }
      );
  }
  onSelectAddress(address) {
    this.quote.quote_versions[0].policy_holder.address.address1 = address.line_1;
    this.quote.quote_versions[0].policy_holder.address.address2 = address.line_2;
    this.quote.quote_versions[0].policy_holder.address.city = address.town_or_city;
    this.quote.quote_versions[0].policy_holder.address.country = address.county;
  }

  /*
  * Alpaca Rate Change
  */
  onAlpacaRatesChange(event, type) {
    this.quote.total_animals = Number(this.quote.quote_versions[0].about_alpaca.alpaca_7dto90d) + Number(this.quote.quote_versions[0].about_alpaca.alpaca_91dto12y) + Number(this.quote.quote_versions[0].about_alpaca.alpaca_13y) + Number(this.quote.quote_versions[0].about_alpaca.alpaca_14y);
    if (!this.route.snapshot.params.quote_id && this.quote.quote_versions[0].alpaca_details.length == 0) {
      this.quote.quote_versions[0].alpaca_details = [];
    }
    if (this.quote.total_animals > this.quote.quote_versions[0].alpaca_details.length) {
      for (let i = this.quote.quote_versions[0].alpaca_details.length; i < this.quote.total_animals; i++) {
        this.quote.quote_versions[0].alpaca_details.push(new AlpacaDetails());
      }
    } else {
      let remainingItems = this.quote.quote_versions[0].alpaca_details.length - this.quote.total_animals;
      this.quote.quote_versions[0].alpaca_details.splice(-remainingItems, remainingItems);

    }

    this.quote.quote_versions[0].alpaca_details.forEach((res) => {
      if (res.is_cria == 0) {
        if (type == 1) {
          res.is_cria = 1;
        } else {
          res.is_cria = 2;
        }
      }
    })

    if (this.quote.quote_versions[0].about_alpaca.economic_slaughter_required_for > this.quote.total_animals) {
      this.quote.quote_versions[0].about_alpaca.economic_slaughter_required_for = 0;
    }


    let rates = this.alpacaRates.filter(x => x.type == type);
    if (rates.length > 0) {
      switch (type) {
        case 1:
          this.Calculations.age_7_90_rate = Number(rates[0].rate);
          this.Calculations.age_7_90_sum_insured = Number(event.target.value) * Number(rates[0].value);
          this.Calculations.age_7_90_total_count = Number(event.target.value);
          break;
        case 2:
          this.Calculations.age_above_90_rate = Number(rates[0].rate);
          this.Calculations.age_above_90_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.sum_insured);
          this.Calculations.age_above_90_total_count = Number(this.quote.quote_versions[0].about_alpaca.alpaca_91dto12y);
          break;
        case 3:
          this.Calculations.male_economic_slaughter_rate = Number(rates[0].rate);
          // if (Number(this.quote.quote_versions[0].about_alpaca.economic_slaughter_required_for) > 0) {
          //   this.quote.quote_versions[0].about_alpaca.economic_slaughter_sum_insured = (Number(this.quote.quote_versions[0].about_alpaca.sum_insured) / Number(this.quote.quote_versions[0].about_alpaca.economic_slaughter_required_for)).toFixed(2);
          // } else {
          //   this.quote.quote_versions[0].about_alpaca.economic_slaughter_sum_insured = 0;
          // }
          this.Calculations.male_economic_slaughter_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.economic_slaughter_sum_insured);
          break;
        case 5:
          this.Calculations.life_saving_surgical_fees = (this.quote.quote_versions[0].about_alpaca.does_life_saving_cover_required == 1) ? Number(rates[0].rate) : 0;
          break;
        case 6:
          this.Calculations.veterinary_fees = (this.quote.quote_versions[0].about_alpaca.does_vet_treatments_covered == 1) ? Number(rates[0].rate) : 0;
          break;
        case 8:
          this.Calculations.age_13_rate = Number(rates[0].rate);
          this.Calculations.age_13_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.sum_insured_13y);
          this.Calculations.age_13_total_count = Number(this.quote.quote_versions[0].about_alpaca.alpaca_13y);
          break;
        case 9:
          this.Calculations.age_14_rate = Number(rates[0].rate);
          this.Calculations.age_14_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.sum_insured_14y);
          this.Calculations.age_14_total_count = Number(this.quote.quote_versions[0].about_alpaca.alpaca_14y);
          break;

      }

    } else {
      this.Calculations.age_7_90_rate = 0;
      this.Calculations.age_7_90_sum_insured = 0;
      this.Calculations.age_7_90_total_count = 0;
      this.Calculations.age_above_90_rate = 0;
      this.Calculations.age_above_90_sum_insured = 0;
      this.Calculations.age_above_90_total_count = 0;
      this.Calculations.male_economic_slaughter_rate = 0;
      this.Calculations.male_economic_slaughter_sum_insured = 0;
      this.Calculations.life_saving_surgical_fees = 0;
      this.Calculations.veterinary_fees = 0;
      this.Calculations.age_13_rate = 0;
      this.Calculations.age_13_sum_insured = 0;
      this.Calculations.age_13_total_count = 0;
      this.Calculations.age_14_rate = 0;
      this.Calculations.age_14_sum_insured = 0;
      this.Calculations.age_14_total_count = 0;
    }
    this.Calculations.calculate_data(this.mta_quote_data);
  }


  /*
  * Acreage Change
  */
  acreageChange(event) {
    this.Calculations.acrage = this.quote.quote_versions[0].alpaca_liability.acerage;
    this.Calculations.calculate_data(this.mta_quote_data);
  }

  /*
  * Trailer Sum Insured Change
  */
  trailerSumInsuredChange(event) {
    let rates = this.alpacaRates.filter(x => x.type == 7);
    if (rates.length > 0) {
      this.Calculations.trailer_rate = Number(rates[0].rate);
      this.Calculations.trailer_minimum_premium = Number(rates[0].min_value);
    } else {
      this.Calculations.trailer_rate = 0;
      this.Calculations.trailer_minimum_premium = 0;
    }
    this.Calculations.trailer_sum_insured = Number(event.target.value);
    this.Calculations.calculate_data(this.mta_quote_data);
  }

  /*
  * SmallHolding Activities
  */
  smallHoldingActivities = [];
  onSmallHoldingActivitiesChange(event, id) {
    if (event.checked) {
      this.smallHoldingActivities.push(id);
    } else {
      let index = this.smallHoldingActivities.indexOf(id);
      this.smallHoldingActivities.splice(index, 1);
      if (id == '52f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '62f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '72f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.Calculations.alpaca_experience_minimum_premium = 0;
        this.Calculations.alpaca_excess_acrage = 0;
      }
      if (id == '53f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '63f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '73f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.Calculations.visit_insured_premises = 0;
      }
      if (id == '54f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '64f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '74f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.Calculations.visit_to_extrenal_venues = 0;
      }
      if (id == '56f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '66f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '76f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.Calculations.promoting_selling_related_products = 0;
      }
      if (id == '55f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '65f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '75f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.Calculations.trade_stand = 0;
      }
      this.Calculations.calculate_data(this.mta_quote_data);
    }

    this.quote.quote_versions[0].smallholding_activities.is_alpaca_experience_sessions = 0;
    this.quote.quote_versions[0].smallholding_activities.is_group_visits_insured_premises = 0;
    this.quote.quote_versions[0].smallholding_activities.is_visits_external_venues = 0;
    this.quote.quote_versions[0].smallholding_activities.is_promoting_selling_products = 0;
    this.quote.quote_versions[0].smallholding_activities.is_attending_events_trade_stand = 0;
    this.smallHoldingActivities.forEach((id) => {
      let activities = this.alpacaLiabiities.filter(x => x.id == id);
      if (id == '52f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '62f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '72f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.quote.quote_versions[0].smallholding_activities.is_alpaca_experience_sessions = 1;
        if (activities.length > 0) {
          this.Calculations.alpaca_experience_minimum_premium = activities[0].minimum_basic_premium;
          this.Calculations.alpaca_excess_acrage = activities[0].excess_acerage;
        }
        this.Calculations.calculate_data(this.mta_quote_data);
      }
      if (id == '53f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '63f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '73f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.quote.quote_versions[0].smallholding_activities.is_group_visits_insured_premises = 1;
        if (activities.length > 0) {
          this.Calculations.visit_insured_premises = activities[0].minimum_basic_premium;
        }
        this.Calculations.calculate_data(this.mta_quote_data);
      }
      if (id == '54f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '64f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '74f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.quote.quote_versions[0].smallholding_activities.is_visits_external_venues = 1;
        if (activities.length > 0) {
          this.Calculations.visit_to_extrenal_venues = activities[0].minimum_basic_premium;
        }
        this.Calculations.calculate_data(this.mta_quote_data);
      }
      if (id == '56f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '66f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '76f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.quote.quote_versions[0].smallholding_activities.is_promoting_selling_products = 1;
        if (activities.length > 0) {
          this.Calculations.promoting_selling_related_products = activities[0].minimum_basic_premium;
        }
        this.Calculations.calculate_data(this.mta_quote_data);
      }
      if (id == '55f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '65f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '75f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.quote.quote_versions[0].smallholding_activities.is_attending_events_trade_stand = 1;
        if (activities.length > 0) {
          this.Calculations.trade_stand = activities[0].minimum_basic_premium;
        }
        this.Calculations.calculate_data(this.mta_quote_data);
      }

    });

  }

  /*
    * Alpaca Liabilitites Limit Change
    */
  alpacaLiabilityLimitValue;
  onAlpacaLiabilityLimitChange(event) {
    this.Calculations.basic_liabilty_minimum_premium = 0;
    this.Calculations.basic_liabilty_excess_acrage = 0;
    this.Calculations.employer_liability = 0;

    this.alpacaLiabilityLimitValue = event.value;
    switch (event.value) {
      case 1:
        this.alpacaLiabiities = this.alpacaAllLiabiities.filter(x => x.indeminity_limit == '1 Million');
        this.alpacaLiabiities.forEach((liability) => {
          if (liability.id == '51f635f8-c5c6-41ad-a75a-463f82b73a0e') {
            this.Calculations.basic_liabilty_minimum_premium = Number(liability.minimum_basic_premium);
            this.Calculations.basic_liabilty_excess_acrage = Number(liability.excess_acerage);
          }
          if (liability.id == '57f635f8-c5c6-41ad-a75a-463f82b73a0e') {
            this.Calculations.employer_liability = Number(liability.minimum_basic_premium);
          }
        });
        break;
      case 2:
        this.alpacaLiabiities = this.alpacaAllLiabiities.filter(x => x.indeminity_limit == '2 Million');
        this.alpacaLiabiities.forEach((liability) => {
          if (liability.id == '61f635f8-c5c6-41ad-a75a-463f82b73a0e') {
            this.Calculations.basic_liabilty_minimum_premium = Number(liability.minimum_basic_premium);
            this.Calculations.basic_liabilty_excess_acrage = Number(liability.excess_acerage);
          }
          if (liability.id == '67f635f8-c5c6-41ad-a75a-463f82b73a0e') {
            this.Calculations.employer_liability = Number(liability.minimum_basic_premium);
          }
        });
        break;
      case 5:
        this.alpacaLiabiities = this.alpacaAllLiabiities.filter(x => x.indeminity_limit == '5 Million');
        this.alpacaLiabiities.forEach((liability) => {
          if (liability.id == '71f635f8-c5c6-41ad-a75a-463f82b73a0e') {
            this.Calculations.basic_liabilty_minimum_premium = Number(liability.minimum_basic_premium);
            this.Calculations.basic_liabilty_excess_acrage = Number(liability.excess_acerage);
          }
          if (liability.id == '77f635f8-c5c6-41ad-a75a-463f82b73a0e') {
            this.Calculations.employer_liability = Number(liability.minimum_basic_premium);
          }
        });
        break;
    }
    // uncheck SmallHoldingActivities
    this.Calculations.alpaca_experience_minimum_premium = 0;
    this.Calculations.alpaca_excess_acrage = 0;
    this.Calculations.visit_insured_premises = 0;
    this.Calculations.visit_to_extrenal_venues = 0;
    this.Calculations.promoting_selling_related_products = 0;
    this.Calculations.trade_stand = 0;
    this.Calculations.calculate_data(this.mta_quote_data);

  }


  /*
  * Alpaca Liabilitites Change
  */
  onAlpacaLiabilitiesChange(event) {

  }

  /*
  * Total Trailors
  */
  onTotalTrailersChange(event) {
    if (event.target.value == 0) {
      this.quote.quote_versions[0].trailer_cover.number_of_trailers = 1;
    }
    let rates = this.alpacaRates.filter(x => x.type == 7);
    if (rates.length > 0) {
      this.Calculations.trailer_rate = Number(rates[0].rate);
      this.Calculations.trailer_minimum_premium = Number(rates[0].min_value);
    } else {
      this.Calculations.trailer_rate = 0;
      this.Calculations.trailer_minimum_premium = 0;
    }
    this.Calculations.calculate_data(this.mta_quote_data);

    if (!this.route.snapshot.params.quote_id) {
      this.quote.quote_versions[0].alpaca_trailor_items = [];
    }

    if (Number(event.target.value) > this.quote.quote_versions[0].alpaca_trailor_items.length) {
      for (let i = this.quote.quote_versions[0].alpaca_trailor_items.length; i < Number(event.target.value); i++) {
        this.quote.quote_versions[0].alpaca_trailor_items.push(new HorseDetailsTrailer());
      }
    } else {
      let remainingItems = this.quote.quote_versions[0].alpaca_trailor_items.length - Number(event.target.value);
      this.quote.quote_versions[0].alpaca_trailor_items.splice(-remainingItems, remainingItems);

    }

  }

  /*
  * Claim
  */
  onChangeClaim(event) {
    let claim = this.allClaims.filter(x => x.number_of_claims == event.value);
    if (claim.length > 0) {
      this.Calculations.claim_loading_percentage = Number(claim[0].claim_loading_percentage);
    } else {
      this.Calculations.claim_loading_percentage = 0;
    }
    this.Calculations.calculate_data(this.mta_quote_data);
  }

  /*
  * Economic Slaughter
  */
  onEconomicSlaughterChange(event) {
    if (this.quote.quote_versions[0].about_alpaca.does_economic_slaughter_required == 2) {
      this.quote.quote_versions[0].about_alpaca.economic_slaughter_required_for = 0;
      this.quote.quote_versions[0].about_alpaca.economic_slaughter_sum_insured = 0;
    }
  }

  /*
  * Stud Male Conformation message
  */

  studMalesConformation(content) {
    this.helptext = 'This cover is for Stud Males only, please click accept to continue';
    this.modelBtnText = 'Accept';
    this.modelBtnClass = 'btn-success';
    this.modalService.open(content, { centered: true });
  }

  /*
  * SAVE QUOTE
  */
  onSubmit(f: NgForm) {
    if (!f.form.valid) {
      for (const key of Object.keys(f.controls)) {
        if (f.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('input[name=' + key + '], mat-select[name=' + key + '], textarea[name=' + key + ']');
          if (invalidControl) {
            invalidControl.focus();
            invalidControl.scrollIntoView({ behavior: 'smooth' });
          }
          break;
        }
      }

      return false;
    }

    if (this.quote.quote_versions[0].about_alpaca.alpaca_7dto90d > 30 || this.quote.quote_versions[0].about_alpaca.alpaca_91dto12y > 30 || this.quote.quote_versions[0].about_alpaca.alpaca_13y > 30 || this.quote.quote_versions[0].about_alpaca.alpaca_14y > 30) {
      return false;
    }

    this.quote.calculations = this.Calculations;
    this.quote.quote_versions[0].quote_page_index = 1;
    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      this.quote.company_id = this.sessionSt.retrieve('broker_company_id');
    } else {
      this.sessionSt.store('al_broker_company_id', this.quote.company_id)
    }

    this.quote.quote_versions[0].policy_start_date = formatDate(this.quote.quote_versions[0].policy_start_date, 'yyyy-MM-dd', 'en-US');
    if (this.quote.quote_versions[0].retroactive_date) {
      this.quote.quote_versions[0].retroactive_date = formatDate(this.quote.quote_versions[0].retroactive_date, 'yyyy-MM-dd', 'en-US');
    }
    this.quote.quote_versions[0].financial_details.alpaca_financials.alpaca_rate = this.Calculations.age_above_90_rate;
    this.quote.quote_versions[0].financial_details.alpaca_financials.alpaca_premium_amount = this.Calculations.age_above_90_premium;
    this.quote.quote_versions[0].financial_details.alpaca_financials.cria_rate = this.Calculations.age_7_90_rate;
    this.quote.quote_versions[0].financial_details.alpaca_financials.cria_premium_amount = this.Calculations.age_7_90_premium;
    this.quote.quote_versions[0].financial_details.alpaca_financials.age_13_rate = this.Calculations.age_13_rate;
    this.quote.quote_versions[0].financial_details.alpaca_financials.age_13_premium = this.Calculations.age_13_premium;
    this.quote.quote_versions[0].financial_details.alpaca_financials.age_14_rate = this.Calculations.age_14_rate;
    this.quote.quote_versions[0].financial_details.alpaca_financials.age_14_premium = this.Calculations.age_14_premium;
    this.quote.quote_versions[0].financial_details.alpaca_financials.economic_slaughter_rate = this.Calculations.male_economic_slaughter_rate;
    this.quote.quote_versions[0].financial_details.alpaca_financials.economic_slaughter_amount = this.Calculations.male_economic_slaughter_premium;
    this.quote.quote_versions[0].financial_details.alpaca_financials.life_saving_rate = this.Calculations.life_saving_surgical_fees;
    this.quote.quote_versions[0].financial_details.alpaca_financials.life_saving_amount = this.Calculations.life_saving_surgical_premium;
    this.quote.quote_versions[0].financial_details.alpaca_financials.veterinary_rate = this.Calculations.veterinary_fees;
    this.quote.quote_versions[0].financial_details.alpaca_financials.veterinary_amount = this.Calculations.veterinary_premium;
    this.quote.quote_versions[0].financial_details.alpaca_financials.alpaca_total_premium = this.Calculations.alpaca_moroality_premium;
    this.quote.quote_versions[0].financial_details.alpaca_financials.liability_total_premium = this.Calculations.public_liability_premium;
    this.quote.quote_versions[0].financial_details.alpaca_financials.basic_liability_premium = this.Calculations.basic_liabilty_premium;
    this.quote.quote_versions[0].financial_details.alpaca_financials.alpaca_premium = this.Calculations.alpaca_moroality_premium;
    this.quote.quote_versions[0].financial_details.alpaca_financials.minimum_basic_liability = this.Calculations.basic_liabilty_minimum_premium;
    this.quote.quote_versions[0].financial_details.alpaca_financials.minimum_alpaca_premium = this.Calculations.alpaca_experience_minimum_premium;
    this.quote.quote_versions[0].financial_details.alpaca_financials.minimum_acerage = this.Calculations.minimum_acerage;
    this.quote.quote_versions[0].financial_details.alpaca_financials.minimum_excess_acerage = this.Calculations.alpaca_excess_acrage;
    this.quote.quote_versions[0].financial_details.alpaca_financials.trailer_premium = this.Calculations.trailer_premium;
    this.quote.quote_versions[0].financial_details.alpaca_financials.trailer_rate = this.Calculations.trailer_rate;
    this.quote.quote_versions[0].financial_details.alpaca_financials.trailer_minimum_basic_premium = this.Calculations.trailer_minimum_premium;
    this.quote.quote_versions[0].financial_details.alpaca_financials.visit_insured_premises_premium = this.Calculations.visit_insured_premises;
    this.quote.quote_versions[0].financial_details.alpaca_financials.visit_to_external_premises_premium = this.Calculations.visit_to_extrenal_venues;
    this.quote.quote_versions[0].financial_details.alpaca_financials.visit_to_trade_stand_premium = this.Calculations.trade_stand;
    this.quote.quote_versions[0].financial_details.alpaca_financials.promoting_and_selling_premium = this.Calculations.promoting_selling_related_products;
    this.quote.quote_versions[0].financial_details.alpaca_financials.employer_liability_premium = this.Calculations.employer_liability;
    this.quote.quote_versions[0].financial_details.alpaca_financials.claim_loading_percentage = this.Calculations.claim_loading_percentage;
    this.quote.quote_versions[0].financial_details.alpaca_financials.claim_loading_amount = this.Calculations.claim_loading_amount;
    this.quote.quote_versions[0].financial_details.alpaca_financials.alpaca_experience_session_premium = this.Calculations.alpaca_experience_session_premium;

    this.quote.quote_versions[0].financial_details.total_policy_cost = this.Calculations.total_gross_premium;
    this.quote.quote_versions[0].financial_details.total_premium = this.Calculations.total_premium;
    this.quote.quote_versions[0].financial_details.ipt_fee = this.Calculations.ipt_charges;
    this.quote.quote_versions[0].financial_details.ipt_value = this.Calculations.ipt_amount;
    this.quote.quote_versions[0].financial_details.uw_fee = this.Calculations.administrative_charges;
    this.quote.quote_versions[0].financial_details.fees = this.Calculations.administrative_charges;
    this.quote.quote_versions[0].financial_details.policy_premium = this.Calculations.total_premium;
    this.quote.quote_versions[0].financial_details.total_policy_cost = this.Calculations.total_gross_premium;

    if (this.quote.quote_versions[0].alpaca_details.length > 0) {
      this.quote.quote_versions[0].alpaca_details.forEach((res) => {
        if (res.horse_purchase_date) {
          res.horse_purchase_date = formatDate(res.horse_purchase_date, 'yyyy-MM-dd', 'en-US');
        }
      });
    }

    let quote_temp = this.quote;

    if (this.route.snapshot.params.quote_type) {
      switch (this.route.snapshot.params.quote_type) {
        case '4':
          this.quote.quote_versions[0].insurance_status_id = '7a877243-0919-4f92-8c35-6f5732013ca6';
          break;
        case '5':
          this.quote.quote_versions[0].insurance_status_id = '0c836e51-f8b0-4df8-8736-3ce56a40869f';
          break;
        case '6':
          this.quote.quote_versions[0].insurance_status_id = '9bcea739-fd0b-4c67-980c-9e0dadeb8445';
          break;
        default:
          this.quote.quote_versions[0].insurance_status_id = 'e216564f-552a-4e45-ae67-0a63b7074691';
          break;
      }
      this.quote.quote_versions[0].type = this.route.snapshot.params.quote_type;
    }

    let data: any = '';
    data = {
      quote_data: this.quote
    };

    this.quoteService.saveQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quote = response.result.quote_data;
            this.quote.total_animals = quote_temp.total_animals;
            this.quote.quote_versions[0].alpaca_details = quote_temp.quote_versions[0].alpaca_details;
            this.quote.quote_versions[0].alpaca_trailor_items = quote_temp.quote_versions[0].alpaca_trailor_items;
            this.quote.quote_versions[0].smallholding_activities = quote_temp.quote_versions[0].smallholding_activities;
            if (this.quote.quote_versions[0].alpaca_details.length == 0) {
              // let total_animals = Number(quote_temp.quote_versions[0].about_alpaca.alpaca_7dto90d) + Number(quote_temp.quote_versions[0].about_alpaca.alpaca_91dto12y);
              // for (let i = 0; i < total_animals; i++) {
              //   this.quote.quote_versions[0].alpaca_details.push(new AlpacaDetails());
              // }
              for (let i = 0; i < Number(quote_temp.quote_versions[0].about_alpaca.alpaca_7dto90d); i++) {
                let alpaca_details = new AlpacaDetails();
                alpaca_details.is_cria = 1;
                this.quote.quote_versions[0].alpaca_details.push(alpaca_details);
              }
              for (let i = 0; i < (Number(quote_temp.quote_versions[0].about_alpaca.alpaca_91dto12y) + Number(this.quote.quote_versions[0].about_alpaca.alpaca_13y) + Number(this.quote.quote_versions[0].about_alpaca.alpaca_14y)); i++) {
                let alpaca_details = new AlpacaDetails();
                alpaca_details.is_cria = 2;
                this.quote.quote_versions[0].alpaca_details.push(alpaca_details);
              }
            }
            if (this.quote.quote_versions[0].alpaca_trailor_items.length == 0 && quote_temp.quote_versions[0].trailer_cover) {
              for (let i = 0; i < Number(quote_temp.quote_versions[0].trailer_cover.number_of_trailers); i++) {
                this.quote.quote_versions[0].alpaca_trailor_items.push(new HorseDetailsTrailer());
              }
            }
            // this.quote.calculations = this.indicativeCalculationsDetails;
            this.quote.calculations = this.Calculations;
            if (this.route.snapshot.params.quote_type != 4 && this.route.snapshot.params.quote_type != 5 && this.route.snapshot.params.quote_type != 6) {
              this.quoteDetailsUpdated.emit(this.quote);
              this.ProceedWithFullDetails.emit();
            } else {
              this.router.navigateByUrl("/policy-list");
            }

            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });

  }

}
