import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { formatDate } from '@angular/common';
import { NgForm } from '@angular/forms';
import { AlQuoteService } from '../../al-quote/al-quote.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserInfoService } from '../../../../services/user-info.service';

@Component({
  selector: 'app-admin-financials',
  templateUrl: './financials.component.html',
  styles: [
  ]
})
export class AlFinancialsComponent implements OnInit {
  @Input() quoteData: QuoteVersions;
  quote;

  constructor(private quoteService: AlQuoteService, private _snackBar: MatSnackBar, private userService: UserInfoService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteData;
  }

  ngOnInit(): void {
   
  }

}