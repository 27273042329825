import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Quote } from '../model/quote/quote.model';
import { QuoteService } from '../quote/quote.service';
import { PolicyService } from './policy.service';
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';
import { UserInfoService } from '../services/user-info.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {
  quoteData = new Quote();
  staticData;
  policyReasons;
  is_NTU: boolean = false;
  latestVersion: number = 0;

  constructor(private quoteService: QuoteService, private route: ActivatedRoute, private userService: UserInfoService,
    private policyService: PolicyService, private modalService: NgbModal, private router: Router, private sessionSt: LocalStorageService) { }

  isBroker: boolean = false;
  hasImportantNotes: boolean = false;
  totalImpNotes: number = 0;
  enableActions: boolean = false;
  ngOnInit(): void {
    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      this.isBroker = true;
    }
    // GET STATIC DATA
    this.quoteService.getStaticData()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.staticData = response.result;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // GET POLICY DETAILS
    this.policyService.getPolicyDetails(this.route.snapshot.params.quote_meta_id)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteData = response.result;
            let quote_versions = response.result.quote_versions.filter(x => x.is_policy == 1);
            this.quoteData.quote_versions = quote_versions;
            this.latestVersion = response.result.quote_versions[0].sequence_number;

            // Enable Actions Dropdown
            let currentDate = new Date()
            let policy_start_date = new Date(this.quoteData.quote_versions[0].policy_start_date);
            var Difference_In_Time = currentDate.getTime() - policy_start_date.getTime();
            if (Difference_In_Time >= 0) {
              this.enableActions = true;
            }

            // Enable NTU  
            // if (Difference_In_Time / (1000 * 3600 * 24) < 14 && this.sessionSt.retrieve('company_role') != 'Broker') {
            //   this.is_NTU = true;
            // }
            if (this.quoteData.quote_versions[0].policy_start_date && this.sessionSt.retrieve('company_role') == 'Broker') {
              let currentDate = new Date()
              let policy_start_date = new Date(this.quoteData.quote_versions[0].policy_start_date);
              var Difference_In_Time = currentDate.getTime() - policy_start_date.getTime();
              if (Difference_In_Time / (1000 * 3600 * 24) < 14) {
                this.is_NTU = true;
              }
            } else {
              this.is_NTU = true;
            }

            
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // API - GET NOTES
    this.quoteService.getNotes(this.route.snapshot.params.quote_meta_id)
      .subscribe(
        (response: any) => {
          let impNotes = response.result.filter(x => x.is_important == 1);
          this.totalImpNotes = impNotes.length;
          if (impNotes.length > 0) {
            this.hasImportantNotes = true;
          }
        });
  }

  ngOnChanges(changes: SimpleChanges) {

    let newDate = new Date();
    // if (this.quoteData.quote_versions[0].policy_end_date) {
    //   let policy_end_date = new Date(this.quoteData.quote_versions[0].policy_end_date);
    //   var Difference_In_Time = policy_end_date.getTime() - newDate.getTime();
    //   let Difference_In_Days_cancle = Difference_In_Time / (1000 * 3600 * 24);
    //   if (Difference_In_Days_cancle < 14 && this.sessionSt.retrieve('company_role') != 'Broker') {
    //     this.is_NTU = true;
    //   }
    // }
    if (this.quoteData.quote_versions[0].policy_start_date && this.sessionSt.retrieve('company_role') == 'Broker') {
      let currentDate = new Date()
      let policy_start_date = new Date(this.quoteData.quote_versions[0].policy_start_date);
      var Difference_In_Time = currentDate.getTime() - policy_start_date.getTime();
      if (Difference_In_Time / (1000 * 3600 * 24) < 14) {
        this.is_NTU = true;
      }
    } else {
      this.is_NTU = true;
    }
  }
  onhasImportantNotesChange(event) {
    this.hasImportantNotes = event;
    this.totalImpNotes = this.totalImpNotes + 1;
  }

  /*
  * Convet To MTA PopUp - List of Reasons
  */
  mtaMsg: string = '';
  convert_to_mta(content, type, delete_mta = 0) {
    if (type == 3) {
      this.policyService.mtaCheck(this.route.snapshot.params.quote_meta_id, delete_mta)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.modalService.dismissAll();
              this.router.navigateByUrl("/quote-new/" + this.quoteData.id + '/' + type);
            } else {
              this.modalService.open(content, { centered: true });
              this.mtaMsg = response.message;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.router.navigateByUrl("/quote-new/" + this.quoteData.id + '/' + type);
    }
  }

  /*
  * Convert To MTA
  * Renew
  */
  saveQuote(quote_type) {
    let data: any = '';

    this.quoteData.quote_versions[0].id = null;
    this.quoteData.quote_versions[0].financial_details.quote_id = null;
    this.quoteData.quote_versions[0].horse_details.quote_id = null;
    this.quoteData.quote_versions[0].policy_holder.quote_id = null;
    this.quoteData.quote_versions[0].statement_of_facts.forEach((res, index) => {
      res.quote_id = null;
    });
    this.quoteData.quote_versions[0].type = quote_type;
    this.quoteData.insurance_status_id = 'e216564f-552a-4e45-ae67-0a63b7074691';
    this.quoteData.quote_versions[0].policy_start_date = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');;

    data = {
      quote_data: this.quoteData
    };

    this.quoteService.saveIndicativeQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this.router.navigateByUrl("/quote-new/" + this.quoteData.id + '/1');
          }

        });
  }

  
}
