import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';

import { QuoteService } from '../../quote.service';
import { Quote } from '../../../model/quote/quote.model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserInfoService } from '../../../services/user-info.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.css']
})
export class QuoteDetailsComponent implements OnInit {
  quoteData = new Quote();
  staticData;
  isAdmin = false;

  constructor(private route: ActivatedRoute, private quoteService: QuoteService, private sessionSt: LocalStorageService,
    private router: Router, private modalService: NgbModal, private _snackBar: MatSnackBar, private userService: UserInfoService) { }


  hasImportantNotes: boolean = false;
  totalImpNotes: number = 0;
  isPolicy: boolean = false;
  hideForBroker: boolean = false;
  minPolicyDate;
  maxPolicyDate;

  ngOnInit(): void {
    if (this.sessionSt.retrieve('company_role') != 'Broker') {
      this.isAdmin = true;
    }
    // GET STATIC DATA
    this.quoteService.getStaticData()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.staticData = response.result;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // GET QUOTE DETAILS
    this.quoteService.getQuoteDetails(this.route.snapshot.params.quote_meta_id)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteData = response.result;
            let policy_start_date = new Date(response.result.quote_versions[0].policy_start_date);
            policy_start_date.setHours(0, 0, 0, 0);
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            // Hide convert to policy/Quote buttons if date is past date
            if (policy_start_date.getTime() < today.getTime() && this.sessionSt.retrieve('company_role') == 'Broker') {
              this.hideForBroker = true;
            }
            // response.result.quote_versions.forEach((res) => {
            //   if(res.id == this.sessionSt.retrieve('quote_ref_id')) {
            //     this.isPolicy = true;
            //     this.quoteData.quote_versions[0] = res;

            //   }
            // });
          }
        },
        (error) => {
          console.log(error);
        }
      );


  }

  ngOnChanges(changes: SimpleChanges) {
    // API - GET NOTES
    if (this.quoteData.quote_versions.length > 0) {
      this.quoteService.getNotes(this.quoteData.quote_versions[0].id)
        .subscribe(
          (response: any) => {
            let impNotes = response.result.filter(x => x.is_important == 1);
            this.totalImpNotes = impNotes.length;
            if (impNotes.length > 0) {
              this.hasImportantNotes = true;
            }
          });
    }
  }

  onhasImportantNotesChange(event) {
    this.hasImportantNotes = event;
    this.totalImpNotes = this.totalImpNotes + 1;
  }

  quoteDetailsUpdated(quotedata) {
    this.quoteData = quotedata;
  }

  /*
  * Update Quote Status
  */
  updateQuoteStatus(status_id) {
    let data = {
      quote_id: this.quoteData.quote_versions[0].id,
      insurance_status_id: status_id
    };

    this.quoteService.updateQuoteStatus(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteData.insurance_status_id = response.result.insurance_status_id;
            this.quoteData.quote_versions[0].insurance_status_id = response.result.insurance_status_id;
            window.location.reload();
          }
        });
  }

  /*
  * Convert to Policy
  */
  convertToPolicy(converttopolicymodal) {
    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      let maxDate = new Date().setDate(new Date().getDate() + 30);
      this.maxPolicyDate = new Date(maxDate);
      this.minPolicyDate = new Date();
    }
    this.modalService.open(converttopolicymodal, { centered: true });
  }

  convertToPolicyPost() {
    let quote_versions = this.quoteData.quote_versions.filter(x => x.is_policy == 1);
    let policy_ref_number = null;
    if (quote_versions.length > 0) {
      policy_ref_number = quote_versions[0].policy_ref_number;
    }

    let data: any = '';
    data = {
      quote_id: this.quoteData.quote_versions[0].id,
      policy_ref_number: policy_ref_number,
      policy_start_date: formatDate(this.quoteData.quote_versions[0].policy_start_date, 'yyyy-MM-dd', 'en-US')
    };
    this.quoteService.convertToPolicy(data)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          if (response.success) {
            this.router.navigate(['/policy', response.result.id]);
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

}
