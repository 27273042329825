import { CompanyUpsertAddress } from './company-upsert-address';
export class CompanyUpsert {
    id: string = '';
    parent_id: string = null;
    name: string = '';
    contact_name: string = '';
    registration_number: string = '';
    role_id: string = '';
    email: string = '';
    phone: string = '';
    mobile: string = '';
    website: string = '';
    business_agreement: string = '';
    terms_of_business: string = '';
    status: string = '';
    commission_percentage: number = 0;
    peliwica_pay_away_commission: number = 0;
    peliwica_ratained_commission: number = 0;
    uw_fees: number = 0;
    loading_percentage: number = 0;
    logo_path: string = '';
    address: any = new CompanyUpsertAddress();
 }