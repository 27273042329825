import { Component, OnInit,SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';
import { QuoteService } from '../../../quote/quote.service';
import { AlQuoteService } from '../../../quote/alpaca/al-quote/al-quote.service';
import { QuoteVersions } from '../../../quote/alpaca/model/quote/quote_versions.model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PolicyService } from '../../policy.service';
import { UserInfoService } from '../../../services/user-info.service';

@Component({
  selector: 'app-al-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.css']
})
export class AlPolicyDetailsComponent implements OnInit {
  quoteData = new QuoteVersions();
  staticData;
  isAdmin = false;
  is_NTU: boolean = false;

  constructor(private route: ActivatedRoute, private quoteService: QuoteService, private sessionSt: LocalStorageService,
    private router: Router, private modalService: NgbModal, private _snackBar: MatSnackBar, private policyService: PolicyService, private userService: UserInfoService) { }


  hasImportantNotes: boolean = false;
  totalImpNotes: number = 0;
  isPolicy: boolean = false;

  ngOnInit(): void {
    if (this.sessionSt.retrieve('company_role') != 'Broker') {
      this.isAdmin = true;
    }
    // GET QUOTE DETAILS
   this.quoteService.getQuoteDetailsByQuoteID(this.route.snapshot.params.quote_id)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteData = response.result;
            // Enable Actions Dropdown
            let currentDate = new Date()
            let policy_start_date = new Date(this.quoteData.policy_start_date);
            var Difference_In_Time = currentDate.getTime() - policy_start_date.getTime();
            
            // Enable NTU  
            if (Difference_In_Time / (1000 * 3600 * 24) < 14 && this.sessionSt.retrieve('company_role') != 'Broker') {
              this.is_NTU = true;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );


  }
  ngOnChanges(changes: SimpleChanges) {
    let newDate = new Date();
    if (this.quoteData.policy_end_date) {
      let policy_end_date = new Date(this.quoteData.policy_end_date);
      var Difference_In_Time = policy_end_date.getTime() - newDate.getTime();
      let Difference_In_Days_cancle = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days_cancle < 14 && this.sessionSt.retrieve('company_role') != 'Broker') {
        this.is_NTU = true;
      }
    }
  }
  onhasImportantNotesChange(event) {
    this.hasImportantNotes = event;
    this.totalImpNotes = this.totalImpNotes + 1;
  }

  quoteDetailsUpdated(quotedata) {
    this.quoteData = quotedata;
  }


  /*
   * Convet To MTA PopUp - List of Reasons
   */
  mtaMsg: string = '';
  policyActions(content, type, delete_mta = 0) {
    if (type == 3) {
      this.policyService.mtaCheck(this.quoteData.quote_meta_id, delete_mta)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.modalService.dismissAll();
              this.router.navigateByUrl("/alpaca-admin/pre-qualifier/" + this.quoteData.id + '/' + type);
            } else {
              this.modalService.open(content, { centered: true });
              this.mtaMsg = response.message;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.router.navigateByUrl("/alpaca-admin/pre-qualifier/" + this.quoteData.id + '/' + type);
    }
  }

}
