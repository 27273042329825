<app-header></app-header>
<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
        <div class="content-wrapper">

            <div class="breadcrums">Home > {{ pageTitle | titlecase }}</div>
            <div class="row">
                <div class="col-md-2">
                    <app-sidebar></app-sidebar>
                </div>
                <div class="col-md-10">
                    <div class="card dashboard content_section bg-success">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>