import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private sessionSt: LocalStorageService, protected router: Router) { }

  canActivate() {
    // REDIRECT TO LOGIN SCREEN IF NOT LOGGED IN -- WILL APPLY LATER
    if (!this.sessionSt.retrieve('login_token')) {
      this.router.navigateByUrl("/");
    }

    let current_path = window.location.pathname;
    current_path = current_path.substring(1);
    let url_elements = current_path.split('/');
    
    if (this.sessionSt.retrieve('company_role') == 'Peliwica' && this.sessionSt.retrieve('user_group') == 'Administrator') { 
      return true;
    } else if(this.sessionSt.retrieve('company_role') == 'Broker' && this.sessionSt.retrieve('user_group') == 'Administrator') {
      if(url_elements.length > 0 && (url_elements[0] == 'company' || url_elements[0] == 'bordereau')) {
        this.router.navigateByUrl("/dashboard");
        return false;
      } else {
        return true;
      }
    } else if(this.sessionSt.retrieve('user_group') == 'Staff') {
      if(url_elements.length > 0 && (url_elements[0] == 'company' || url_elements[0] == 'company-manage' || url_elements[0] == 'user' || url_elements[0] == 'user-list' || url_elements[0] == 'bordereau')) {
        this.router.navigateByUrl("/dashboard");
        return false;
      } else {
        return true;
      }
    } else {
      this.router.navigateByUrl("/dashboard");
      return false;
    }
  }
}