import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';
import { QuoteService } from '../../../quote.service';
import { AlQuoteService } from '../../al-quote/al-quote.service';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserInfoService } from '../../../../services/user-info.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-admin-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.css']
})
export class AlQuoteDetailsComponent implements OnInit {
  quoteData = new QuoteVersions();
  staticData;
  isAdmin = false;

  constructor(private route: ActivatedRoute, private quoteService: QuoteService, private adminQuoteService: AlQuoteService, private userService: UserInfoService,
    private sessionSt: LocalStorageService, private router: Router, private modalService: NgbModal, private _snackBar: MatSnackBar) { }


  hasImportantNotes: boolean = false;
  totalImpNotes: number = 0;
  hideForBroker: boolean = false;
  minPolicyDate;
  maxPolicyDate;
  ngOnInit(): void {
    
    if (this.sessionSt.retrieve('company_role') != 'Broker') {
      this.isAdmin = true;
    }
    // GET QUOTE DETAILS
    this.quoteService.getQuoteDetailsByQuoteID(this.route.snapshot.params.quote_id)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteData = response.result;
            let policy_start_date = new Date(response.result.policy_start_date);
            policy_start_date.setHours(0, 0, 0, 0);
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            // Hide convert to policy/Quote buttons if date is past date
            if (policy_start_date.getTime() < today.getTime() && this.sessionSt.retrieve('company_role') == 'Broker') {
              this.hideForBroker = true;
            } 
            
          }
        },
        (error) => {
          console.log(error);
        }
      );


  }

  onhasImportantNotesChange(event) {
    this.hasImportantNotes = event;
    this.totalImpNotes = this.totalImpNotes + 1;
  }

  quoteDetailsUpdated(quotedata) {
    this.quoteData = quotedata;
  }

  /*
  * Update Quote Status
  */
  msg: string = '';
  updateQuoteStatus(status_id, content) {
    let data = {
      quote_id: this.quoteData.id,
      insurance_status_id: status_id
    };

    this.quoteService.updateQuoteStatus(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteData.insurance_status_id = '80c0db90-ba5d-4387-b09a-4c008a664932';
            this.modalService.open(content, { centered: true });
            this.msg = response.message;
          }
        });
  }

  /*
  * Convert to Policy
  */
  convertToPolicy(converttopolicymodal) {
    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      let maxDate = new Date().setDate(new Date().getDate() + 30);
      this.maxPolicyDate = new Date(maxDate);
      this.minPolicyDate = new Date();
    }
    this.modalService.open(converttopolicymodal, { centered: true });
  }

  convertToPolicyPost() {
    let data: any = '';
    data = {
      quote_id: this.quoteData.id,
      policy_ref_number: this.quoteData.policy_ref_number,
      policy_start_date: formatDate(this.quoteData.policy_start_date, 'yyyy-MM-dd', 'en-US')
    };
    this.adminQuoteService.convertToPolicy(data)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          if (response.success) {
            this.router.navigate(['/alpaca-admin/policy', this.quoteData.id]);
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }

}
