import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Quote } from '../../model/quote/quote.model';
import { AlCalculationsDetails } from '../al-calculations/al-calculations.model';
import { environment } from '../../../../../environments/environment';
import { UserInfoService } from '../../../../services/user-info.service';

@Component({
  selector: 'app-admin-al-policy-and-payment',
  templateUrl: './al-policy-and-payment.component.html',
  styleUrls: ['../../../quote.component.css']
})
export class AdminAlPolicyAndPaymentComponent implements OnInit {
  @Output() BackToQuotationTab = new EventEmitter();
  @Input() quoteDetails: Quote;
  Calculations = new AlCalculationsDetails();
  client_website_link: string = '';

  constructor(private userService: UserInfoService) { }

  quote;
  ngOnInit(): void {
    this.quote = this.quoteDetails;
    this.client_website_link = environment.CLIENT_WEBSITE_LINK;
  }
  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;
  }

  /*
  * Back To Quotation Tab
  */
 backToQuotationTab() {
   this.BackToQuotationTab.emit();
 }

}
