import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Quote } from '../model/quote/quote.model';
import { QuoteService } from './quote.service';
import { ActivatedRoute, Router } from "@angular/router";
import { CalculationsDetails } from './calculations/calculations.model';
import { UserInfoService } from '../services/user-info.service';


@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css'],
})

export class QuoteComponent implements OnInit {
  selectedIndex: number = 0;
  @Input() quote;
  full_details_tab = false;
  statement_of_facts_tab = false;
  payment_details = false;
  submission = false;
  staticData;
  

  quoteDetailsForChildTemplates = new Quote();
  CalculationDetails = new CalculationsDetails();


  constructor(private quoteService: QuoteService, private route: ActivatedRoute, private router: Router, private userService: UserInfoService) {

  }


  ngOnInit(): void {  
    this.userService.getUserDetails();
    // GET STATIC DATA
    this.quoteService.getStaticData()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.staticData = response.result;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // GET QUOTE DETAILS
    if (this.route.snapshot.params.id) {
      let temp = this.quoteDetailsForChildTemplates;
      this.quoteService.getQuoteDetails(this.route.snapshot.params.id)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.quoteDetailsForChildTemplates = response.result;
              if (response.result.quote_versions.length > 0) { 
                let is_policy = 0;
                if(this.route.snapshot.params.quote_type) {
                  is_policy = 1;
                }
                let quote_versions = response.result.quote_versions.filter(x => x.is_policy == is_policy);
                if(quote_versions.length > 0) {
                  this.quoteDetailsForChildTemplates.quote_versions = quote_versions;
                } else {
                  if(!this.route.snapshot.params.quote_type && this.route.snapshot.params.id) {
                    this.router.navigate(['/policy', this.route.snapshot.params.id]);
                  } else {
                    this.quoteDetailsForChildTemplates.quote_versions = response.result.quote_versions.slice(-1);
                  }
                  
                }
                
              }
              

             // this.quoteDetailsForChildTemplates.calculations = this.CalculationDetails;
              
              // if (!this.route.snapshot.params.quote_type) { 
              //   this.full_details_tab = true;
              //   this.statement_of_facts_tab = true;
              //   this.payment_details = true;
              //   this.submission = true;
              // }

              
              if(response.result.quote_versions[0].is_aeio == 0) {
                this.quoteDetailsForChildTemplates.full_quote_section = true;
              } 
              
              this.quoteDetailsForChildTemplates.calculations.sum_insured = parseFloat(Number(response.result.quote_versions[0].horse_details.value).toFixed(2));
              if(response.result.quote_versions[0].policy_holder.address == null) {
                this.quoteDetailsForChildTemplates.quote_versions[0].policy_holder.address = temp.quote_versions[0].policy_holder.address;
              }
              if(response.result.quote_versions[0].horse_details.address == null) {
                this.quoteDetailsForChildTemplates.quote_versions[0].horse_details.address = temp.quote_versions[0].horse_details.address;
              }         
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
    
  }



  quoteDetailsUpdated(quoteDetailsForChildTemplates) {
    this.quoteDetailsForChildTemplates = null;

    this.quoteDetailsForChildTemplates = quoteDetailsForChildTemplates;
    
  }




  tabChanged(event) {
    var a = this.quoteDetailsForChildTemplates;
    this.quoteDetailsForChildTemplates = null;
    this.quoteDetailsForChildTemplates = a;
    if (event.index == 0) {
      this.quoteDetailsForChildTemplates.indicativePage = true;
    } else {
      this.quoteDetailsForChildTemplates.indicativePage = false;
    }
    
  }

  activateFullDetailsTab() {
    this.full_details_tab = true;
    this.selectedIndex += 1;
  }

  jumpToIndicativeQuoteDetilsTab() {
    this.quoteDetailsForChildTemplates.indicativePage = true;
    this.selectedIndex = 0;
  }
  jumpToStatementOfFactTab() {
    this.statement_of_facts_tab = true;
    this.selectedIndex = 2;
  }
  jumpToFullQuoteDetilsTab() {
    this.full_details_tab = true;
    this.selectedIndex = 1;
  }
  jumpToPayment() {
    this.payment_details = true;
    this.selectedIndex = 3;
  }
  jumpToUnderWritingSubmissionTab() {
    this.submission = true;
    this.selectedIndex = 4;
  }

}
