import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Quote } from '../../model/quote/quote.model';
import { CalculationsDetails } from '../calculations/calculations.model';
import { QuoteService } from '../quote.service';
import { UserInfoService } from '../../services/user-info.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['../quote.component.css'],
})
export class PaymentComponent implements OnInit {
  @Input() quoteDetails: Quote;
  @Output() quoteDetailsUpdated = new EventEmitter<Quote>();
  @Output() BackToFactStatement = new EventEmitter();
  @Output() UnderWritingSubmission = new EventEmitter();
  indicativeCalculationsDetails = new CalculationsDetails();
  quote: Quote;

  constructor(private quoteService: QuoteService, private userService: UserInfoService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;
    //this.quote.indicativePage = false;
    if (this.quoteDetails.calculations != '') {
      this.indicativeCalculationsDetails = this.quoteDetails.calculations;
    }
  }

  ngOnInit(): void {
   
  }

  backToFactStatementBtn() {
    this.BackToFactStatement.emit();
  }

  onSubmit(f: NgForm) {
    let data = {
      quote_id: this.quote.quote_versions[0].id,
      insurance_status_id: 'f6d14624-6351-4d93-b27b-70fb8a06ec5d',
      convert_to_quote_notes: this.quote.quote_versions[0].convert_to_quote_notes
    };
  
    this.quoteService.updateQuoteStatus(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteDetailsUpdated.emit(this.quote);
            this.UnderWritingSubmission.emit();
          }
        });

  }

}
