import { PolicyHolder } from './policy_holder.model';
import { HorseDetails } from './horse_details.model';
import { FinancialDetails } from './financial_details.model';
import { FactStatement } from './fact_statement.model';
import { HorseSubClass } from './horse_sub_class.model';
import { AboutAlpaca } from './about_alpaca.model';
import { AlpacaLiability } from './alpaca_liability.model';
import { SmallHoldingActivities } from './smallholding_activities.model';
import { AnimalType } from './animal_type.model';
import { EmployerLiability } from './employer_liability.model';
import { TrailerCover } from './trailer_cover.model';
import { HorseDetailsTrailer } from './horse_details_trailer.model';
import { AlpacaDetails } from './alpaca_details.model';
import { PolicyHolderAddress } from './policy_holder_address.model';

export class QuoteVersions {
    id: string = '';
    broker_id: string = '';   
    sequence_number: number = 0;    
    type: number = 1;
    quote_ref_number: string = '';       
    policy_ref_number: string = '';
    is_policy: number = 0;
    underwriter: string = null;
    insurer: string = null;
    policy_start_date: string = '';
    policy_end_date: string = '';
    create_date: string = '';
    insurance_status_id: string = 'e216564f-552a-4e45-ae67-0a63b7074691';
    horse_class_id: string = '';
    horse_class_sub_id: string = '';
    section_cover_sum_insured_id: string = '';
    vet_fee_sum_insured_id: string = '';
    company_excess_id: string = '';
    company_co_pay_excess_id: string = '';
    addon_saddlery_value: number = null;
    addon_trailer_value: number = null;
    does_policy_owner_owns_horse: number = 0;
    is_domicile_in_UK: number = 0;
    quote_meta_id: string = '';
    policy_cover_id: string = '';
    blood_stock_id: string = '';
    blood_stock_arm_linking_id: string = '';
    blood_stock_vet_fee_linking_id: string = '';
    is_aeio: number = 0;
    does_addon_saddlery_on: number = 0;
    does_addon_trailer_on: number = 0;
    domicile_details: string = '';
    is_accepted: number = null;
    life_saving_surgery_id: string = '';
    life_saving_annual_surgery_id: string = '';
    personal_accidental_cover_id: string = '';
    public_liability_cover_id: string = '';
    co_insurance_towards_diagnostic_id: string = '';
    tigerlab_policy_reference: string = '';
    mta_reason_id: string = null;
    mta_reason_other: string = '';
    quote_converted_date: string = '';
    policy_converted_date: string = '';
    
    // Alpaca
    quote_page_index: number = 0;
    alpaca_theft_cover: number = 1;
    alpaca_public_liability_cover: number = null;
    alpaca_trailer_cover: number = null;
    is_animal_location_same_as_proposer: number = null;
    number_of_claims: number = null;
    quote_document: string = '';
    latest_sequence_number: number = 0;
    retroactive_date: string = '';
    previous_policy_amount: number = null;

    horse_sub_class: HorseSubClass = new HorseSubClass();
    policy_holder:PolicyHolder = new PolicyHolder();
    horse_details:HorseDetails = new HorseDetails();
    financial_details:FinancialDetails = new FinancialDetails();
    statement_of_facts:Array<FactStatement> = [new FactStatement()];
    documents = [];
    endorsements = [];
    notes = [];

    // Alpaca
    about_alpaca:AboutAlpaca =  new AboutAlpaca();
    alpaca_liability:AlpacaLiability =  new AlpacaLiability();
    smallholding_activities:SmallHoldingActivities =  new SmallHoldingActivities();
    animal_type:AnimalType =  new AnimalType();
    employer_liability:EmployerLiability =  new EmployerLiability();
    trailer_cover:TrailerCover =  new TrailerCover();
    alpaca_trailor_items: Array<object> = [new HorseDetailsTrailer()];
    alpaca_details: Array<object> = [new AlpacaDetails()];
    animal_address: PolicyHolderAddress = new PolicyHolderAddress();
}
