<div class="calculator">
    <mat-accordion>
        <mat-expansion-panel *ngIf="quote.quote_versions[0].alpaca_theft_cover == 1">
            <mat-expansion-panel-header>
                <mat-panel-title class="f13 mb-0">
                    Alpaca Mortality
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="cal_body p-3 bg-white">
                <h5>Alpaca Mortality</h5>
                <p>
                    <b>Policy premium</b><br>&pound;{{calculationsDe.alpaca_moroality_premium}}
                </p>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="quote.quote_versions[0].alpaca_public_liability_cover == 1" class="mt-2">
            <mat-expansion-panel-header>
                <mat-panel-title class="f13 mb-0">
                    Public & Product Liability
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="cal_body p-3 bg-white">
            <h5 class="mt-3">Public & Product Liability</h5>
            <p>
                <b>Public Liability premium</b><br>&pound;{{calculationsDe.public_liability_premium}}
            </p>
        </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="quote.quote_versions[0].alpaca_trailer_cover == 1" class="mt-2">
            <mat-expansion-panel-header>
                <mat-panel-title class="f13 mb-0">
                    Trailer
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="cal_body p-3 bg-white">
            <h5>Trailer</h5>
            <p>
                <b>Policy premium</b><br>&pound;{{calculationsDe.trailer_premium}}
            </p>
        </div>
        </mat-expansion-panel>
        <mat-expansion-panel expanded class="mt-2">
            <mat-expansion-panel-header>
                <mat-panel-title class="f13 mb-0">
                    Totals
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="cal_body p-3 bg-white">
            <p>
                <b>Total Policy Premium</b><br>&pound;{{calculationsDe.total_premium}}
            </p>
            <p>
                <b>Insurance premium tax (12%)</b><br>&pound;{{calculationsDe.ipt_amount}}
            </p>
            <p>
                <b>Total premium</b><br>&pound;{{calculationsDe.total_premium_without_fee}}
            </p>
            <p>
                <b>Fees</b><br>&pound;{{calculationsDe.administrative_charges}}
            </p>
            <p>
                <b>Total cost of the policy</b><br>&pound;{{calculationsDe.total_gross_premium}}
            </p>
        </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div class="btn btn-default btn-gray text-white mt-2 w-100 text-left">
        Quote Reference: {{quote.quote_versions[0].quote_ref_number}}
    </div>
</div>