import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Quote } from '../model/quote/quote.model';
import { QuoteService } from '../../quote.service';
import { AlQuoteService } from './al-quote.service';
import { PolicyService } from '../../../policy/policy.service';
import { environment } from '../../../../environments/environment';
import { UserInfoService } from '../../../services/user-info.service';

@Component({
  selector: 'app-al-quote',
  templateUrl: './al-quote.component.html',
  styleUrls: ['../../quote.component.css']
})
export class AdminAlQuoteComponent implements OnInit {
  quoteDetailsForChildTemplates = new Quote();
  selectedIndex: number = 0;
  quote_tab: boolean = true;
  quote_details_tab: boolean = false;
  full_details_tab: boolean = false;
  statement_of_facts_tab: boolean = false;
  quotation_tab: boolean = false;
  polciy_payment_tab: boolean = false;
  staticData;
  brokers = [];
  allPolicyReasons = [];
  reasons = [];

  constructor(private quoteService: QuoteService, private alquoteService: AlQuoteService,
    private route: ActivatedRoute, private policyService: PolicyService, private userService: UserInfoService) { }

  ngOnInit(): void {
    // GET STATIC DATA
    this.alquoteService.getStaticData()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.staticData = response.result;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // Get All Brokers
    this.quoteService.getBrokers(0)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.brokers = response.result;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // GET QUOTE DETAILS
    if (this.route.snapshot.params.quote_id) {
      this.quoteService.getQuoteDetailsByQuoteID(this.route.snapshot.params.quote_id)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.quoteDetailsForChildTemplates.quote_versions[0] = response.result;
              this.quoteDetailsForChildTemplates.id = response.result.quote_meta_id;
              this.quoteDetailsForChildTemplates.company_id = response.result.company_id;
              this.QuoteDetailsPage = true;
              if (this.route.snapshot.params.quote_type && this.route.snapshot.params.quote_type != 3) {
                this.selectedIndex = 1;
                this.quote_tab = false;
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }

    // Get Policy Reasons    
    this.policyService.getAlpacaPolicyMTAReasons()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.allPolicyReasons = response.result.types;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /*
  * Activate Indicative Quote Tab
  */
  activateIndicativeQuoteTab() {
    this.selectedIndex = 0;
  }
  /*
  * Activate Indicative Quote Detais Page
  */
  QuoteDetailsPage: boolean = false;
  activateIndicativeQuoteDetailsPage() {
    this.QuoteDetailsPage = true;
    this.quote_details_tab = true;
    this.selectedIndex = 1;
  }

  /*
  * Activate Full Details Page
  */
  activateFullDetailsTab() {
    this.selectedIndex = 2;
    this.full_details_tab = true;
  }
  /*
  * Activate SOF Page
  */
  activateSOFTab() {
    this.selectedIndex = 3;
    this.statement_of_facts_tab = true;
  }
  /*
  * Activate Quotation Tab
  */
  activateQuotationTab() {
    this.selectedIndex = 4;
    this.quotation_tab = true;
    //if (!this.route.snapshot.params.quote_id) {
    this.quote_tab = false;
    this.quote_details_tab = false;
    this.full_details_tab = false;
    this.statement_of_facts_tab = false;
    //}
  }
  /*
  * Activate Policy & Payment Tab
  */
  activatePolicyPaymentTab() {
    this.selectedIndex = 5;
    this.polciy_payment_tab = true;
    //if (!this.route.snapshot.params.quote_id) {
    this.quote_tab = false;
    this.quote_details_tab = false;
    this.full_details_tab = false;
    this.statement_of_facts_tab = false;
    this.quotation_tab = false;
    //}
  }

  /*
  * Update Quote Object After Form Save
  */
  quoteDetailsUpdated(quoteDetailsForChildTemplates) {
    this.quoteDetailsForChildTemplates = null;
    this.quoteDetailsForChildTemplates = quoteDetailsForChildTemplates;

  }
}
