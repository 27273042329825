import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';
import { PolicyService } from '../policy.service';
import { GridOptions } from "ag-grid-community";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Quote } from '../../model/quote/quote.model';
import { UserInfoService } from '../../services/user-info.service';

@Component({
  selector: 'app-policy-compliance',
  templateUrl: './policy-compliance.component.html',
  styles: [
  ]
})
export class PolicyComplianceComponent implements OnInit {
  @Input() quote: Quote;
  constructor(private policyService: PolicyService, private modalService: NgbModal, private userService: UserInfoService) { }

  ngOnInit(): void {
   
  }
  ngOnChanges(changes: SimpleChanges) {
    // GET COMPLIANCE ASSIST DATA
    if (this.quote && this.quote.quote_versions.length > 0 && this.quote.quote_versions[0].id) {
      this.policyService.getComplianceAssistDetails(this.quote.quote_versions[0].id)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.rowData = response.result;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }

  }

  // List Quote Versions
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: 'Level', field: 'level', sortable: true, width: 100
    },
    {
      headerName: 'Request ID', field: 'request_id', sortable: true, width: 100
    },
    {
      headerName: 'Message', field: 'msg_txt', sortable: true, width: 350,
      tooltip: function (params) {
        return params.data.msg_txt;
      },
    },
    {
      headerName: 'Sanctions', field: 'sanctions_peps_result', sortable: true
    },
    {
      headerName: 'Request Time', field: 'timestamp', sortable: true,
      cellRenderer: function (params) {
        return formatDate(params.data.timestamp, 'dd-MM-yyyy H:m', 'en-US');
      }
    }
  ];
  rowData: any = [];

  /*
  * Compliance Assist
  */
  msg;
  compliance_assist(messagepopup) {
    let data: any = '';
    data = {
      quote_id: this.quote.quote_versions[0].id
    };
    this.policyService.complianceAssist(data)
      .subscribe(
        (response: any) => {
          if (!response.success) {
            if (response.result.errors.length > 0) {
              this.msg = response.result.errors[0];
              this.modalService.open(messagepopup, { centered: true });
            } else {
              this.msg = response.message;
              this.modalService.open(messagepopup, { centered: true });
             }
          } else {
            this.msg = 'Compliance data saved.';
            this.modalService.open(messagepopup, { centered: true });
            this.rowData = response.result;
          }

        });
  }

}
