import { PolicyHolderAddress } from './policy_holder_address.model';
export class PolicyHolder {
    id: string = '';
    quote_id: string = '';
    policy_id: string = '';
    address_id: string = '';
    first_name: string = '';
    last_name: string = '';
    phone: string = '';
    email: string = '';
    dob: string = '';
    address:PolicyHolderAddress = new PolicyHolderAddress();
}
