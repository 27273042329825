<form #f="ngForm">
    <input type="hidden" name="id" placeholder="id" class="form-control form-control-sm" [(ngModel)]="quote.id">
    <div *ngFor="let statementOfFact of statementOfFacts; let i = index;">
        <div class="form-group row">
            <div class="col-md-12">
                <div class="quote_radio w-90">
                    <label class="p-3"> 
                        {{statementOfFact.description.replace('[HORSENAME]',this.quote.quote_versions[0].horse_details.name)}}
                    </label>
                </div>
                <span (click)="helpTextPopup(content,statementOfFact.description)"><i
                        class="mdi mdi-comment-question-outline ml-1"></i></span>
            </div>
        </div>
        <div class="form-group row" *ngIf="statementOfFact.type == 1">
            <div class="col-md-3">
                <mat-radio-group aria-label="Select an option"
                    name="statementOfFact_{{quote.quote_versions[0].statement_of_facts[i].question_id}}"
                    [(ngModel)]="quote.quote_versions[0]?.statement_of_facts[i].response">
                    <div class="quote_radio row mb-3">
                        <div class="col-md-6">
                            <mat-radio-button [value]="1" disabled
                                name="statementOfFact_{{quote.quote_versions[0].statement_of_facts[i].question_id}}"
                                ngDefaultControl [(ngModel)]="quote.quote_versions[0]?.statement_of_facts[i].response">Yes</mat-radio-button>
                        </div>
                        <div class="col-md-6">
                            <mat-radio-button [value]="2" disabled
                                name="statementOfFact_{{quote.quote_versions[0].statement_of_facts[i].question_id}}"
                                ngDefaultControl [(ngModel)]="quote.quote_versions[0]?.statement_of_facts[i].response">No</mat-radio-button>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-11"
                *ngIf="quote.quote_versions[0]?.statement_of_facts[i].response == 1">
                <textarea name="response_text_{{quote.quote_versions[0].statement_of_facts[i].question_id}}"
                    class="form-control" placeholder="Please provide response text" readonly
                    [(ngModel)]="quote.quote_versions[0].statement_of_facts[i].response_text">
                </textarea>
            </div>
        </div>
        <div class="form-group row" *ngIf="statementOfFact.type == 2">
            <div class="col-md-11">
                <input class="form-control" type="text" placeholder="Please provide details" readonly
                    name="statementOfFact_{{statementOfFact.id}}" [(ngModel)]="statementOfFact.response_text">
            </div>
        </div>
    </div>
    
</form>
<ng-template #content let-modal>
    <div class="modal-body">
        <div [innerHTML]="helptext.replace('[HORSENAME]',this.quote.quote_versions[0].horse_details.name)"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>