<div class="card-header bg-success">
    <h3>Manage Users
        <small><a [routerLink]="['/user-list',company_id]" class="text-white float-right"><i
                    class="mdi mdi-arrow-left"></i> Back to
                list</a></small>
    </h3>
</div>

<mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChanged($event)">
    <mat-tab>
        <ng-template mat-tab-label>
            Basic Information
        </ng-template>
        <div class="card-body">
            <form #f="ngForm" (ngSubmit)="f.form.valid && onSubmit(f)">
                <input type="hidden" name="id" [(ngModel)]="user.id">
                <input type="hidden" name="company_id" [(ngModel)]="company_id">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Email <span class="text-danger">*</span></label>
                            <input type="text" name="username" [(ngModel)]="user.username" required #username="ngModel"
                                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                [ngClass]="{ 'is-invalid': f.submitted && username.invalid }"
                                class="form-control form-control-sm" placeholder="Email">
                            <div *ngIf="f.submitted && username.errors && (username.invalid || username.touched)"
                                class="invalid-feedback">
                                <small *ngIf="username.errors?.invalid">Email is required.</small>
                                <small *ngIf="username.errors?.pattern">Please provide a valid email address</small>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>First Name</label>
                            <input type="text" name="first_name" [(ngModel)]="user.userinfo.first_name"
                                class="form-control form-control-sm" placeholder="First name">
                        </div>

                        <div class="form-group">
                            <label>Last Name </label>
                            <input type="text" name="last_name" [(ngModel)]="user.userinfo.last_name"
                                class="form-control form-control-sm" placeholder="Last name">
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div class="form-group">
                            <label>Phone </label>
                            <input type="text" name="phone" [(ngModel)]="user.userinfo.phone"
                                class="form-control form-control-sm" placeholder="Phone">
                        </div>
                        <div class="form-group">
                            <label>Group <span class="text-danger">*</span></label>
                            <select class="form-control custom-select custom-select-sm" name="group_id"
                                [(ngModel)]="user.group_user_linking.group_id" required #group_id="ngModel"
                                [ngClass]="{ 'is-invalid': f.submitted && group_id.invalid }">
                                <option *ngFor="let group of groups" value="{{group.id}}">
                                    {{group.name}}</option>
                            </select>
                            <div *ngIf="f.submitted && group_id.errors"
                                class="invalid-feedback">
                                <small>Please choose an option.</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Support Email <span class="text-danger">*</span></label>
                            <input type="text" name="support_email" [(ngModel)]="user.userinfo.support_email"
                                class="form-control form-control-sm" placeholder="Support Email" required
                                #support_email="ngModel"
                                [ngClass]="{ 'is-invalid': f.submitted && support_email.invalid }">
                            <div *ngIf="f.submitted && support_email.errors"
                                class="invalid-feedback">
                                <small>Support email is required.</small>
                            </div>
                        </div>
                        <!-- <div class="form-group row mt-5">
                            <label class="col-md-2">Status</label>
                            <div class="col-md-10">
                                <label class="toggle-switch toggle-switch-dark">
                                    <input type="checkbox" checked="" name="status" [(ngModel)]="user.status">
                                    <span class="toggle-slider round"></span>
                                </label>
                            </div>
                        </div> -->
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="form-group">
                            <label>Role</label>
                            <select class="form-control custom-select custom-select-sm" name="role_id"
                                [(ngModel)]="user.user_group_linking.role_id">
                                <option value="">Select Role</option>
                              <option *ngFor="let role of userroles" value="{{role.id}}">{{role.name}}</option>
                            </select>
                        </div>
                  
                

                    </div> -->
                </div>
                <div class="form-group text-right">
                    <input type="submit" name="submit" value="Save Basic Details" class="btn btn-default btn-gray">
                    <a class="btn btn-success ml-3" [class.disabled]="!activateAddressTab"
                        (click)='NextBtnAction()'>Next <i class="mdi mdi-arrow-right"></i></a>
                </div>

            </form>



        </div>
    </mat-tab>
    <mat-tab [disabled]="!activateAddressTab">
        <ng-template mat-tab-label>
            Address

        </ng-template>
        <div class="card-body">
            <form #fa="ngForm" (ngSubmit)="fa.form.valid && onAddressSubmit(fa)">
                <input type="hidden" name="id" [(ngModel)]="user.address.id">
                <input type="hidden" name="user_id" [(ngModel)]="user.id">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Address 1 <span class="text-danger">*</span></label>
                            <input type="text" name="address1" [(ngModel)]="user.address.address1" required
                                #address1="ngModel" [ngClass]="{ 'is-invalid': fa.submitted && address1.invalid }"
                                class="form-control form-control-sm" placeholder="Address 1">
                            <div *ngIf="fa.submitted && address1.invalid" class="invalid-feedback">
                                Address line 1 is required.
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Address 2</label>
                            <input type="text" name="address2" [(ngModel)]="user.address.address2"
                                class="form-control form-control-sm" placeholder="Address 2">
                        </div>
                        <div class="form-group">
                            <label>Address 3</label>
                            <input type="text" name="address3" [(ngModel)]="user.address.address3"
                                class="form-control form-control-sm" placeholder="Address 3">
                        </div>
                        <div class="form-group">
                            <label>Address 4</label>
                            <input type="text" name="address4" [(ngModel)]="user.address.address4"
                                class="form-control form-control-sm" placeholder="Address 4">
                        </div>

                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>City <span class="text-danger">*</span></label>
                            <input type="text" name="city" [(ngModel)]="user.address.city" required #city="ngModel"
                                [ngClass]="{ 'is-invalid': fa.submitted && city.invalid }"
                                class="form-control form-control-sm" placeholder="City">
                            <div *ngIf="fa.submitted && city.invalid" class="invalid-feedback">
                                City is required.
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Country <span class="text-danger">*</span></label>
                            <input type="text" name="country" [(ngModel)]="user.address.country" required
                                #country="ngModel" [ngClass]="{ 'is-invalid': fa.submitted && country.invalid }"
                                class="form-control form-control-sm" placeholder="Country">
                            <div *ngIf="fa.submitted && country.invalid" class="invalid-feedback">
                                Country is required.
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Postcode <span class="text-danger">*</span></label>
                            <input type="text" name="postcode" [(ngModel)]="user.address.postcode" required
                                #postcode="ngModel" [ngClass]="{ 'is-invalid': fa.submitted && postcode.invalid }"
                                class="form-control form-control-sm" placeholder="Postcode">
                            <div *ngIf="fa.submitted && postcode.invalid" class="invalid-feedback">
                                Postcode is required.
                            </div>
                        </div>

                    </div>
                </div>
                <div class="form-group text-right">
                    <a class="btn btn-success mr-3" (click)='PreviousBtnAction()'><i class="mdi mdi-arrow-left"></i>
                        Previous</a>
                    <input type="submit" name="submit" value="Save Address" class="btn btn-default btn-gray">
                </div>
            </form>
        </div>

    </mat-tab>
</mat-tab-group>