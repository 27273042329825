import { Injectable } from '@angular/core';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {

  constructor(private sharedService: SharedService) { }

  // Get Policy Statuses
  getPolicyStatuses() {
    return this.sharedService.getAPI('staticdata/insurance');
  }
  // Get Policy Details
  getPolicyDetails(quote_meta_id) {
    if (quote_meta_id) {
      return this.sharedService.getAPI('policy/' + quote_meta_id);
    }
  }

  // Search Policy Details
  searchPolicy(data) {
    return this.sharedService.postAPI('policy/search', data);
  }

  //Get Policy Versions
  getPolicyVersions(quote_meta_id) {
    if (quote_meta_id) {
      return this.sharedService.getAPI('policy/versions/' + quote_meta_id);
    }
  }

  //Get Policy Reasons
  getPolicyMTAReasons() {
    return this.sharedService.getAPI('staticdata/get_policy_reasons');
  }
  //Get Alpaca Policy Reasons
  getAlpacaPolicyMTAReasons() {
    return this.sharedService.getAPI('staticdata/get_alpaca_policy_reasons');
  }

  //Check MTA
  mtaCheck(quote_meta_id, delete_MTA) {
    if (quote_meta_id) {
      return this.sharedService.getAPI('policy/mta/check/' + quote_meta_id + '/' + delete_MTA);
    }
  }

  // Get Documents
  getPolicyDocuments(quote_id) {
    if (quote_id) {
      return this.sharedService.getAPI('quote/documents/' + quote_id + '/2');
    }
  }

  // Post Compliance Assist
  complianceAssist(data) {
    return this.sharedService.postAPI('complianceassist', data);
  }
  // Get Compliance Assist
  getComplianceAssistDetails(quote_id) {
    if (quote_id) {
      return this.sharedService.getAPI('complianceassist/' + quote_id);
    }
  }
  // Save Policy Document
  savePolicyDocument(formData) {
    return this.sharedService.postFormData('policy/document', formData);
  }

  // Regenerate Schedule
  regenerateSchedule(quote_id,is_policy) {
    if (quote_id) {
      return this.sharedService.getAPI('policy/document/regenerate/' + quote_id + '/' + is_policy);
    }
  }
}
