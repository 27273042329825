import { Component, OnInit, Input,EventEmitter, SimpleChanges, 
  Output   } from '@angular/core';
import { CalculationsDetails } from './calculations.model';
import { Quote } from '../../model/quote/quote.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-calculations',
  templateUrl: './calculations.component.html',
  styleUrls: ['../quote.component.css'],
  
})
export class CalculationsComponent implements OnInit {
  @Input() quoteInfo: Quote;
  quote: Quote;
  @Input() calculationsIndicative;
  @Input() indicativeQuoteCalculationSection;
  @Input() indicativeCalculationsDetails:CalculationsDetails;
  @Input() componentName;
  calculationsDe;
  //indicativeCalculationsDetails

  // @Input()
  //   set indicativeCalculationsDetails(value: any) {
  //       this.calculationsDe = value;
  //   }


  constructor(private modalService: NgbModal) { }
 
  temp;
  ngOnChanges(changes: SimpleChanges) {
    this.calculationsDe = null;
    this.calculationsDe = this.indicativeCalculationsDetails;
    this.quote = this.quoteInfo;    
    
  }

  ngOnInit(): void {
  //  this.calculationsDe = this.indicativeCalculationsDetails;
   this.quote = this.quoteInfo;
  }

  /*
  * HELP TEXT POPUP
  */
 helptext = '';
 helpTextPopup(content) {
   this.modalService.open(content, { centered: true });
 }
 


}
