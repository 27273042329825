import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuoteService } from '../../../quote.service';
import { ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { QuoteNotes } from '../../../../model/quote/quote_notes.model';
import { UserInfoService } from '../../../../services/user-info.service';
import { formatDate } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-admin-notes',
  templateUrl: './notes.component.html',
  styles: [
  ]
})
export class AlNotesComponent implements OnInit {
  @Input() quoteData: QuoteVersions;
  @Output() hasImportantNotes = new EventEmitter();
  notes = new QuoteNotes();
  quote;

  constructor(private quoteService: QuoteService, private modalService: NgbModal, private sessionSt: LocalStorageService) { }
  ngOnChanges(changes: SimpleChanges) {

    this.quote = this.quoteData;
    this.rowData = this.quoteData.notes
    if (this.sessionSt.retrieve('company_role') == 'Peliwica' && this.sessionSt.retrieve('user_group') == 'Administrator') {
      this.quoteData.notes.forEach((res) => {
        res.isAdmin = true;
      })              
    }
  }
  ngOnInit(): void {

  }

  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: 'Notes', sortable: false, width: 120,
      cellRenderer: function (params) {
        return (params.data.is_important == 1) ? '<label class="badge badge-danger">Important</label> ' : '';

      }
    },
    {
      headerName: ' ', field: 'notes', sortable: true, width: 500,
      tooltip: function (params) {
        return params.data.notes;
      },
    },
    {
      headerName: 'Created By', sortable: false, width: 120,
      cellRenderer: function (params) {
        if (params.data.user) {
          return params.data.user.first_name + ' ' + params.data.user.last_name;
        }
      }
    },
    {
      headerName: 'Created At', sortable: false, width: 120,
      cellRenderer: function (params) {
        return formatDate(params.data.created_at, 'dd-MM-yyyy', 'en-US');
      }
    },
    {
      headerName: null,
      width: 120,
      cellRenderer: function (params) {
        let links = '';
        if (params.data.isAdmin) {
          links += '<a title="Edit Notes" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
        }
        // links += '<a title="Delete" style="font-size: 18px; margin-left: 20px;"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete" id="' + params.data.id + '"></i></a>';
        return links;
      }
    }
  ];
  rowData: any;

  selectedRow;
  onGridRowClicked(e: any, content, deleteModel) {
    if (e) {

      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {

            const modalRef = this.modalService.open(content, { centered: true });
            this.notes = e.data;
            break;
          }
        case "delete": {
          this.modalService.open(deleteModel, { centered: true });
          this.selectedRow = this.gridOptions.api.getFocusedCell();
          this.notes = e.data;
        }
      }
    }
  }

  deleteNotes(id, selectedRow) {
    this.quoteService.deleteNotes(id)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          this.gridOptions.rowData.splice(selectedRow.rowIndex, 1);
          this.gridOptions.api.setRowData(this.gridOptions.rowData);
        });
  }

  /*
  * ADD NOTES
  */
  openNotesPopup(content) {
    this.modalService.open(content, { centered: true });
    this.notes = new QuoteNotes();
  }

  saveNotes() {
    let data = {
      id: this.notes.id,
      quote_id: this.quote.id, //this.route.snapshot.params.quote_meta_id,
      notes: this.notes.notes,
      is_important: this.notes.is_important
    };
    this.quoteService.saveNotes(data)
      .subscribe(
        (response: any) => {
          if (this.notes.id == '') {
            this.gridOptions.rowData.push({
              'id': response.result.note_id,
              'notes': this.notes.notes,
              'user': response.result.user,
              'created_at': response.result.created_at,
              'is_important': this.notes.is_important,
              'isAdmin': (this.sessionSt.retrieve('company_role') == 'Peliwica' && this.sessionSt.retrieve('user_group') == 'Administrator')?true:false
            
            });
            this.gridOptions.api.setRowData(this.gridOptions.rowData);
            
          } 
          this.isImportantNote();
          this.gridOptions.api.refreshCells();
          this.modalService.dismissAll();
        });
  }
  isImportantNote() {
    let is_important = false;
    this.rowData.forEach((res) => {
      if (res.is_important == 1) {
        is_important = true;
      }
    })
    this.hasImportantNotes.emit(is_important);
  }
}
