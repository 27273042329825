import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { GridOptions } from "ag-grid-community";
import { QuoteService } from '../../../quote/quote.service';
import { ActivatedRoute } from "@angular/router";
import { formatDate } from '@angular/common';
import { QuoteVersions } from '../../../quote/alpaca/model/quote/quote_versions.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../../../environments/environment';
import { PolicyService } from '../../policy.service';
import { UserInfoService } from '../../../services/user-info.service';

import {
  saveAs as importedSaveAs
} from "file-saver";

@Component({
  selector: 'app-al-policy-documents',
  templateUrl: './documents.component.html',
  styles: [
  ]
})
export class AlPolicyDocumentsComponent implements OnInit {
  @Input() quoteData: QuoteVersions;
  isPolicy: boolean = false;
  quote;

  constructor(private route: ActivatedRoute, private quoteService: QuoteService, private policyService: PolicyService,
    private _snackBar: MatSnackBar, private modalService: NgbModal, private sessionSt: LocalStorageService, private userService: UserInfoService) { }

  previousSequenceNumber = 0;
  ngDoCheck() {
   if (this.previousSequenceNumber != this.quote.sequence_number) {
      this.getDocuments();
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteData;
    if (this.sessionSt.retrieve('quote_ref_id')) {
      this.isPolicy = true;
    }
    this.previousSequenceNumber = this.quote.sequence_number;
    this.getDocuments();
  }
  ngOnInit(): void {
    
  }

  // GET QUOTE DOCUMENTS
  getDocuments() {
    if (this.quote.id != '') {
      this.policyService.getPolicyDocuments(this.quote.id)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.rowData = response.result;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  // List Quote Versions
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    { headerName: 'Document Name', field: 'title', sortable: true, width: 300, filter: false },
    { headerName: 'Document Version', field: 'quote_version', sortable: true, filter: false },
    {
      headerName: 'Creation Date', sortable: true, filter: false,
      cellRenderer: function (params) {
        return formatDate(params.data.created_date, 'dd-MM-yyyy', 'en-US');
      }
    },
    {
      headerName: null,
      width: 130, filter: false,
      cellRenderer: function (params) {
        // let links = '<a target="_blank" href="' + environment.imageURL + params.data.download_path + '" title="Download Document" style="font-size: 18px;color: #138a51;"><i class="mdi mdi-cloud-download"  download_path="' + params.data.download_path + '"></i></a>';
        let links = '<a class="ml-2" title="Download Document" style="font-size: 18px;color: #138a51;"><i class="mdi mdi-cloud-download"  data-action-type="download"></i></a>';
        return links;
      }
    }
  ];
  rowData: any;

  /*
  * GRID BUTTONS ACTION
  */
  documentsEditMode: boolean = false;
  documentID: string = '';
  onGridRowClicked(e: any, content, documentsDelete) {
    if (e) {
      let actionType = e.event.target.getAttribute("data-action-type");
      this.documentID = e.data.id;
      this.docTitle = e.data.title;
      switch (actionType) {
        case "download":
          {
            this.quoteService.downloadDocs(this.documentID,this.docTitle);
            break;
          }
      }
    }
  }

  /* 
  * Upload Documents
  */
  docTitle: string = '';
  fileToUpload: File = null;
  openDocumentsPopup(content) {
    this.documentID = '';
    this.docTitle = '';
    this.fileToUpload = null;
    this.quote.quote_document = '';
    this.documentsEditMode = false;
    this.modalService.open(content, { centered: true });
  }

  // UPLOAd DOCUMENT
  getUploadedFile(files) {
    this.fileToUpload = files.item(0);
    this.quote.quote_document = this.fileToUpload.name;
  }


  documentUpload() {
    let formData = new FormData();
    formData.append("file_name", this.fileToUpload);
    formData.append("quote_id", this.quote.id);
    formData.append("broker_id", this.quote.broker_id);
    formData.append("title", this.docTitle);

    this.policyService.savePolicyDocument(formData)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.gridOptions.rowData.push({
              'id': response.result.id,
              'title': response.result.title,
              'quote_version': this.quote.sequence_number,
              'created_date': response.result.created_at,
              'is_static': 0,
              'download_path': response.result.file_path + '/' + response.result.file_name,
            });
            this.gridOptions.api.setRowData(this.gridOptions.rowData);


            this.gridOptions.api.refreshCells();
            this.modalService.dismissAll();
            this._snackBar.open('Document uploaded.', '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open('Unable to upload document.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
  * UPDATE DOCUMENT
  */
  updateDocument() {
    let data: any = '';
    data = {
      document_id: this.documentID,
      title: this.docTitle
    };
    this.quoteService.updateDocument(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this.rowData.forEach((res) => {
              if (this.documentID == res.id && res.is_static == 0) {
                res.title = this.docTitle
              }
            });
            this.gridOptions.api.setRowData(this.rowData);
            this.gridOptions.api.refreshCells();
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open('Unable to update title. Please try again later.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
  * Delete Document
  */
  deleteDocument() {
    let data: any = '';
    data = {
      document_id: this.documentID
    };
    this.quoteService.deleteDocument(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            let rowsDoc = [];
            this.rowData.forEach((res) => {
              if (this.documentID != res.id) {
                rowsDoc.push(res);
              }
            });
            const selectedRow = this.gridOptions.api.getFocusedCell()
            const id = this.gridOptions.rowData[selectedRow.rowIndex].i

            this.gridOptions.rowData.splice(selectedRow.rowIndex, 1)
            this.gridOptions.api.setRowData(this.gridOptions.rowData)

            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
  * Regenerate Schedule
  */
  schMsg: string = '';
  regenerate_schedule(reSchedule) {
    this.policyService.regenerateSchedule(this.quote.id,1)
      .subscribe(
        (response: any) => {
          this.schMsg = response.message;
          this.modalService.open(reSchedule, { centered: true });
        });
  }

}
