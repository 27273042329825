<div class="card-header bg-success">
    <div class="row">
        <div class="col-md-6">
            <h3 class="text-white">Policy: {{quoteData.policy_ref_number}}</h3>
        </div>
        <div class="col-md-6"> 
            <div class="align-items-center justify-content-between float-right"
                *ngIf="quoteData.insurance_status_id != '7a877243-0919-4f92-8c35-6f5732013ca6' && quoteData.insurance_status_id != '0c836e51-f8b0-4df8-8736-3ce56a40869f'">
                <ul class="navbar-nav navbar-nav-right mr-3" *ngIf="quoteData.latest_sequence_number == quoteData.sequence_number">
                    <li class="nav-item nav-profile dropdown">
                        <a class="nav-link dropdown-toggle p-0 text-white" href="#" data-toggle="dropdown"
                            id="profileDropdown">
                            Actions
                        </a>
                        <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                            aria-labelledby="profileDropdown">
                            <a (click)="policyActions(content,3)" class="dropdown-item">
                                <i class="mdi mdi-account-convert text-primary"></i>
                                Convert to MTA
                            </a>
                            <a (click)="policyActions(content,4)" class="dropdown-item">
                                <i class="mdi mdi-delete-variant text-primary"></i>
                                Cancel
                            </a>
                            <a (click)="policyActions(content,5)" class="dropdown-item" *ngIf="quoteData.type == 2">
                                <i class="mdi mdi-delete text-primary"></i>
                                Lapse
                            </a>
                            <a (click)="policyActions(content,6)" class="dropdown-item"
                                *ngIf="quoteData.type == 1 && is_NTU">
                                <i class="mdi mdi-arrange-bring-forward"></i>
                                NTU
                            </a>

                        </div>
                    </li>
                </ul>
                <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                    data-toggle="horizontal-menu-toggle">
                    <span class="mdi mdi-menu"></span>
                </button>
            </div> 
        </div>
    </div>
</div>
<mat-tab-group class="float-left w-100">
    <mat-tab>
        <ng-template mat-tab-label>
            Overview
        </ng-template>
        <div class="card-body">
            <app-al-policy-overview [quoteData]="quoteData" (quoteDetailsUpdated)="quoteDetailsUpdated($event)">
            </app-al-policy-overview>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Full Details
        </ng-template>
        <div class="card-body">
            <app-al-policy-full-details [quoteData]="quoteData"></app-al-policy-full-details>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Statement of Fact
        </ng-template>
        <div class="card-body">
            <app-al-policy-sof [quoteData]="quoteData"></app-al-policy-sof>
        </div>
    </mat-tab>
    <mat-tab *ngIf="isAdmin">
        <ng-template mat-tab-label>
            Financials
        </ng-template>
        <div class="card-body">
            <app-al-policy-financials [quoteData]="quoteData"></app-al-policy-financials>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Documents
        </ng-template>
        <div class="card-body">
            <app-al-policy-documents [quoteData]="quoteData"></app-al-policy-documents>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Claims
        </ng-template>
        <div class="card-body">
            <app-al-policy-claims [quote]="quoteData"></app-al-policy-claims>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Notes <span *ngIf="hasImportantNotes" class="ml-1" style="color: #ff0000"
                title="{{totalImpNotes}} Important Notes"><i class="mdi mdi-alert-circle"></i></span>
        </ng-template>
        <div class="card-body">
            <app-al-policy-notes [quoteData]="quoteData" (hasImportantNotes)="onhasImportantNotesChange($event)">
            </app-al-policy-notes>
        </div>
    </mat-tab>
    <mat-tab *ngIf="isAdmin">
        <ng-template mat-tab-label>
            PEP & Sanctions
        </ng-template>
        <div class="card-body">
            <app-al-policy-compliance [quote]="quoteData"></app-al-policy-compliance>
        </div>
    </mat-tab>
</mat-tab-group>
<ng-template #content let-modal>
    <div class="modal-body">
        <p *ngIf="mtaMsg">{{mtaMsg}}</p>
        <p *ngIf="!mtaMsg">
            We assume you have all the quote data. Are you sure you want to convert <b>{{ quoteData.quote_ref_number }}
            </b> into policy?
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="policyActions(content,3,1)">Yes</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">No</button>
    </div>
</ng-template>