
<div class="row">
    <div class="col-md-7">
        <div class="row mb-3">
            <div class="col-md-6"><label>Latest Version:</label></div>
            <div class="col-md-6"> 
                <input type="text" name="quote_version" [value]="quote.quote_versions[0].sequence_number"
                     class="form-control form-control-sm" readonly></div>
        </div>
        <div class="row mb-3" *ngIf="quote.quote_versions[0].policy_ref_number">
            <div class="col-md-6"><label>Policy Ref No.:</label></div>
            <div class="col-md-6">
                <a class="text-white" (click)="policyDetails()"><input type="text" name="policy_ref" [value]="quote.quote_versions[0].policy_ref_number"
                    class="form-control form-control-sm" readonly style="cursor: pointer;"></a></div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6"><label>Start Date:</label></div>
            <div class="col-md-6">
                <input type="text" name="policy_start_date" [value]="quote.quote_versions[0].policy_start_date | date:'dd-MM-yyyy'"
                    placeholder="Policy Start Date" class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6"><label>End Date:</label></div>
            <div class="col-md-6">
                <input type="text" name="policy_end_date" [value]="quote.quote_versions[0].policy_end_date | date:'dd-MM-yyyy'" class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6"><label>Transaction Type:</label></div>
            <div class="col-md-6">
                <mat-form-field class="w-100">  
                    <mat-select [(ngModel)]="quote.quote_versions[0].type"  disabled
                        class="form-control form-control-sm" name="quote_type" >
                        <mat-option [value]="1">New Business</mat-option>
                        <mat-option [value]="2">Renewal</mat-option>
                        <mat-option [value]="3">MTA</mat-option>
                        <mat-option [value]="4">Cancelled</mat-option>
                        <mat-option [value]="5">Lapsed</mat-option>
                        <mat-option [value]="6">NTU</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6"><label>Status:</label></div>
            <div class="col-md-6">
                <mat-form-field class="w-100">
                    <mat-select class="form-control form-control-sm" name="status" disabled 
                        [(ngModel)]="quote.quote_versions[0].insurance_status_id">
                        <mat-option [value]="status.id" *ngFor="let status of policyStatuses">
                            {{status.display_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-2" *ngIf="quote.quote_versions[0].mta_reason_id">
            <div class="col-md-6"><label>Policy Reason:</label></div>
            <div class="col-md-6">
                <div *ngIf="quote.quote_versions[0].mta_reason_id != '1'">
                    <input type="text" name="policy_reason" [(ngModel)]="policyReason" readonly
                        class="form-control form-control-sm">
                </div>
                <div *ngIf="quote.quote_versions[0].mta_reason_id == '1'">
                    <input type="text" name="policy_reason" [(ngModel)]="quote.quote_versions[0].mta_reason_other" readonly
                        class="form-control form-control-sm">
                </div>
            </div>
        </div>
        <div class="row mb-2" *ngIf="quote.quote_versions[0].tigerlab_policy_reference">
            <div class="col-md-6"><label>Legacy Policy Reference:</label></div>
            <div class="col-md-6">
                <input type="text" name="tigerlab_policy_reference" placeholder="Legacy Policy Reference"
                            class="form-control form-control-sm"
                            [(ngModel)]="quote.quote_versions[0].tigerlab_policy_reference" readonly>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6"><label>Insurer:</label></div>
            <div class="col-md-6">
                <mat-form-field class="w-100">  
                    <mat-select [(ngModel)]="quote.quote_versions[0].insurer" disabled
                        class="form-control form-control-sm" name="insurer" >
                        <mat-option *ngFor="let insurer of insurers" [value]="insurer.id">
                         {{insurer.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6"><label>Quote:</label></div>
            <div class="col-md-6">
                <input type="text" name="quote_type" placeholder="Full/Quote"
                        class="form-control form-control-sm"
                            [value]="(quote.quote_versions[0].is_aeio == 0)?'Full Details':'AEIO'" readonly>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-md-6"><label>Underwriter:</label></div>
            <div class="col-md-6">
                <mat-form-field class="w-100">
                    <mat-select [(ngModel)]="selectedUnderwriterID" disabled
                        class="form-control form-control-sm" name="underwriter" >
                        <mat-option *ngFor="let uw of underwriters" [value]="uw.id">
                         {{uw.userinfo.first_name}} {{uw.userinfo.last_name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div> -->
    </div>
</div>
<ag-grid-angular
    style="width: 100%; height: 300px;"
    class="ag-theme-bootstrap mt-3"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    animateRows
    pagination
    paginationPageSize = 10
    rowHeight = 27
    headerHeight=70
    (rowClicked)='onGridRowClicked($event)' 
    >