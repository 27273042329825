import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Quote } from '../../model/quote/quote.model';
import { LocalStorageService } from 'ngx-webstorage';
import { formatDate, TitleCasePipe } from '@angular/common';
import { GridOptions } from "ag-grid-community";
import { AlQuoteService } from '../../al-quote/al-quote.service';
import { QuoteService } from '../../../quote.service';
import { ActivatedRoute, Router } from "@angular/router";
import { PolicyService } from '../../../../policy/policy.service';
import { UserInfoService } from '../../../../services/user-info.service';

@Component({
  selector: 'app-admin-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class AlOverviewComponent implements OnInit {
  @Output() quoteDetailsUpdated = new EventEmitter<Quote>();
  quote_end_date: string = '';
  quote;
  quote_type;
  @Input() set quoteData(value: any) {
    if (value) {
      this.quote = value;
      // GET QUOTE VERSIONS
      if (value.quote_meta_id) {
        this.rowData = value.versions;
        this.rowData.forEach((res) => {
          res.first_name = this.titlecasePipe.transform(res.first_name);
          res.last_name = this.titlecasePipe.transform(res.last_name);
        })
      }
    }
  }


  constructor(private sessionSt: LocalStorageService, private quoteService: AlQuoteService, private adminQuoteService: QuoteService, private userService: UserInfoService,
    private route: ActivatedRoute, private policyService: PolicyService, private titlecasePipe: TitleCasePipe, private router: Router) { }

  // List Quote Versions
  private gridColumnApi;
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: 'Inception Date', field: 'policy_start_date', sortable: true, filter: false, width: 230,
      cellRenderer: function (params) {
        return formatDate(params.data.policy_start_date, 'dd-MM-yyyy', 'en-US');
      }
    },
    {
      headerName: 'Quote Number', field: 'quote_ref_number', sortable: true, width: 250,
      cellRenderer: function (params) {
        let links = '<a title="View Details" data-action-type="view" id="' + params.data.quote_id + '">' + params.data.quote_ref_number + '</a>';
        return links;
      }
    },
    { headerName: 'Ver. No.', field: 'version', sortable: true, width: 100 },
    {
      headerName: 'Customer Name', field: 'first_name', sortable: true, width: 280,
      cellRenderer: function (params) {
        return params.data.first_name + ' ' + params.data.last_name;
      },
      tooltip: function (params) {
        return params.data.first_name + ' ' + params.data.last_name;
      },
    },
    {
      headerName: 'Broker Company', field: 'broker_name', sortable: true, width: 300,
      hide: (this.sessionSt.retrieve('company_role') == 'Broker') ? true : false,
      tooltip: function (params) {
        return params.data.broker_name;
      },
    },
    {
      headerName: 'Agent', field: 'agent_first_name', sortable: true, width: 280,
      cellRenderer: function (params) {
        return params.data.agent_first_name + ' ' + params.data.agent_last_name;
      },
      tooltip: function (params) {
        return params.data.agent_first_name + ' ' + params.data.agent_last_name;
      },
    },
    { headerName: 'Type', field: 'type_text', sortable: true },
    {
      headerName: 'Status', field: 'quote_status', sortable: true, width: 280,
      tooltip: function (params) {
        return params.data.quote_status;
      },
    },
    {
      headerName: 'Gross Premium', field: 'policy_premium', sortable: true,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.policy_premium != null) {
          amount = params.data.policy_premium;
        }
        return '&pound;' + amount;
      },
      tooltip: function (params) {
        return params.data.policy_premium;
      },
    },
    {
      headerName: 'IPT', field: 'ipt_fee', sortable: true,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.ipt_fee != null) {
          amount = params.data.ipt_fee;
        }
        return amount + '%';
      },
      tooltip: function (params) {
        return params.data.ipt_fee + '%';
      },
    },
    {
      headerName: 'Fees', field: 'fees', sortable: true,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.fees != null) {
          amount = params.data.fees;
        }
        return '&pound;' + amount;
      },
      tooltip: function (params) {
        return params.data.fees;
      },
    },
    {
      headerName: 'Total Premium', field: 'total_policy_cost', sortable: true,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.total_policy_cost != null) {
          amount = params.data.total_policy_cost;
        }
        return '&pound;' + amount;
      },
      tooltip: function (params) {
        return params.data.total_policy_cost;
      },
    },

    // {
    //   headerName: 'Claims', sortable: true, filter: true,
    //   cellRenderer: function (params) {
    //     return '';
    //   }
    // }
  ];
  rowData: any;


  minPolicyDate;
  maxPolicyDate;
  underwriters;
  insurers;
  selectedUnderwriterID = this.sessionSt.retrieve('userInfo').id;
  quoteTypes;
  policyReason: string = '';
  policyStatuses;

  ngOnInit(): void {
    
    // API - GET INSURERS
    this.adminQuoteService.getInsurers()
      .subscribe(
        (response: any) => {
          this.insurers = response.result;
        });

    // Get Policy Statuses
    this.policyService.getPolicyStatuses()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.policyStatuses = response.result.quote_insurance_status;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  ngOnChanges(changes: SimpleChanges) {

  }
  /*
  * GRID BUTTONS ACTION
  */
  onGridRowClicked(e: any) {
    if (e) {
      let quote_id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "view":
          {
            this.getPolicyData(quote_id);
            break;
          }
      }
    }
  }

  // GET POLICY DETAILS
  getPolicyData(quote_id) {
    this.adminQuoteService.getQuoteDetailsByQuoteID(quote_id)
      .subscribe(
        (response: any) => {
          this.quote = response.result;
          this.quote.financial_details.broker_commission = Number(this.quote.financial_details.broker_commission);
          this.quote.financial_details.peliwica_pay_away_commission = Number(this.quote.financial_details.peliwica_pay_away_commission)
          this.quote.financial_details.peliwica_retained_commission = Number(this.quote.financial_details.peliwica_retained_commission);
          this.quote.financial_details.policy_premium = Number(this.quote.financial_details.policy_premium);
        });
  }

   /*
  * View Policy Details
  */
 policyDetails() {
  this.router.navigate(['/alpaca-admin/policy/',this.quote.id]);
 }

}
