<div class="card-header bg-success">
    <h3>Quote Details</h3>
</div>
<div class="card-body">
    <form #f="ngForm">
        <div class="row">
            <div class="col-md-6">
                <h5><u>Quote Search</u></h5>
            </div>
            <div class="col-md-6 text-right"><a (click)="clearFilters(f)" class="btn btn-success btn-sm">Clear Filters</a>
            </div>
        </div>


        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-md-3">Insured name</label>
                    <div class="col-md-9">
                        <div class="input-group input-group-sm mb-3">
                            <div class="input-group-prepend bg-transparent">
                                <span class="input-group-text bg-white" id="inputGroup-sizing-sm">
                                    <i class="mdi mdi-magnify text-primary"></i>
                                </span>
                            </div>
                            <input type="text" name="policy_holder" class="form-control form-control-lg"
                                [(ngModel)]="quote.policy_holder" (blur)="searchRecords()">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-md-3">Broker Company</label>
                    <div class="col-md-9">
                        <mat-form-field class="w-100">
                            <mat-label>Broker Company</mat-label>
                            <mat-select class="form-control form-control-sm" name="broker_id"
                                [(ngModel)]="quote.company_id" (selectionChange)="searchRecords()">
                                <mat-option [value]="">Select Broker</mat-option>
                                <mat-option *ngFor="let brk of brokers" [value]="brk.id">
                                    {{brk.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-md-3">Reference</label>
                    <div class="col-md-9">
                        <div class="input-group input-group-sm mb-3">
                            <div class="input-group-prepend bg-transparent">
                                <span class="input-group-text bg-white" id="inputGroup-sizing-sm">
                                    <i class="mdi mdi-magnify text-primary"></i>
                                </span>
                            </div>
                            <input type="text" name="quote_ref_number" class="form-control form-control-lg"
                                [(ngModel)]="quote.quote_ref_number" (blur)="searchRecords()">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-md-3">Transaction Type</label>
                    <div class="col-md-9">
                        <mat-form-field class="w-100">
                            <mat-label>Transaction Type</mat-label>
                            <mat-select class="form-control form-control-sm" name="type" [(ngModel)]="quote.type"
                                (selectionChange)="searchRecords()">
                                <mat-option [value]="">Select Transaction Type</mat-option>
                                <mat-option [value]="type.key" *ngFor="let type of quoteTypes | keyvalue">{{type.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-md-3">Date From</label>
                    <div class="col-md-9">
                        <div class="form-group">
                            <input type="text" name="date_from" class="form-control form-control-sm datepicker" matInput
                                (focus)="myDatepicker.open()" [matDatepicker]="myDatepicker" [(ngModel)]="quote.from"
                                (blur)="searchRecords()" (dateChange)="searchRecords()">
                            <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #myDatepicker></mat-datepicker>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-md-3">Date To</label>
                    <div class="col-md-9">
                        <div class="form-group">
                            <input type="text" name="date_to" class="form-control form-control-sm datepicker" matInput
                                (focus)="dateToDatepicker.open()" [matDatepicker]="dateToDatepicker"
                                [(ngModel)]="quote.to" (blur)="searchRecords()" (dateChange)="searchRecords()">
                            <mat-datepicker-toggle [for]="dateToDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #dateToDatepicker></mat-datepicker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="row">
        <div class="col-md-2 pt-4">
            <h5>Quote MI</h5>
        </div>
        <div class="col-md-9">
            <div class="row text-green text-center">
                <div class="col-md-3">
                    <div class="bg-white p-3">Quotes<br><b>{{quote.total_records}}</b></div>
                </div>
                <div class="col-md-3">
                    <div class="bg-white p-3">Gross Premium<br><b>&pound; {{quote.total_net_rate}}</b></div>
                </div>
                <div class="col-md-3">
                    <div class="bg-white p-3">Total Premium<br><b>&pound; {{quote.total_gross_premium}}</b></div>
                </div>
                <div class="col-md-3">
                    <div class="bg-white p-3">Fees<br><b>&pound; {{quote.total_fees}}</b></div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <h5><u>Quote Details</u></h5>
        <ag-grid-angular style="width: 100%; height: 550px;" class="ag-theme-bootstrap" [gridOptions]="gridOptions"
            [rowData]="rowData" [columnDefs]="columnDefs" animateRows pagination paginationPageSize=15 rowHeight=27
            headerHeight=70 (rowClicked)='onGridRowClicked($event,content)'>
        </ag-grid-angular>
    </div>
</div>
<ng-template #content let-modal>
    <div class="modal-body">
        {{msg}}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-sm" (click)="deleteQuote(quote_id,rowIndex)">Delete</button>
        <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close()">Close</button>
    </div>
</ng-template>