<div class="card-header bg-success">
    <div class="row">
        <div class="col-md-6">
            <h3 class="text-white">Quote: {{quoteData.quote_versions[0].quote_ref_number}}
                <small [hidden]="quoteData.quote_versions[0].is_policy == 1"><a [routerLink]="['/quote-new',quoteData.id]" class="btn btn-success btn-sm">Edit</a></small>
            </h3>
        </div> 
       <div class="col-md-6" [hidden]="quoteData.quote_versions[0].is_policy == 1">
            <a (click)="updateQuoteStatus('80c0db90-ba5d-4387-b09a-4c008a664932')" class="btn btn-success btn-sm float-right" *ngIf="quoteData.quote_versions[0].insurance_status_id == 'f6d14624-6351-4d93-b27b-70fb8a06ec5d' && isAdmin">Convert to Quote</a>
            <a (click)="convertToPolicy(converttopolicymodal)" class="btn btn-success btn-sm float-right" *ngIf="quoteData.quote_versions[0].insurance_status_id == '80c0db90-ba5d-4387-b09a-4c008a664932'">Convert to Policy</a>
        </div>   
    </div>
</div>
<mat-tab-group class="float-left w-100">
    <mat-tab>
        <ng-template mat-tab-label>
            Overview
        </ng-template>
        <div class="card-body">
            <app-overview [quoteData]="quoteData" (quoteDetailsUpdated)="quoteDetailsUpdated($event)"></app-overview>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Full Details
        </ng-template>
        <div class="card-body">
            <app-quote-full-details [quote]="quoteData" [staticData]="staticData"></app-quote-full-details>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Statement of Fact
        </ng-template>
        <div class="card-body">
            <app-quote-sof [quote]="quoteData"></app-quote-sof>
        </div>
    </mat-tab>
    <mat-tab *ngIf="isAdmin">
        <ng-template mat-tab-label>
            Financials
        </ng-template>
        <div class="card-body">
            <app-financials [quote]="quoteData" [staticData]="staticData"></app-financials>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Documents
        </ng-template>
        <div class="card-body">
            <app-documents [quote]="quoteData"></app-documents>
        </div>
    </mat-tab>
    <!-- <mat-tab *ngIf="isAdmin">
        <ng-template mat-tab-label>
            Claims
        </ng-template>
        <div class="card-body">
            <app-claims [quote]="quoteData"></app-claims>
        </div>
    </mat-tab> -->
    <mat-tab>
        <ng-template mat-tab-label>
            Notes <span *ngIf="hasImportantNotes" class="ml-1" style="color: #ff0000" title="{{totalImpNotes}} Important Notes"><i class="mdi mdi-alert-circle"></i></span>
        </ng-template>
        <div class="card-body">
            <app-notes [quote]="quoteData" (hasImportantNotes)="onhasImportantNotesChange($event)"></app-notes>
        </div>
    </mat-tab>
</mat-tab-group>

<ng-template #converttopolicymodal let-modal>
    <div class="modal-body">
        <p>We assume you have all the quote data. Are you sure you want to convert <b>{{ quoteData.quote_versions[0].quote_ref_number }} </b> into policy?</p>
        <div class="form-group">
            <label>Policy Start Date</label>
            <div class="w-100">
                <input type="text" readonly required name="policy_start_date" [(ngModel)]="quoteData.quote_versions[0].policy_start_date"
                    #policy_start_date="ngModel" placeholder="Policy Start Date" style="height: 31px;"
                    class="form-control form-control-sm datepicker" matInput [min]="minPolicyDate" [max]="maxPolicyDate"
                    [matDatepicker]="myDatepicker">
                <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="convertToPolicyPost()">Convert to Policy</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
    </div>
</ng-template>
