<div class="card-header bg-success">
    <h3>Declaration Statement</h3>
</div>
<div class="card-body">
    <div class="form-group bg-white p-3 text-green" *ngFor="let ques of agreedQuestions;">
  {{ques.text}}
    </div>
    <div class="form-group">
        <button (click)="agreedBtnAction()" class="btn btn-success btn-sm">Agree</button>
    </div>
</div>