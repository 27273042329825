import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MiComponent } from './dashboard/mi/mi.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CompanyComponent } from './company/company.component';
import { LayoutLoginComponent } from './_layouts/layout-login/layout-login.component';
import { LayoutInnerpagesComponent } from './_layouts/layout-innerpages/layout-innerpages.component';
import { CompanyUpsertComponent } from './company/company-upsert.component';
import { UserComponent } from './user/user.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { QuoteComponent } from './quote/quote.component';
import { QuoteListComponent } from './quote/quote-list/quote-list.component'; 
import { QuoteNewComponent } from './quote/quote-new.component'; 
import { QuoteDetailsComponent } from './quote/quote-details/quote-details/quote-details.component'; 
import { PolicyComponent } from './policy/policy.component'; 
import { PolicyListComponent } from './policy/policy-list/policy-list.component'; 
import { BordereauComponent } from './bordereau/bordereau.component'; 
import {LoginGuard} from './auth/auth.guard';
import {AdminPreQualifierComponent} from './quote/alpaca/al-quote/pre-qualifier/pre-qualifier.component';
import {AdminAlQuoteComponent} from './quote/alpaca/al-quote/al-quote.component';

import { AlQuoteDetailsComponent } from './quote/alpaca/quote-details/quote-details/quote-details.component';

import { AlPolicyDetailsComponent } from './policy/alpaca/policy-details/policy-details.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';


const appRoutes: Routes = [  
  {
    path: '', component: LayoutLoginComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent }
    ]
  },
  { path: 'logout', component: LogoutComponent },
  {
    path: '', component: LayoutInnerpagesComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent,canActivate: [LoginGuard]  },
      { path: 'dashboard-mi/:frequency', component: MiComponent,canActivate: [LoginGuard]  },
      { path: 'company', component: CompanyComponent, canActivate: [LoginGuard] },
      { path: 'company-manage', component: CompanyUpsertComponent, canActivate: [LoginGuard]},
      { path: 'company-manage/:id', component: CompanyUpsertComponent , canActivate: [LoginGuard]},
      { path: 'user/:company_id/:id', component: UserComponent , canActivate: [LoginGuard]},
      { path: 'user-list/:company_id', component: UserListComponent, canActivate: [LoginGuard] },
      { path: 'user/:company_id', component: UserComponent, canActivate: [LoginGuard] },
      { path: 'quote-new', component: QuoteNewComponent, canActivate: [LoginGuard] },
      { path: 'quote-new/:id', component: QuoteNewComponent, canActivate: [LoginGuard] },
      { path: 'quote-new/:id/:quote_type', component: QuoteNewComponent, canActivate: [LoginGuard] },
      { path: 'quote', component: QuoteComponent, canActivate: [LoginGuard] },
      { path: 'quote/:id', component: QuoteComponent, canActivate: [LoginGuard] },
      { path: 'quote/:id/:quote_type', component: QuoteComponent, canActivate: [LoginGuard] },
      { path: 'quote-list', component: QuoteListComponent, canActivate: [LoginGuard] },
      { path: 'quote-details/:quote_meta_id', component: QuoteDetailsComponent, canActivate: [LoginGuard] },
      { path: 'policy-list', component: PolicyListComponent, canActivate: [LoginGuard] },
      { path: 'policy/:quote_meta_id', component: PolicyComponent, canActivate: [LoginGuard] },
      { path: 'bordereau', component: BordereauComponent, canActivate: [LoginGuard] }
    ]
  },
  {
    path: 'alpaca-admin', component: LayoutInnerpagesComponent,
    children: [
      { path: 'pre-qualifier', component: AdminPreQualifierComponent },
      { path: 'quote', component: AdminAlQuoteComponent },
      { path: 'pre-qualifier/:quote_id', component: AdminPreQualifierComponent },
      { path: 'pre-qualifier/:quote_id/:quote_type', component: AdminPreQualifierComponent },
      { path: 'quote/:quote_id', component: AdminAlQuoteComponent },
      { path: 'quote/:quote_id/:quote_type', component: AdminAlQuoteComponent },
      { path: 'quote-details/:quote_id', component: AlQuoteDetailsComponent },
      { path: 'policy/:quote_id', component: AlPolicyDetailsComponent }
    ]
  },
  {
    path: '', component: LayoutInnerpagesComponent,
    children: [
      { path: '**', component: PageNotFoundComponent }
    ]
  },
  
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
