<h4>Indicative Quote</h4>
<form #f="ngForm" (ngSubmit)="onSubmit(f)">
    <div class="row">
        <div class="col-md-8">
            <div class="form_sections">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group w-100">
                            <label class="text-white f13">Policy Start Date</label>
                            <div class="w-100">
                                <input type="text" required name="policy_start_date" placeholder="Policy Start Date"
                                    class="form-control form-control-sm datepicker"
                                    [(ngModel)]="quote.quote_versions[0].policy_start_date" #policy_start_date="ngModel"
                                    [ngClass]="{ 'is-invalid': f.submitted && policy_start_date.invalid }" matInput
                                    (focus)="myDatepicker.open()" [matDatepicker]="myDatepicker" [min]="minPolicyDate"
                                    [max]="maxPolicyDate">
                                <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #myDatepicker></mat-datepicker>
                                <div *ngIf="f.submitted && policy_start_date.invalid" class="invalid-feedback">
                                    Policy date is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="!isBroker">
                        <mat-form-field class="w-100" style="margin-top: -10px;">
                            <span class="text-white f13">Broker</span>
                            <mat-select [(ngModel)]="quote.company_id" class="form-control form-control-sm"
                                name="broker_id" [ngClass]="{ 'is-invalid': f.submitted && broker.invalid }"
                                #broker="ngModel" required [disabled]="disableIfCancelAndNTU" (selectionChange)="onChangeBroker($event)">
                                <mat-option *ngFor="let brk of brokers" [value]="brk.id">
                                    {{brk.name}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="f.submitted && broker.invalid" class="invalid-feedback">
                                Broker field is required.
                            </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="text-white f13">Transaction Type</label>
                        <mat-form-field class="w-100" style="margin-top: -10px;">
                            <mat-select [(ngModel)]="quote_type" class="form-control form-control-sm" name="quote_type"
                                [disabled]="quote_type >= 3" (selectionChange)="onQuoteTypeChange($event)">
                                <mat-option [value]="1" [hidden]="quote_type >= 3">New Business</mat-option>
                                <mat-option [value]="2" [hidden]="quote_type >= 3">Renewal</mat-option>
                                <mat-option [value]="3" [hidden]="quote_type != 3">MTA</mat-option>
                                <mat-option [value]="4" [hidden]="quote_type != 4">Cancelled</mat-option>
                                <mat-option [value]="5" [hidden]="quote_type != 5">Lapsed</mat-option>
                                <mat-option [value]="6" [hidden]="quote_type != 6">NTU</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6"
                        *ngIf="quote_type == 2 && quote.quote_versions[0].alpaca_public_liability_cover == 1">
                        <div class="form-group">
                            <label class="text-white f13">Retroactive date</label>
                            <div class="w-100">
                                <input type="text" required name="retroactive_date" placeholder="Retroactive date"
                                    class="form-control form-control-sm datepicker"
                                    [(ngModel)]="quote.quote_versions[0].retroactive_date" #retroactive_date="ngModel"
                                    [ngClass]="{ 'is-invalid': f.submitted && retroactive_date.invalid }" matInput
                                    (focus)="retroactiveDatepicker.open()" [matDatepicker]="retroactiveDatepicker">
                                <mat-datepicker-toggle [for]="retroactiveDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #retroactiveDatepicker></mat-datepicker>
                                <div *ngIf="f.submitted && retroactive_date.invalid" class="invalid-feedback">
                                    Retroactive date is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="quote_type == 3 || quote_type == 4">
                        <label class="text-white f13">Policy Reason</label>
                        <mat-form-field class="w-100" style="margin-top: -10px;">
                            <mat-select [(ngModel)]="quote.quote_versions[0].mta_reason_id"
                                class="form-control form-control-sm" name="qd_mta_reason_id"
                                [ngClass]="{ 'is-invalid': f.submitted && mta_reason_id.invalid }"
                                #mta_reason_id="ngModel" required>
                                <mat-option *ngFor="let rsn of policyReasons" [value]="rsn.id">
                                    {{rsn.reasons}}
                                </mat-option>
                                <mat-option [value]="1">Other</mat-option>
                            </mat-select>
                            <div *ngIf="f.submitted && mta_reason_id.invalid" class="invalid-feedback">
                                Please select a specific reason.
                            </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"
                        *ngIf="quote_type == 2 && quote.quote_versions[0].alpaca_public_liability_cover == 1">
                        <div class="form-group">
                            <label class="text-white f13">Previous Year Premium</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="number" required name="previous_policy_amount"
                                    placeholder="Previous Year Premium" class="form-control form-control-sm" appNumbers
                                    [(ngModel)]="quote.quote_versions[0].previous_policy_amount"
                                    #previous_policy_amount="ngModel"
                                    [ngClass]="{ 'is-invalid': f.submitted && previous_policy_amount.invalid }">
                                <div *ngIf="f.submitted && previous_policy_amount.invalid" class="invalid-feedback">
                                    Previous year premium is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_sections">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            About The Proposer
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="form-group">
                        <mat-radio-group class="row" aria-label="Select an option" name="qd_proposer_type"
                            [(ngModel)]="quote.quote_versions[0].policy_holder.proposer_type"
                            [disabled]="disableIfCancelAndNTU">
                            <div class="col-md-4">
                                <div class="quote_radio w-90 mb-3">
                                    <mat-radio-button [value]="1">Individual</mat-radio-button>
                                </div>
                                <span class="ml-1" (click)="helpTextPopup(content,'A person who is acting for purposes which are outside his trade or profession 
                                e.g. a non-profit making hobby such as animals kept as pets.')"><i
                                        class="mdi mdi-comment-question-outline"></i></span>
                            </div>
                            <div class="col-md-4">
                                <div class="quote_radio w-90 mb-3">
                                    <mat-radio-button [value]="2">Micro Enterprise</mat-radio-button>
                                </div>
                                <span class="ml-1"
                                    (click)="helpTextPopup(content,'Smaller businesses that have a turnover or annual balance sheet of not more than 
                                £2m (two million GBP)  and fewer than ten employees. e.g. smallholdings that include animals or small livestock herds.')"><i
                                        class="mdi mdi-comment-question-outline"></i></span>
                            </div>
                            <div class="col-md-4">
                                <div class="quote_radio w-90 mb-3">
                                    <mat-radio-button [value]="3">Commercial</mat-radio-button>
                                </div>
                                <span class="ml-1" (click)="helpTextPopup(content,'Livestock related businesses that are run for profit that have a turnover or annual balance sheet of more than £2m (two million GBP) or more than 10 employees.
                                e.g. large livestock herds / livestock herds.')"><i
                                        class="mdi mdi-comment-question-outline"></i></span>
                            </div>
                        </mat-radio-group>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" *ngIf="quote.quote_versions[0].policy_holder.proposer_type == 1">
                                <input type="text" name="first_name" placeholder="First Name"
                                    class="form-control form-control-sm" [disabled]="disableIfCancelAndNTU"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.first_name"
                                    [ngClass]="{ 'is-invalid': f.submitted && first_name.invalid }"
                                    #first_name="ngModel" required>
                                <div *ngIf="f.submitted && first_name.invalid" class="invalid-feedback">
                                    First name is required.
                                </div>
                            </div>
                            <div class="form-group" *ngIf="quote.quote_versions[0].policy_holder.proposer_type == 1">
                                <input type="text" name="last_name" placeholder="Last Name"
                                    class="form-control form-control-sm" [disabled]="disableIfCancelAndNTU"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.last_name">
                            </div>
                            <div class="form-group" *ngIf="quote.quote_versions[0].policy_holder.proposer_type != 1">
                                <input type="text" name="company_name" placeholder="Name of Company"
                                    class="form-control form-control-sm" [disabled]="disableIfCancelAndNTU"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.company_name">
                            </div>
                            <div class="form-group" *ngIf="quote.quote_versions[0].policy_holder.proposer_type != 1">
                                <input type="text" name="trading_name" placeholder="Trading Name"
                                    class="form-control form-control-sm" [disabled]="disableIfCancelAndNTU"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.trading_name">
                            </div>
                            <div class="form-group">
                                <input type="email" name="email" placeholder="Email Address"
                                    class="form-control form-control-sm" [disabled]="disableIfCancelAndNTU"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.email"
                                    pattern="^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$"
                                    [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" #email="ngModel"
                                    required>
                                <div *ngIf="f.submitted && email.errors && (email.invalid || email.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="email.invalid && !email.touched">Email is required.</div>
                                    <div *ngIf="email.errors.pattern && email.touched">Please provide a valid email
                                        address
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input required type="text" name="phone" placeholder="Contact Number 1"
                                    class="form-control form-control-sm" required #phone="ngModel"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.phone"
                                    [disabled]="disableIfCancelAndNTU">
                                <div *ngIf="f.submitted && phone.invalid" class="invalid-feedback">
                                    Mobile number is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="mobile" placeholder="Contact Number 2"
                                    class="form-control form-control-sm" [disabled]="disableIfCancelAndNTU"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.mobile">

                            </div>
                        </div>


                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="postcode_lookup" placeholder="Postcode"
                                    class="form-control form-control-sm" [disabled]="disableIfCancelAndNTU"
                                    (keyup)="postcode_lookup.errors?'':postcodeLookup($event)"
                                    pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                                    #postcode_lookup="ngModel"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.address.postcode"
                                    [ngClass]="{ 'is-invalid': f.submitted && postcode_lookup.invalid }"
                                    [matAutocomplete]="auto" appUpperCase>
                                <div *ngIf="postcode_lookup.errors && (postcode_lookup.invalid || postcode_lookup.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="postcode_lookup.invalid && !postcode_lookup.touched">Postcode is
                                        required.</div>
                                    <div
                                        *ngIf="f.submitted && postcode_lookup.errors?.pattern && postcode_lookup.touched">
                                        Invalid postcode.</div>
                                </div>
                                <div>
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <div
                                            *ngIf="!postcode_lookup.errors && quote.quote_versions[0].policy_holder.address.postcode != ''">
                                            <mat-option [value]="quote.quote_versions[0].policy_holder.address.postcode"
                                                (click)="onSelectAddress(adrs)" *ngFor="let adrs of addresses">
                                                {{adrs.formatted_address.join(', ')}}</mat-option>
                                        </div>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="policy_holder_address_line_1" placeholder="Line 1"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.address.address1"
                                    class="form-control form-control-sm" appTitleCase [disabled]="disableIfCancelAndNTU"
                                    [ngClass]="{ 'is-invalid': f.submitted && policy_holder_address_line_1.invalid }"
                                    required #policy_holder_address_line_1="ngModel">
                                <div *ngIf="f.submitted && policy_holder_address_line_1.invalid"
                                    class="invalid-feedback">
                                    Address 1 is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="policy_holder_address_line_2" placeholder="Line 2"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.address.address2"
                                    class="form-control form-control-sm" appTitleCase
                                    [disabled]="disableIfCancelAndNTU">
                            </div>

                            <div class="form-group row">
                                <div class="col-md-6">
                                    <input type="text" name="policy_holder_city" placeholder="Town"
                                        [(ngModel)]="quote.quote_versions[0].policy_holder.address.city"
                                        class="form-control form-control-sm" appTitleCase
                                        [ngClass]="{ 'is-invalid': f.submitted && policy_holder_town.invalid }" required
                                        #policy_holder_town="ngModel" [disabled]="disableIfCancelAndNTU">
                                    <div *ngIf="f.submitted && policy_holder_town.invalid" class="invalid-feedback">
                                        Town is required.
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="policy_holder_county" placeholder="County"
                                        [(ngModel)]="quote.quote_versions[0].policy_holder.address.country"
                                        class="form-control form-control-sm" appTitleCase
                                        [disabled]="disableIfCancelAndNTU"
                                        [ngClass]="{ 'is-invalid': f.submitted && policy_holder_country.invalid }"
                                        required #policy_holder_country="ngModel">
                                    <div *ngIf="f.submitted && policy_holder_country.invalid" class="invalid-feedback">
                                        County is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
            <div class="form_sections" *ngIf="quote.quote_versions[0].alpaca_theft_cover == 1">
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            About the Alpacas / Llamas
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="form-group">
                        <label class="f13 text-white">
                            How old are the animals and what's the total value of them?
                            <span
                                (click)="helpTextPopup(content,'You do not need to show the values for crias aged between 7 – 90 days old')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                    </div>
                    <div class="form-group">
                        <label class="text-white f13">Between 7 and 90 days old
                            <span (click)="helpTextPopup(content,'<div>
                                <div>
                                    <div>
                                        <span> The following sums insured apply to crias fewer than 91 days old</span><br/><br/>
                                        <span> From 7 days old to 30 days old - </span>  <span>&pound;500.00</span><br/>		
                                        <span> From 31 days old to 60 days old - </span><span>&pound;750.00</span><br/>
                                        <span> From 61 days old to 89 days old - </span> <span>&pound;1,000.00 </span><br/>
                                        <span> From 90 days old to expiry of policy - </span><span>&pound;1,500.00</span><br/><br/>
                                        <span> The sum insured automatically increases as set out above, warranted the cria is in sound health at each increase date.</span><br/>
                                    </div>
                      
                                </div>
                            </div>')"><i class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-5">
                                <input type="number" appNumbers name="seven_to_ninety_days" placeholder="How many cria?"
                                    class="form-control form-control-sm" [disabled]="disableIfCancelAndNTU"
                                    [(ngModel)]="quote.quote_versions[0].about_alpaca.alpaca_7dto90d" [max]="30"
                                    [ngClass]="{ 'is-invalid': (f.submitted && seven_to_ninety_days.invalid) || quote.quote_versions[0].about_alpaca.alpaca_7dto90d > 30 }"
                                    required #seven_to_ninety_days="ngModel" (change)="onAlpacaRatesChange($event, 1)">
                                <div *ngIf="f.submitted && seven_to_ninety_days.invalid" class="invalid-feedback">
                                    Value is required.
                                </div>
                                <div *ngIf="quote.quote_versions[0].about_alpaca.alpaca_7dto90d > 30"
                                    class="invalid-feedback">
                                    Please contact the system administrator if the number of cria are more than 30.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-white f13">Between 91 days & 12 years old
                            <span
                                (click)="helpTextPopup(content,'State the number of alpacas for insurance between 91 days and 12 years old and state the total value of these alpacas')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="number" appNumbers name="ninetyone_to_twelve_days"
                                        placeholder="How many alpacas?" class="form-control form-control-sm"
                                        [(ngModel)]="quote.quote_versions[0].about_alpaca.alpaca_91dto12y"
                                        [ngClass]="{ 'is-invalid': (f.submitted && ninetyone_to_twelve_days.invalid) || quote.quote_versions[0].about_alpaca.alpaca_91dto12y > 30 }"
                                        required #ninetyone_to_twelve_days="ngModel"
                                        (change)="onAlpacaRatesChange($event, 2)" [disabled]="disableIfCancelAndNTU">
                                    <div *ngIf="f.submitted && ninetyone_to_twelve_days.invalid"
                                        class="invalid-feedback">
                                        Value is required.
                                    </div>
                                    <div *ngIf="quote.quote_versions[0].about_alpaca.alpaca_91dto12y > 30"
                                        class="invalid-feedback">
                                        Please contact the system administrator if the number of animals are more than
                                        30.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="text-white f13">Only the Total Amount of Alapacas / Llamas Between 91 days &
                                12 years old</label>
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-success text-white">&pound;</span>
                                        </div>
                                        <input type="number" appNumbers name="sum_insured" style="font-size: 12px;"
                                            placeholder="Enter the total value of insurance cover required"
                                            class="form-control form-control-sm" step="50"
                                            [disabled]="disableIfCancelAndNTU"
                                            [(ngModel)]="quote.quote_versions[0].about_alpaca.sum_insured"
                                            (blur)="onAlpacaRatesChange($event, 2)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="quote_type == 2">
                        <label class="text-white f13">Aged 13 years old
                            <span (click)="helpTextPopup(content,'Aged 13 years old')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="number" appNumbers name="alpaca_13y" placeholder="How many alpacas?"
                                        class="form-control form-control-sm"
                                        [(ngModel)]="quote.quote_versions[0].about_alpaca.alpaca_13y"
                                        [ngClass]="{ 'is-invalid': (f.submitted && alpaca_13y.invalid) || quote.quote_versions[0].about_alpaca.alpaca_13y > 30 }"
                                        required #alpaca_13y="ngModel" (change)="onAlpacaRatesChange($event, 8)"
                                        [disabled]="disableIfCancelAndNTU">
                                    <div *ngIf="f.submitted && alpaca_13y.invalid" class="invalid-feedback">
                                        Value is required.
                                    </div>
                                    <div *ngIf="quote.quote_versions[0].about_alpaca.alpaca_13y > 30"
                                        class="invalid-feedback">
                                        Please contact the system administrator if the number of animals are more than
                                        30.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-success text-white">&pound;</span>
                                        </div>
                                        <input type="number" appNumbers name="sum_insured_13y" style="font-size: 12px;"
                                            placeholder="Enter the total value of insurance cover required"
                                            class="form-control form-control-sm" step="50"
                                            [disabled]="disableIfCancelAndNTU"
                                            [(ngModel)]="quote.quote_versions[0].about_alpaca.sum_insured_13y"
                                            (blur)="onAlpacaRatesChange($event, 8)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="quote_type == 2">
                        <label class="text-white f13">Aged 14 years old
                            <span (click)="helpTextPopup(content,'Aged 14 years old')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="number" appNumbers name="alpaca_14y" placeholder="How many alpacas?"
                                        class="form-control form-control-sm"
                                        [(ngModel)]="quote.quote_versions[0].about_alpaca.alpaca_14y"
                                        [ngClass]="{ 'is-invalid': (f.submitted && alpaca_14y.invalid) || quote.quote_versions[0].about_alpaca.alpaca_14y > 30  }"
                                        required #alpaca_14y="ngModel" (change)="onAlpacaRatesChange($event, 9)"
                                        [disabled]="disableIfCancelAndNTU">
                                    <div *ngIf="f.submitted && alpaca_14y.invalid" class="invalid-feedback">
                                        Value is required.
                                    </div>
                                    <div *ngIf="quote.quote_versions[0].about_alpaca.alpaca_14y > 30"
                                        class="invalid-feedback">
                                        Please contact the system administrator if number of the animals are more than
                                        30.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-success text-white">&pound;</span>
                                        </div>
                                        <input type="number" appNumbers name="sum_insured_14y" style="font-size: 12px;"
                                            placeholder="Enter the total value of insurance cover required"
                                            class="form-control form-control-sm" step="50"
                                            [disabled]="disableIfCancelAndNTU"
                                            [(ngModel)]="quote.quote_versions[0].about_alpaca.sum_insured_14y"
                                            (blur)="onAlpacaRatesChange($event, 9)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-white f13">Do you need Economic Slaughter/Loss of Use for STUD MALES ONLY
                            <span
                                (click)="helpTextPopup(content,'Cover for a male alpaca suffering an accident, illness or disease rendering the animal totally and permanently infertile, impotent or otherwise incapable of serving females, by either natural service or artificial insemination, caused by an accident, injury, illness or disease happening or manifesting itself during the period of insurance.')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-5">
                                <mat-radio-group class="row" required aria-label="Select an option"
                                    name="does_economic_slaughter_required" #economicSlaughter ngmodel
                                    (change)="onEconomicSlaughterChange($event)" [disabled]="disableIfCancelAndNTU"
                                    [(ngModel)]="quote.quote_versions[0].about_alpaca.does_economic_slaughter_required">
                                    <div class="col-md-6">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="1" name="does_economic_slaughter_required"
                                                ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].about_alpaca.does_economic_slaughter_required"
                                                required #economicSlaughterControl="ngModel">Yes</mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>
                                            <div class="quote_radio">
                                                <mat-radio-button [value]="2" name="does_economic_slaughter_required"
                                                    ngDefaultControl
                                                    [(ngModel)]="quote.quote_versions[0].about_alpaca.does_economic_slaughter_required"
                                                    required #economicSlaughterControl="ngModel">No</mat-radio-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="f.submitted && economicSlaughterControl.invalid"
                                        class="invalid-feedback col-md-12">
                                        Please select an option.
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="row mt-3"
                            *ngIf="quote.quote_versions[0].about_alpaca.does_economic_slaughter_required == 1">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="number" appNumbers name="economic_slaughter_required_for"
                                        placeholder="How many stud males?" class="form-control form-control-sm"
                                        [max]="quote.total_animals" [disabled]="disableIfCancelAndNTU"
                                        [(ngModel)]="quote.quote_versions[0].about_alpaca.economic_slaughter_required_for"
                                        (change)="onAlpacaRatesChange($event, 3)"
                                        (blur)="studMalesConformation(content)">
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-success text-white">&pound;</span>
                                        </div>
                                        <input type="number" appNumbers name="economic_slaughter_sum_insured"
                                            style="font-size: 12px;" step="50" [disabled]="disableIfCancelAndNTU"
                                            placeholder="Enter the total value of insurance cover required"
                                            class="form-control form-control-sm" (blur)="onAlpacaRatesChange($event, 3)"
                                            [(ngModel)]="quote.quote_versions[0].about_alpaca.economic_slaughter_sum_insured">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-white f13">Do you need life saving surgical fee cover?
                            <span (click)="helpTextPopup(content,'All reasonable and normal veterinary treatment costs for surgical procedures to save the life of the animal carried out by a Vet up to maximum benefit of £3,000 
                            Excess of £350 per surgery applies.
                            ')"><i class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-5">
                                <mat-radio-group class="row" required aria-label="Select an option"
                                    name="lifeSavingCover" #lifeSavingCover ngmodel
                                    (change)="onAlpacaRatesChange($event, 5)" [disabled]="disableIfCancelAndNTU"
                                    [(ngModel)]="quote.quote_versions[0].about_alpaca.does_life_saving_cover_required">
                                    <div class="col-md-6">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="1" name="lifeSavingCover" ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].about_alpaca.does_life_saving_cover_required"
                                                required #lifeSavingCoverControl="ngModel">Yes</mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>
                                            <div class="quote_radio">
                                                <mat-radio-button [value]="2" name="lifeSavingCover" ngDefaultControl
                                                    [(ngModel)]="quote.quote_versions[0].about_alpaca.does_life_saving_cover_required"
                                                    required #lifeSavingCoverControl="ngModel">No</mat-radio-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="f.submitted && lifeSavingCoverControl.invalid"
                                        class="invalid-feedback col-md-12">
                                        Please select an option.
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-white f13">Do you need all reasonable and normal vets treatment costs
                            covered?
                            <span (click)="helpTextPopup(content,'All reasonable and normal veterinary treatment costs carried out by a Vet up to maximum benefit of £5,000 per insured animal for each and every illness, condition or disease of, or physical injury to the insured animal.  This extension does not cover routine veterinary costs including but not limited to costs for pregnancy, vaccinations and worming.
                                Excess £500 per incident applies.')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-5">
                                <mat-radio-group class="row" required aria-label="Select an option"
                                    name="vetsTreatmentCost" #vetsTreatmentCost ngmodel
                                    (change)="onAlpacaRatesChange($event, 6)" [disabled]="disableIfCancelAndNTU"
                                    [(ngModel)]="quote.quote_versions[0].about_alpaca.does_vet_treatments_covered">
                                    <div class="col-md-6">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="1" name="vetsTreatmentCost" ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].about_alpaca.does_vet_treatments_covered"
                                                required #vetsTreatmentCostControl="ngModel">Yes</mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>
                                            <div class="quote_radio">
                                                <mat-radio-button [value]="2" name="vetsTreatmentCost" ngDefaultControl
                                                    [(ngModel)]="quote.quote_versions[0].about_alpaca.does_vet_treatments_covered"
                                                    required #vetsTreatmentCostControl="ngModel">No</mat-radio-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="f.submitted && vetsTreatmentCostControl.invalid"
                                        class="invalid-feedback col-md-12">
                                        Please select an option.
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-5">
                            <label class="text-white f13">How many claims have you made in the past 2 years for the
                                animals?</label>
                            <mat-form-field class="w-100">
                                <mat-label>Claims</mat-label>
                                <mat-select [(ngModel)]="quote.quote_versions[0].number_of_claims"
                                    class="form-control form-control-sm" name="number_of_claims"
                                    (selectionChange)="onChangeClaim($event)" [disabled]="disableIfCancelAndNTU"
                                    [ngClass]="{ 'is-invalid': f.submitted && number_of_claims.invalid }"
                                    #number_of_claims="ngModel" required>
                                    <mat-option *ngFor="let claim of allClaims" [value]="claim.number_of_claims">
                                        {{ claim.number_of_claims + ((claim.number_of_claims < 2)?' Claim':' Claims') }}
                                            </mat-option>
                                </mat-select>
                                <div *ngIf="f.submitted && number_of_claims.invalid" class="invalid-feedback">
                                    Claim is required.
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
            <div class="form_sections" *ngIf="quote.quote_versions[0].alpaca_public_liability_cover == 1">
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            About the Public and Product liability cover
                            <!-- <span (click)="helpTextPopup(content,'Cover against loss arising from claims made against you, caused by or in connection with your trailer, in respect of your legal liability to pay damages (including Claimants’’ costs, fees and expenses) for and/or arising out of accidental: 
                            1.	Bodily injury; <br/>
                            2.	Damage;<br/>
                            3.	Excluding liability incurred whilst the insured Trailer(s) are either attached to a motor towing vehicle or having broken free from the towing vehicle;
                            <br/>
                            Excess: The first £150 of each claim.
                            
                            
                            ')"><i class="mdi mdi-comment-question-outline ml-1"></i></span> -->
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="form-group row">
                        <div class="col-md-5">
                            <label class="text-white f13">What is the acreage of the farm?</label>
                            <input type="text" appNumbers name="acerage" [disabled]="disableIfCancelAndNTU"
                                placeholder="Enter the total acreage of the Smallholding"
                                class="form-control form-control-sm" (blur)="acreageChange($event)"
                                [(ngModel)]="quote.quote_versions[0].alpaca_liability.acerage" #acerage="ngModel"
                                required [ngClass]="{ 'is-invalid': f.submitted && acerage.invalid }">
                            <div *ngIf="f.submitted && acerage.invalid" class="invalid-feedback">
                                Value is required.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-white f13">What is the limit of Public liability cover required?
                            <span (click)="helpTextPopup(content,'Please select below either £1m, £2m or £5m')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-8">
                                <mat-radio-group class="row" required aria-label="Select an option"
                                    name="publicLiabilityCover" #publicLiabilityCover ngmodel
                                    [(ngModel)]="quote.quote_versions[0].alpaca_liability.public_liability_cover"
                                    (change)="onAlpacaLiabilityLimitChange($event)" [disabled]="disableIfCancelAndNTU">
                                    <div class="col-md-4">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="1" name="publicLiabilityCover" ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_liability.public_liability_cover"
                                                required #publicLiabilityCoverControl="ngModel">&pound;1m
                                            </mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="2" name="publicLiabilityCover" ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_liability.public_liability_cover"
                                                required #publicLiabilityCoverControl="ngModel">&pound;2m
                                            </mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="5" name="publicLiabilityCover" ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_liability.public_liability_cover"
                                                required #publicLiabilityCoverControl="ngModel">&pound;5m
                                            </mat-radio-button>
                                        </div>
                                    </div>
                                    <div *ngIf="f.submitted && publicLiabilityCoverControl.invalid"
                                        class="invalid-feedback col-md-12">
                                        Please select an option.
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-white f13">Do the public interact with the animals
                            <span
                                (click)="helpTextPopup(content,'If yes, please select the main activities from the activities below.')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-8">
                                <mat-radio-group class="row" required aria-label="Select an option"
                                    [disabled]="disableIfCancelAndNTU" name="publicInteractionAllowed"
                                    #publicInteractionAllowed ngmodel
                                    [(ngModel)]="quote.quote_versions[0].alpaca_liability.is_public_interaction_allowed">
                                    <div class="col-md-4">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="1" name="publicInteractionAllowed"
                                                ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_liability.is_public_interaction_allowed"
                                                required #publicInteractionAllowedControl="ngModel">Yes
                                            </mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="2" name="publicInteractionAllowed"
                                                ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_liability.is_public_interaction_allowed"
                                                required #publicInteractionAllowedControl="ngModel">No
                                            </mat-radio-button>
                                        </div>
                                    </div>
                                    <div *ngIf="f.submitted && publicLiabilityCoverControl.invalid"
                                        class="invalid-feedback col-md-12">
                                        Please select an option.
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>

                    <div class="form-group"
                        [hidden]="quote.quote_versions[0].alpaca_liability.is_public_interaction_allowed == 2">
                        <label class="text-white f13">What are the main activities undertaken by Smallholding?
                            <span (click)="helpTextPopup(content,'<b>Alpaca/Llama Experience Sessions </b></br>
                                Visitors to your premises for</br>
                                Meet & Greet the animals</br>
                                Walks with the animals on and/or off the insured premises</br>
                                Animals to be on lead reins at all times during walks</br>
                                Maximum of 1 animal per individual during walks</br>
                                
                                <b>Group Visits to the insured premises</b></br>
                                Group visitors such as school pupils, local clubs, special needs children, craft workshops.</br>
                                The visitors will have a tour of the smallholding and observe the animals.  </br>
                                This will not include the visitors walking with the animals unless you have selected the Alpaca/Llama Experience Sessions too.</br>
                                
                                <b>Visits to external venues</b></br>
                                Cover when you are taking your animals to venues such as schools, weddings, hospices, nursing homes and similar venues.</br>
                                This will not include the public walking with the animals unless you have selected the Alpaca/Llama Experience Sessions too.</br>
                                
                                <b>Promoting and selling related products</b></br>
                                Product Liability for the retail sale of products, e.g., dairy, meat, produce, products or fibre.  If you select this activity, you will be contacted to discuss the type of products you sell and your annual turnover from the sale of products.
                                <br/>
                                
                                <b>Attending events with a Trade Stand</b></br>
                                If you attend events - markets, organised shows etc. with a Trade Stand, from as little as a table displaying your products to a large trade stand, you should have Public and Products Liability cover in place.  Almost all Organisers of Events require proof of your insurance, or at least your confirmation that you have such cover in place.
                                ')"><i class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-4" [hidden]="liabilities.is_visible_on_ui == 0"
                                            *ngFor="let liabilities of alpacaLiabiities; let i = index;">
                                            <div class="form-group">
                                                <mat-checkbox name="smallholding_activities_{{i}}"
                                                    [disabled]="disableIfCancelAndNTU"
                                                    (change)="onSmallHoldingActivitiesChange($event, liabilities.id)"
                                                    value="{{liabilities.id}}" [checked]="liabilities.is_checked">
                                                    {{liabilities.policy_cover}}
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
            <div class="form_sections" *ngIf="quote.quote_versions[0].alpaca_trailer_cover == 1">
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            About the Trailer Cover <span (click)="helpTextPopup(content,'<b>Public Liability</b><br/>
                                Cover against loss arising from claims made against you, caused by or in connection with your trailer, in respect of your legal liability to pay damages (including Claimants’’ costs, fees and expenses) for and/or arising out of accidental:</br> 
                                1.	Bodily injury;</br> 
                                2.	Damage;</br> 
                                3.	Excluding liability incurred whilst the insured Trailer(s) are either attached to a motor towing vehicle or having broken free from the towing vehicle;</br> 
                                
                                <b>Excess: </b>The first £150 of each claim.
                                ')"><i class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="form-group row">
                        <div class="col-md-6">
                            <label class="text-white f13">How many trailers are there?
                                <!-- <span (click)="helpTextPopup(content,'Material Damage and Theft<br/>
To pay the current market value, sum insured, or cost of repairs (whichever is the lesser amount) for loss or damage resulting from fire, theft or accidental damage.<br/>
Excess: Material Damage claims:  The first £100 of each claim<br/>
Excess: Theft claims: The first £150 of each claim<br/>

')"><i
                                        class="mdi mdi-comment-question-outline ml-1"></i></span> -->
                            </label>
                            <input type="number" appNumbers name="trailer_cover" placeholder="Total Trailers"
                                class="form-control form-control-sm" [disabled]="disableIfCancelAndNTU"
                                [(ngModel)]="quote.quote_versions[0].trailer_cover.number_of_trailers"
                                #trailer_cover="ngModel" required (blur)="onTotalTrailersChange($event)"
                                [ngClass]="{ 'is-invalid': f.submitted && trailer_cover.invalid }" [min]="1">
                            <div *ngIf="f.submitted && trailer_cover.invalid" class="invalid-feedback">
                                Value is required.
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-6">
                            <label class="text-white f13">What is the total value to be insured? (Max upto £5k sum
                                insured for any 1 trailer)
                                <!-- <span (click)="helpTextPopup(content,'Max upto £5k sum insured for any 1 trailer')"><i
                                        class="mdi mdi-comment-question-outline ml-1"></i></span> -->
                            </label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="text" appNumbers name="trailer_insurance_cover"
                                    placeholder="Enter the total value of the trailer(s)"
                                    [disabled]="disableIfCancelAndNTU" class="form-control form-control-sm"
                                    (blur)=trailerSumInsuredChange($event)
                                    [(ngModel)]="quote.quote_versions[0].trailer_cover.trailer_insurance_cover"
                                    #trailer_insurance_cover="ngModel" required step="50"
                                    [ngClass]="{ 'is-invalid': f.submitted && trailer_insurance_cover.invalid }">
                                <div *ngIf="f.submitted && trailer_insurance_cover.invalid" class="invalid-feedback">
                                    Value is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
            <div class="form-group">
                <div *ngIf="quote_type != 4 && quote_type != 5 && quote_type != 6">
                    <a (click)="backToQuoteTab()" class="btn btn-defaut btn-gray btn-sm">Back</a>
                    <button type="submit" class="btn btn-success btn-sm float-right">Proceed to full details</button>
                </div>
                <div *ngIf="quote_type == 4">
                    <button class="btn btn-success btn-sm mb-5">Cancel</button>
                </div>
                <div *ngIf="quote_type == 5">
                    <button class="btn btn-success btn-sm mb-5">Lapse</button>
                </div>
                <div *ngIf="quote_type == 6">
                    <button class="btn btn-success btn-sm mb-5">Submit for NTU</button>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <app-admin-al-calculations [Calculations]="Calculations" [quoteDetails]="quoteDetails">
            </app-admin-al-calculations>
        </div>
    </div>
</form>

<ng-template #content let-modal>
    <div class="modal-body">
        <div [innerHTML]="helptext"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn {{modelBtnClass}}" (click)="modal.close()">{{modelBtnText}}</button>
    </div>
</ng-template>