import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { GridOptions } from "ag-grid-community";
import { formatDate } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'ngx-webstorage';
import { QuoteVersions } from '../../../quote/alpaca/model/quote/quote_versions.model';
import { Claim } from '../../../model/quote/claim.model';
import { QuoteService } from '../../../quote/quote.service';
import { environment } from '../../../../environments/environment';
import { UserInfoService } from '../../../services/user-info.service';

@Component({
  selector: 'app-al-policy-claims',
  templateUrl: './policy-al-claims.component.html',
  styleUrls: ['../../../quote/quote.component.css'],
})
export class AlPolicyClaimsComponent implements OnInit {
  @Input() quote: QuoteVersions;
  claim = new Claim();
  saveDetailsSection: boolean = false;
  isAdmin: boolean = false;

  constructor(private quoteService: QuoteService, private modalService: NgbModal,
    private _snackBar: MatSnackBar, private sessionSt: LocalStorageService, private userService: UserInfoService) { }

  ngOnInit(): void {
    if (this.sessionSt.retrieve('company_role') != 'Broker') {
      this.isAdmin = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    // GET CLAIMS
    if (this.quote && this.quote.id) {
      this.getAllClaims();
    }
  }

  getAllClaims() {
    this.quoteService.getQuoteClaims(this.quote.id)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.rowData = response.result;
            if (this.sessionSt.retrieve('company_role') == 'Broker') {
              this.rowData.isBroker = true;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // List Quote Versions
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: 'FNOL Date', field: 'fnol_date', sortable: true,
      cellRenderer: function (params) {
        return formatDate(params.data.fnol_date, 'dd-MM-yyyy', 'en-US');
      }
    },
    {
      headerName: 'Loss Date', field: 'loss_date', sortable: true,
      cellRenderer: function (params) {
        return formatDate(params.data.loss_date, 'dd-MM-yyyy', 'en-US');
      }
    },
    { headerName: 'Claim Number', field: 'legacy_claim_number', sortable: true, filter: false },
    {
      headerName: 'Amount', field: 'payment_amount', sortable: true, filter: false,
      cellRenderer: function (params) {
        return '&pound;' + params.data.payment_amount;
      }
    },
    {
      headerName: null,
      width: 130, filter: false,
      cellRenderer: function (params) {
        let links = '';
        if (!params.data.isBroker) {
          links += '<a  title="Edit" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit"></i></a>';
        }
        return links;
      }
    }
  ];
  rowData: any;

  /*
  * GRID BUTTONS ACTION
  */
  onGridRowClicked(e: any) {
    if (e) {
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit": {
          // GET CLAIMS
          this.quoteService.getQuoteClaimsByID(e.data.id)
            .subscribe(
              (response: any) => {
                if (response.success) {
                  this.claim = response.result;
                  this.claim.payment_amount = Math.round(this.claim.payment_amount);

                  this.getAllDocuments();
                  this.saveDetailsSection = true;
                }
              },
              (error) => {
                console.log(error);
              }
            );
          break
        }
      }
    }
  }

  /*
  * Save Claims
  */
  saveClaims() {
    this.claim = new Claim();
    this.saveDetailsSection = true;
  }
  closeSaveClaimsSection() {
    this.saveDetailsSection = false;
  }
  succssMsg: string = '';
  errorMsg: string = '';
  onSubmit(resModal) {
    this.claim.quote_id = this.quote.id;
    this.claim.fnol_date = formatDate(this.claim.fnol_date, 'yyyy-MM-dd', 'en-US');
    this.claim.loss_date = formatDate(this.claim.loss_date, 'yyyy-MM-dd', 'en-US');
    this.quoteService.saveClaims(this.claim)
      .subscribe(
        (response: any) => {   
          this.errorMsg = '';       
          this.succssMsg = '';       
          if (response.success) {
            this.succssMsg = response.message;            
            this.claim.id = response.result.claim.id;
            this.claim.claim_number = response.result.claim.claim_number;
            this.getAllClaims();
          } else {
            this.errorMsg = response.message;   
          }
          this.modalService.open(resModal, { centered: true });
        });
  }

  // Get All Documents
  rowDataDocs: any = [];
  rowDataInv: any = [];
  rowDataCorrespondent: any = [];
  getAllDocuments() {

    this.quoteService.getClaimDocuments(this.claim.id, 9)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.rowDataDocs = [];
            this.rowDataInv = [];
            this.rowDataCorrespondent = [];
            response.result.forEach((res) => {
              if (res.claim_doc_type == '1') {
                this.rowDataDocs.push(res);
              }
              if (res.claim_doc_type == '2') {
                this.rowDataInv.push(res);
              }
              if (res.claim_doc_type == '3') {
                this.rowDataCorrespondent.push(res);
              }
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  gridOptionsDocs: GridOptions = {
    onGridReady: () => {
      this.gridOptionsDocs.api.sizeColumnsToFit();
    }
  };
  gridOptionsInv: GridOptions = {
    onGridReady: () => {
      this.gridOptionsInv.api.sizeColumnsToFit();
    }
  };
  gridOptionsCorrespondent: GridOptions = {
    onGridReady: () => {
      this.gridOptionsCorrespondent.api.sizeColumnsToFit();
    }
  };

  columnDefsDocs = [
    { headerName: 'Document Name', field: 'title', sortable: true, width: 300 },
    {
      headerName: 'Creation Date', sortable: true, filter: true,
      cellRenderer: function (params) {
        return formatDate(params.data.created_at, 'dd-MM-yyyy', 'en-US');
      }
    },
    {
      headerName: null,
      width: 130,
      cellRenderer: function (params) {
        let download_path = params.data.file_path + '/' + params.data.file_name;
        let links = '<a  title="Edit Documents" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit"></i></a>';
        links += '<a class="ml-2" target="_blank" href="' + environment.imageURL + download_path + '" title="Download Document" style="font-size: 18px;color: #138a51;"><i class="mdi mdi-cloud-download"></i></a>';
        links += '<a class="ml-2" title="Delete Documents" style="font-size: 18px;"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete"></i></a>';
        return links;
      }
    }
  ];
  columnDefsInv = [
    { headerName: 'Document Name', field: 'title', sortable: true, width: 300 },
    {
      headerName: 'Creation Date', sortable: true, filter: true,
      cellRenderer: function (params) {
        return formatDate(params.data.created_at, 'dd-MM-yyyy', 'en-US');
      }
    },
    {
      headerName: null,
      width: 130,
      cellRenderer: function (params) {
        let download_path = params.data.file_path + '/' + params.data.file_name;
        let links = '<a  title="Edit Documents" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit"></i></a>';
        links += '<a class="ml-2" target="_blank" href="' + environment.imageURL + download_path + '" title="Download Document" style="font-size: 18px;color: #138a51;"><i class="mdi mdi-cloud-download"></i></a>';
        links += '<a class="ml-2" title="Delete Documents" style="font-size: 18px;"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete"></i></a>';
        return links;
      }
    }
  ];
  columnDefsCorrespondent = [
    { headerName: 'Document Name', field: 'title', sortable: true, width: 300 },
    {
      headerName: 'Creation Date', sortable: true, filter: true,
      cellRenderer: function (params) {
        return formatDate(params.data.created_at, 'dd-MM-yyyy', 'en-US');
      }
    },
    {
      headerName: null,
      width: 130,
      cellRenderer: function (params) {
        let download_path = params.data.file_path + '/' + params.data.file_name;
        let links = '<a  title="Edit Documents" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit"></i></a>';
        links += '<a class="ml-2" target="_blank" href="' + environment.imageURL + download_path + '" title="Download Document" style="font-size: 18px;color: #138a51;"><i class="mdi mdi-cloud-download"></i></a>';
        links += '<a class="ml-2" title="Delete Documents" style="font-size: 18px;"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete"></i></a>';
        return links;
      }
    }
  ];

  // UPLOAd DOCUMENT
  docTitle: string = '';
  fileToUpload: File = null;
  documentsEditMode: boolean = false;
  documentID: string = '';
  claim_doc_type = null;
  uploadDocumentBtn(documents, claim_doc_type) {
    this.claim_doc_type = claim_doc_type;
    this.documentID = '';
    this.docTitle = '';
    this.fileToUpload = null;
    this.documentsEditMode = false;
    this.quote.quote_document = '';
    this.modalService.open(documents, { centered: true });
  }

  getUploadedFile(files) {
    this.fileToUpload = files.item(0);
    this.quote.quote_document = this.fileToUpload.name;
  }

  documentUpload() {
    let formData = new FormData();
    formData.append("file_name", this.fileToUpload);
    formData.append("quote_id", this.quote.id);
    formData.append("claim_id", this.claim.id);
    formData.append("broker_id", this.sessionSt.retrieve('broker_company_id'));
    formData.append("claim_doc_type", this.claim_doc_type);
    formData.append("title", this.docTitle);

    this.quoteService.saveClaimDocument(formData)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          if (response.success) {
            this.quote.quote_document = response.result.file_name;
            if (this.claim_doc_type == 1) {
              this.gridOptionsDocs.rowData.unshift({
                'id': response.result.id,
                'title': response.result.title,
                'quote_version': this.quote.sequence_number,
                'created_at': response.result.created_at,
                'download_path': response.result.file_path + '/' + response.result.file_name,
              });
              this.gridOptionsDocs.api.setRowData(this.gridOptionsDocs.rowData);
              this.gridOptionsDocs.api.refreshCells();
            }
            if (this.claim_doc_type == 2) {
              this.gridOptionsInv.rowData.unshift({
                'id': response.result.id,
                'title': response.result.title,
                'quote_version': this.quote.sequence_number,
                'created_at': response.result.created_at,
                'download_path': response.result.file_path + '/' + response.result.file_name,
              });
              this.gridOptionsInv.api.setRowData(this.gridOptionsInv.rowData);
              this.gridOptionsInv.api.refreshCells();
            }
            if (this.claim_doc_type == 3) {
              this.gridOptionsCorrespondent.rowData.unshift({
                'id': response.result.id,
                'title': response.result.title,
                'quote_version': this.quote.sequence_number,
                'created_at': response.result.created_at,
                'download_path': response.result.file_path + '/' + response.result.file_name,
              });
              this.gridOptionsCorrespondent.api.setRowData(this.gridOptionsCorrespondent.rowData);
              this.gridOptionsCorrespondent.api.refreshCells();
            }
            this._snackBar.open('Document uploaded.', '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open('Unable to upload document.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }
  onGridRowClickedDocs(e: any, documents, documentsDelete, claim_doc_type) {
    if (e) {
      let actionType = e.event.target.getAttribute("data-action-type");
      this.documentID = e.data.id;
      this.docTitle = e.data.title;
      this.claim_doc_type = claim_doc_type;
      switch (actionType) {
        case "edit": {
          this.documentsEditMode = true;
          this.modalService.open(documents, { centered: true });
          break
        }
        case "delete": {
          this.modalService.open(documentsDelete, { centered: true });
          break;
        }
      }
    }
  }

  /*
  * UPDATE DOCUMENT
  */
  updateDocument() {
    let data: any = '';
    data = {
      document_id: this.documentID,
      title: this.docTitle
    };
    this.quoteService.updateDocument(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            if (this.claim_doc_type == 1) {
              this.rowDataDocs.forEach((res) => {
                if (this.documentID == res.id) {
                  res.title = this.docTitle
                }
              });
              this.gridOptionsDocs.api.setRowData(this.rowDataDocs);
              this.gridOptionsDocs.api.refreshCells();
            }
            if (this.claim_doc_type == 2) {
              this.rowDataInv.forEach((res) => {
                if (this.documentID == res.id) {
                  res.title = this.docTitle
                }
              });
              this.gridOptionsInv.api.setRowData(this.rowDataInv);
              this.gridOptionsInv.api.refreshCells();
            }
            if (this.claim_doc_type == 3) {
              this.rowDataCorrespondent.forEach((res) => {
                if (this.documentID == res.id) {
                  res.title = this.docTitle
                }
              });
              this.gridOptionsCorrespondent.api.setRowData(this.rowDataCorrespondent);
              this.gridOptionsCorrespondent.api.refreshCells();
            }
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open('Unable to update title. Please try again later.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
  * Delete Document
  */
  deleteDocument() {
    let data: any = '';
    data = {
      document_id: this.documentID
    };
    this.quoteService.deleteDocument(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            if (this.claim_doc_type == 1) {
              const selectedRow = this.gridOptionsDocs.api.getFocusedCell()
              const id = this.gridOptionsDocs.rowData[selectedRow.rowIndex].i

              this.gridOptionsDocs.rowData.splice(selectedRow.rowIndex, 1)
              this.gridOptionsDocs.api.setRowData(this.gridOptionsDocs.rowData)
            }
            if (this.claim_doc_type == 2) {
              const selectedRow = this.gridOptionsInv.api.getFocusedCell()
              const id = this.gridOptionsInv.rowData[selectedRow.rowIndex].i

              this.gridOptionsInv.rowData.splice(selectedRow.rowIndex, 1)
              this.gridOptionsInv.api.setRowData(this.gridOptionsInv.rowData)
            }
            if (this.claim_doc_type == 3) {
              const selectedRow = this.gridOptionsCorrespondent.api.getFocusedCell()
              const id = this.gridOptionsCorrespondent.rowData[selectedRow.rowIndex].i

              this.gridOptionsCorrespondent.rowData.splice(selectedRow.rowIndex, 1)
              this.gridOptionsCorrespondent.api.setRowData(this.gridOptionsCorrespondent.rowData)
            }
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }
}

