import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';
import { SharedService } from '../../services/shared.service';
declare const sidebar_submenu: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  UserType;
  userInfo = this.sessionSt.retrieve('userInfo');
  isEquine: boolean = false;
  isAlpaca: boolean = false;
  company_id;

  constructor(private router: Router, private sessionSt: LocalStorageService,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    this.UserType = this.sharedService.getUserType();
    this.company_id = this.sessionSt.retrieve('broker_company_id');
    if (this.sessionSt.retrieve('companyProducts')) { 
      if (this.UserType == 2) {
        this.isEquine = true;
        this.isAlpaca = true;
      } else {
        this.sessionSt.retrieve('companyProducts').forEach((res) => {
          if (res.product_id == '5dd8c0bb-b933-4f28-bc70-b5cc47fafb95') {
            this.isEquine = true;
          }
          if (res.product_id == '6dd8c0bb-b933-4f28-bc70-b5cc47fafb95') {
            this.isAlpaca = true;
          }
        })

      }
    }
  }

  submenu(event) {
    const target = event.target || event.srcElement || event.currentTarget;
    const classAttr = target.attributes.class;
    const idAttr = target.attributes.id;
    sidebar_submenu(idAttr.nodeValue);
  }

}
