import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { FormsModule } from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import {DataTablesModule} from 'angular-datatables';
import {MatRadioModule} from '@angular/material/radio'; 
import {MatSliderModule} from '@angular/material/slider';
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import {MatSelectModule} from '@angular/material/select'; 
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';


import { ModalModule } from './_modal';

import { SharedService } from './services/shared.service';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptorService } from './services/interceptors/loader-interceptor.service';

import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SidebarComponent } from './_layouts/sidebar/sidebar.component';
import { CompanyComponent } from './company/company.component';
import { LayoutLoginComponent } from './_layouts/layout-login/layout-login.component';
import { LayoutInnerpagesComponent } from './_layouts/layout-innerpages/layout-innerpages.component';
import { AppRoutingModule } from './app-routing.module';
import { CompanyUpsertComponent } from './company/company-upsert.component';
import { LoaderComponent } from './_layouts/loader/loader.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserComponent } from './user/user.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

import { CustomMaterialModule } from './confirm-dialog/confirm-dialog.module';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';

import {LoginGuard} from './auth/auth.guard';
import { QuoteComponent } from './quote/quote.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FullDetailsComponent } from './quote/full-details/full-details.component';
import {MatDatepickerModule } from '@angular/material/datepicker'; 
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import { IndicativeQuoteComponent } from './quote/indicative-quote/indicative-quote.component';
const moment = _rollupMoment || _moment;
import {MatExpansionModule} from '@angular/material/expansion';
import { FactStatementComponent } from './quote/fact-statement/fact-statement.component'; 
import { AgGridModule } from 'ag-grid-angular';
import { PaymentComponent } from './quote/payment/payment.component';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
import { SubmissionComponent } from './quote/submission/submission.component';
import { QuoteListComponent } from './quote/quote-list/quote-list.component';
import { CalculationsComponent } from './quote/calculations/calculations.component';
import { DecimalNumbersDirective } from './directives/decimal-numbers.directive';
import { TitleCasePipe } from '@angular/common';
import { TitleCaseDirective } from './directives/title-case.directive';
import { DialogComponent } from './_layouts/dialog/dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuoteNewComponent } from './quote/quote-new.component';
import { QuoteDetailsComponent } from './quote/quote-details/quote-details/quote-details.component';
import { OverviewComponent } from './quote/quote-details/overview/overview.component';
import { QuoteFullDetailsComponent } from './quote/quote-details/quote-full-details/quote-full-details.component';
import { UpperCaseDirective } from './directives/upper-case.directive';
import { QuoteSofComponent } from './quote/quote-details/quote-sof/quote-sof.component';
import { FinancialsComponent } from './quote/quote-details/financials/financials.component';
import { DocumentsComponent } from './quote/quote-details/documents/documents.component';
import { NotesComponent } from './quote/quote-details/notes/notes.component';
import { PolicyComponent } from './policy/policy.component';
import { PolicyOverviewComponent } from './policy/policy-overview/policy-overview.component';
import { PolicyFullDetailsComponent } from './policy/policy-full-details/policy-full-details.component';
import { PolicySofComponent } from './policy/policy-sof/policy-sof.component';
import { PolicyFinancialsComponent } from './policy/policy-financials/policy-financials.component';
import { PolicyDocumentsComponent } from './policy/policy-documents/policy-documents.component';
import { PolicyClaimsComponent } from './policy/policy-claims/policy-claims.component';
import { PolicyNotesComponent } from './policy/policy-notes/policy-notes.component';
import { PolicyListComponent } from './policy/policy-list/policy-list.component';
import { BordereauComponent } from './bordereau/bordereau.component';
import { MiComponent } from './dashboard/mi/mi.component';
import { NumbersDirective } from './directives/numbers.directive';
import { PolicyComplianceComponent } from './policy/policy-compliance/policy-compliance.component';

import { AdminPreQualifierComponent } from './quote/alpaca/al-quote/pre-qualifier/pre-qualifier.component';
import { AdminAlQuoteComponent } from './quote/alpaca/al-quote/al-quote.component';
import { AdminAlIndicativeQuoteComponent } from './quote/alpaca/al-quote/al-indicative-quote/al-indicative-quote.component';
import { AdminAlIndicativeQuoteDetailsComponent } from './quote/alpaca/al-quote/al-indicative-quote-details/al-indicative-quote-details.component';
import { AdminAlFullDetailsComponent } from './quote/alpaca/al-quote/al-full-details/al-full-details.component';
import { AdminAlSofComponent } from './quote/alpaca/al-quote/al-sof/al-sof.component';
import { AdminAlQuotationComponent } from './quote/alpaca/al-quote/al-quotation/al-quotation.component';
import { AdminAlPolicyAndPaymentComponent } from './quote/alpaca/al-quote/al-policy-and-payment/al-policy-and-payment.component';
import { AdminAlCalculationsComponent } from './quote/alpaca/al-quote/al-calculations/al-calculations.component';

import { AlQuoteDetailsComponent } from './quote/alpaca/quote-details/quote-details/quote-details.component';
import { AlOverviewComponent } from './quote/alpaca/quote-details/overview/overview.component';
import { AlQuoteFullDetailsComponent } from './quote/alpaca/quote-details/quote-full-details/quote-full-details.component';
import { AlQuoteSofComponent } from './quote/alpaca/quote-details/quote-sof/quote-sof.component';
import { AlFinancialsComponent } from './quote/alpaca/quote-details/financials/financials.component';
import { AlDocumentsComponent } from './quote/alpaca/quote-details/documents/documents.component';
import { AlNotesComponent } from './quote/alpaca/quote-details/notes/notes.component';

import { AlPolicyDetailsComponent } from './policy/alpaca/policy-details/policy-details.component';
import { AlPolicyOverviewComponent } from './policy/alpaca/overview/overview.component';
import { AlPolicyFullDetailsComponent } from './policy/alpaca/policy-full-details/policy-full-details.component';
import { AlPolicySofComponent } from './policy/alpaca/policy-sof/policy-sof.component';
import { AlPolicyFinancialsComponent } from './policy/alpaca/financials/financials.component';
import { AlPolicyDocumentsComponent } from './policy/alpaca/documents/documents.component';
import { AlPolicyNotesComponent } from './policy/alpaca/notes/notes.component';
import { AlPolicyClaimsComponent } from './policy/alpaca/policy-al-claims/policy-al-claims.component';
import { AlPolicyComplianceComponent } from './policy/alpaca/policy-compliance/policy-compliance.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    ForgotPasswordComponent,
    LogoutComponent,
    ResetPasswordComponent,
    SidebarComponent,
    CompanyComponent,
    LayoutLoginComponent,
    LayoutInnerpagesComponent,
    CompanyUpsertComponent,
    LoaderComponent,
    UserComponent,
    UserListComponent,
    ConfirmDialogComponent,
    QuoteComponent,
    FullDetailsComponent,
    IndicativeQuoteComponent,
    FactStatementComponent,
    PaymentComponent,
    SubmissionComponent,
    QuoteListComponent,
    CalculationsComponent,
    DecimalNumbersDirective,
    TitleCaseDirective,
    DialogComponent,
    QuoteNewComponent,
    QuoteDetailsComponent,
    OverviewComponent,
    QuoteFullDetailsComponent,
    UpperCaseDirective,
    QuoteSofComponent,
    FinancialsComponent,
    DocumentsComponent,
    NotesComponent,
    PolicyComponent,
    PolicyOverviewComponent,
    PolicyFullDetailsComponent,
    PolicySofComponent,
    PolicyFinancialsComponent,
    PolicyDocumentsComponent,
    PolicyClaimsComponent,
    PolicyNotesComponent,
    PolicyListComponent,
    BordereauComponent,
    MiComponent,
    NumbersDirective,
    PolicyComplianceComponent,

    AdminPreQualifierComponent,
    AdminAlQuoteComponent,
    AdminAlIndicativeQuoteComponent,
    AdminAlIndicativeQuoteDetailsComponent,
    AdminAlFullDetailsComponent,
    AdminAlSofComponent,
    AdminAlQuotationComponent,
    AdminAlPolicyAndPaymentComponent,
    AdminAlCalculationsComponent,

    AlQuoteDetailsComponent,
    AlOverviewComponent,
    AlQuoteFullDetailsComponent,
    AlQuoteSofComponent,
    AlFinancialsComponent,
    AlDocumentsComponent,
    AlNotesComponent,

    AlPolicyDetailsComponent,
    AlPolicyOverviewComponent,
    AlPolicyFullDetailsComponent,
    AlPolicySofComponent,
    AlPolicyFinancialsComponent,
    AlPolicyDocumentsComponent,
    AlPolicyNotesComponent,
    AlPolicyClaimsComponent,
    AlPolicyComplianceComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    ReactiveFormsModule,
    ModalModule,    
    AppRoutingModule,
    FormsModule,
    MatTabsModule,
    BrowserAnimationsModule,
    DataTablesModule,
    CustomMaterialModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatExpansionModule,
    AgGridModule.withComponents([]),
    MatSliderModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatDialogModule,
    NgbModule
  ],
  providers: [SharedService,ConfirmDialogService,LoaderService,LoginGuard,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},TitleCasePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
