import { Injectable } from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlQuoteService {

  constructor(private sharedService: SharedService, private sessionSt: LocalStorageService) { }

  // Get Agreed Questions
  getAgreedQues(company_id) {
    if (company_id) {
      return this.sharedService.getAPI('staticdata/alpaca_agreed_questions/' + company_id);
    }
  }

  // Get All Static Data
  getStaticData() {
    return this.sharedService.getAPI('staticdata/get_alpaca_static_data');
  }


  // Save Quote
  saveQuote(data) {
    return this.sharedService.postAPI('alpaca/quote', data);
  }
  // POSTCODE LOOKUP
  getAddressPostcodeLookup(postcode) {
    return this.sharedService.getOtherAPI(environment.ADDRESSLOOKUP_API_URL + postcode + '?api-key=' + environment.ADDRESSLOOKUP_API_KEY + '&expand=true&fuzzy=true');
  }

  // Get StatementOFFact Data
  getStatementOfFacts() {
    return this.sharedService.getAPI('staticdata/alpaca_statement_of_fact/' + environment.ALPACA_ID + '/100');
  }

  // Get Quote Details By ID
  getQuoteDetails(quote_id) {
    if (quote_id) {
      return this.sharedService.getAPI('alpaca/quote/details/' + quote_id);
    }
  }

  // Convert To Policy
  convertToPolicy(data) {
    return this.sharedService.postAPI('alpaca/quote/convert_to_policy', data);
  }

  // Get All Brokers
  getBrokers() {
    return this.sharedService.getAPI('companies/allbrokersbyloggedinuser/' + this.sessionSt.retrieve('userInfo').id);
  }

  // Get Notes --- remove later
  getNotes(quote_id) {
    if (quote_id) {
      return this.sharedService.getAPI('quote/notes/list/' + quote_id);
    }
  }

  // Update Quote Status -- remove later
  updateQuoteStatus(data) {
    return this.sharedService.postAPI('quote/status', data);
  }


  /* 
  * Other Common Functions
  */
  // SET FINANCIAL DETAILS IN CASE OF EDIT QUOTE
  setFinancialDetails(quote, alpacaRates, Calculations) {
    // Alpaca Rates
    if (quote.quote_versions[0].alpaca_theft_cover == 1) {
      if (alpacaRates) {
        let rates = alpacaRates.filter(x => x.type == 1);
        if (rates.length > 0) {
          Calculations.age_7_90_rate = Number(quote.quote_versions[0].financial_details.alpaca_financials.cria_rate);
          Calculations.age_7_90_sum_insured = Number(quote.quote_versions[0].about_alpaca.alpaca_7dto90d) * Number(rates[0].value);
          Calculations.age_7_90_total_count = Number(quote.quote_versions[0].about_alpaca.alpaca_7dto90d);
        }
      }

      Calculations.age_above_90_rate = Number(quote.quote_versions[0].financial_details.alpaca_financials.alpaca_rate);
      Calculations.age_above_90_sum_insured = Number(quote.quote_versions[0].about_alpaca.sum_insured);
      Calculations.age_above_90_total_count = Number(quote.quote_versions[0].about_alpaca.alpaca_91dto12y);

      Calculations.age_13_rate = Number(quote.quote_versions[0].financial_details.alpaca_financials.age_13_rate);
      Calculations.age_13_sum_insured = Number(quote.quote_versions[0].about_alpaca.sum_insured_13y);
      Calculations.age_13_total_count = Number(quote.quote_versions[0].about_alpaca.alpaca_13y);

      Calculations.age_14_rate = Number(quote.quote_versions[0].financial_details.alpaca_financials.age_14_rate);
      Calculations.age_14_sum_insured = Number(quote.quote_versions[0].about_alpaca.sum_insured_14y);
      Calculations.age_14_total_count = Number(quote.quote_versions[0].about_alpaca.alpaca_14y);

      Calculations.male_economic_slaughter_rate = Number(quote.quote_versions[0].financial_details.alpaca_financials.economic_slaughter_rate);
      Calculations.male_economic_slaughter_sum_insured = Number(quote.quote_versions[0].about_alpaca.economic_slaughter_sum_insured);

      Calculations.life_saving_surgical_fees = Number(quote.quote_versions[0].financial_details.alpaca_financials.life_saving_rate);
      Calculations.veterinary_fees = Number(quote.quote_versions[0].financial_details.alpaca_financials.veterinary_rate);

      // Claim
      Calculations.claim_loading_percentage = Number(quote.quote_versions[0].financial_details.alpaca_financials.claim_loading_percentage);
    }

    // Public Liability
    if (quote.quote_versions[0].alpaca_public_liability_cover == 1) {
      //Acrage
      if (quote.quote_versions[0].alpaca_liability) {
        Calculations.acrage = quote.quote_versions[0].alpaca_liability.acerage;
      }
      // Smallholding Activities
      Calculations.alpaca_experience_minimum_premium = Number(quote.quote_versions[0].financial_details.alpaca_financials.minimum_alpaca_premium);
      Calculations.alpaca_excess_acrage = Number(quote.quote_versions[0].financial_details.alpaca_financials.minimum_excess_acerage);
      Calculations.visit_insured_premises = Number(quote.quote_versions[0].financial_details.alpaca_financials.visit_insured_premises_premium);
      Calculations.visit_to_extrenal_venues = Number(quote.quote_versions[0].financial_details.alpaca_financials.visit_to_external_premises_premium);
      Calculations.promoting_selling_related_products = Number(quote.quote_versions[0].financial_details.alpaca_financials.promoting_and_selling_premium);
      Calculations.trade_stand = Number(quote.quote_versions[0].financial_details.alpaca_financials.visit_to_trade_stand_premium);

      // Liability Limits
      Calculations.basic_liabilty_minimum_premium = Number(quote.quote_versions[0].financial_details.alpaca_financials.minimum_basic_liability);
      //this.setLiabilityLimitValues();
    }

    //Trailer
    if (quote.quote_versions[0].alpaca_trailer_cover == 1) {
      Calculations.trailer_rate = Number(quote.quote_versions[0].financial_details.alpaca_financials.trailer_rate);
      Calculations.trailer_minimum_premium = Number(quote.quote_versions[0].financial_details.alpaca_financials.trailer_minimum_basic_premium);
      if (quote.quote_versions[0].trailer_cover) {
        Calculations.trailer_sum_insured = Number(quote.quote_versions[0].trailer_cover.trailer_insurance_cover);
      }
    }

    Calculations.ipt_charges = Number(quote.quote_versions[0].financial_details.ipt_fee);
    Calculations.ipt_amount = Number(quote.quote_versions[0].financial_details.ipt_value);
    Calculations.administrative_charges = Number(quote.quote_versions[0].financial_details.uw_fee);
    Calculations.calculate_data(quote);
  }
}

