<form #f="ngForm" (ngSubmit)="f.form.valid && onSubmit(f)">
    <input type="hidden" name="id" placeholder="id"
                        class="form-control form-control-sm"
                        [(ngModel)]="quote.id"
                        >
    <div class="row">
        <div class="col-md-8">

            <div class="bg-white text-green p-5 mb-5 text-center payment">
                <img src="assets/images/invoice.webp" width="100" alt="invoice" />
                <h5 class="mt-3">BORDEREAUX INVOICE</h5>
                <br>
                <p>If Cover is selected by you following the Peliwica underwriting process, the policy will be added to your next bordereaux and invoiced at the end of the month, payment terms are 30 days. </p>

            </div>
            <div class="form-group">
                <label>Notes</label>
                <textarea class="form-control" name="convert_to_quote_notes" required
                [(ngModel)]="quote.quote_versions[0].convert_to_quote_notes" #convert_to_quote_notes="ngModel" 
                [ngClass]="{ 'is-invalid': f.submitted && convert_to_quote_notes.invalid }"></textarea>
                <div *ngIf="f.submitted && convert_to_quote_notes.invalid" class="invalid-feedback">
                    Notes are required.
                </div>
            </div>
            <div class="form-group">
                <a (click)="backToFactStatementBtn()" class="btn btn-default btn-gray mr-3">Back</a>
                <input type="submit" name="proceed" class="btn btn-success btn-gray" value="Submit for Underwriting">
            </div>
        </div>
        <div class="col-md-4">
            <app-calculations [quoteInfo]="quoteDetails"
                [indicativeCalculationsDetails]="indicativeCalculationsDetails"></app-calculations>
        </div>
    </div>

</form>