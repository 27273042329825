import { QuoteVersions } from './quote_versions.model';
import { AlCalculationsDetails } from '../../al-quote/al-calculations/al-calculations.model';

export class Quote {
    id: string = '';
    quote_id: string = '';   
    total_animals: number = 0;    
    company_id: string = '';       
    insurance_status_id: string = 'e216564f-552a-4e45-ae67-0a63b7074691';    
    status: number = 0;
    insurer:string = '';    
    policy_cover_index: string = '';     
    quote_document = 'Choose file';   

    quote_versions:Array<QuoteVersions> = [new QuoteVersions()];
    calculations = new AlCalculationsDetails();
    
}
