<div class="card-header bg-success">
    <div class="row">
        <div class="col-md-6">
            <h3 class="text-white">Policy: {{quoteData.quote_versions[0].policy_ref_number}}</h3>
        </div>
        <div class="col-md-6"> 
            <div class="align-items-center justify-content-between float-right" *ngIf="quoteData.quote_versions[0].insurance_status_id != '7a877243-0919-4f92-8c35-6f5732013ca6' && quoteData.quote_versions[0].insurance_status_id != '0c836e51-f8b0-4df8-8736-3ce56a40869f'">
                <ul class="navbar-nav navbar-nav-right mr-3" *ngIf="latestVersion == quoteData.quote_versions[0].sequence_number">
                    <li class="nav-item nav-profile dropdown">
                        <a class="nav-link dropdown-toggle p-0 text-white" href="#" data-toggle="dropdown" id="profileDropdown">
                            Actions
                        </a>
                        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                            <a (click)="convert_to_mta(content,3)" class="dropdown-item">
                                <i class="mdi mdi-account-convert text-primary"></i>
                                Convert to MTA
                            </a>
                            <!-- <a (click)="convert_to_mta(content,2)" class="dropdown-item">
                                <i class="mdi mdi-autorenew text-primary"></i>
                                Renewal
                            </a> -->
                            <a (click)="convert_to_mta(content,4)" class="dropdown-item">
                                <i class="mdi mdi-delete-variant text-primary"></i>
                                Cancel
                            </a>
                            <a (click)="convert_to_mta(content,5)" class="dropdown-item" *ngIf="quoteData.quote_versions[0].type == 2">
                                <i class="mdi mdi-delete text-primary"></i>
                                Lapse 
                            </a> 
                            <a (click)="convert_to_mta(content,6)" class="dropdown-item" 
                                *ngIf="quoteData.quote_versions[0].type == 1 && is_NTU">
                                <i class="mdi mdi-arrange-bring-forward"></i>
                                NTU 
                            </a>
                            
                        </div>
                    </li>
                </ul>
                <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="horizontal-menu-toggle">
                    <span class="mdi mdi-menu"></span>
                </button>
            </div>
        </div>   
    </div>
</div>
<mat-tab-group class="float-left w-100">
    <mat-tab>
        <ng-template mat-tab-label>
            Overview
        </ng-template>
        <div class="card-body">
            <app-policy-overview [quoteData]="quoteData"></app-policy-overview>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Full Details
        </ng-template>
        <div class="card-body">
            <app-policy-full-details [quote]="quoteData" [staticData]="staticData"></app-policy-full-details>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Statement of Fact
        </ng-template>
        <div class="card-body">
            <app-policy-sof [quote]="quoteData"></app-policy-sof>
        </div>
    </mat-tab>
    <mat-tab *ngIf="!isBroker">
        <ng-template mat-tab-label>
            Financials
        </ng-template>
        <div class="card-body"> 
            <app-policy-financials [quote]="quoteData"></app-policy-financials>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Documents
        </ng-template>
        <div class="card-body">
            <app-policy-documents [quote]="quoteData"></app-policy-documents>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Claims
        </ng-template>
        <div class="card-body">
            <app-policy-claims [quote]="quoteData"></app-policy-claims>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Notes <span *ngIf="hasImportantNotes" class="ml-1" style="color: #ff0000" title="{{totalImpNotes}} Important Notes"><i class="mdi mdi-alert-circle"></i></span>
        </ng-template>
        <div class="card-body">
            <app-policy-notes [quote]="quoteData" (hasImportantNotes)="onhasImportantNotesChange($event)"></app-policy-notes>
        </div>
    </mat-tab>
    <mat-tab *ngIf="!isBroker">
        <ng-template mat-tab-label>
            PEP & Sanctions 
        </ng-template>
        <div class="card-body">
            <app-policy-compliance [quote]="quoteData"></app-policy-compliance>
        </div>
    </mat-tab>
</mat-tab-group>
<ng-template #content let-modal>
    <div class="modal-body">
        <p>{{mtaMsg}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="convert_to_mta(content,3,1)">Yes</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">No</button>
    </div>
</ng-template>
