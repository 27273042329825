<h3>Documents 
    <button (click)="openDocumentsPopup(content)" [hidden]="isPolicy" class="btn btn-success btn-sm ml-3">Add Document</button>
    <button (click)="regenerate_schedule(reSchedule)" class="btn btn-gray text-white btn-sm ml-3">Regenerate
        Schedule</button>
</h3>

<ag-grid-angular
    style="width: 100%; height: 400px;"
    class="ag-theme-bootstrap"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    animateRows
    pagination
    paginationPageSize = 10
    rowHeight = 27
    headerHeight=35
    (rowClicked)='onGridRowClicked($event,content,documentsDelete)' 
    >
</ag-grid-angular>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h4>Document</h4>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label>Title</label>
                <input type="text" name="document_title" [(ngModel)]="docTitle" class="form-control form-control-sm">
            </div>
            <div class="form-group" *ngIf="!documentsEditMode">
                <label>Document</label> 
                <div class="custom-file">
                    <input type="file" name="file_name" (change)="getUploadedFile($event.target.files)"
                        class="custom-file-input" id="customFile">
                    <label class="custom-file-label" for="customFile">{{quote.quote_document}}</label>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" *ngIf="!documentsEditMode" class="btn btn-success" (click)="documentUpload()">Upload</button>
        <button type="button" *ngIf="documentsEditMode" class="btn btn-success" (click)="updateDocument()">Update</button>
            <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
        </div>
    </ng-template>
    <ng-template #documentsDelete let-modal>
        <div class="modal-header">
            <h4>Delete Document</h4>
        </div>
        <div class="modal-body text-center">
            <img src="assets/images/deactivate.png" width="50"><br>
            Are you sure you want to delete document {{docTitle}}?
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="deleteDocument()">Yes</button>
            <button type="button" class="btn btn-default" (click)="modal.close()">No</button>
        </div>
    </ng-template>
    <ng-template #reSchedule let-modal>
        <div class="modal-body">
            {{schMsg}}
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default" (click)="modal.close()">OK</button>
        </div>
    </ng-template>