import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AlQuoteService } from '../al-quote.service';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../../../../environments/environment';
import { QuoteService } from '../../../quote.service';
import { UserInfoService } from '../../../../services/user-info.service';

@Component({
  selector: 'app-admin-pre-qualifier',
  templateUrl: './pre-qualifier.component.html',
  styleUrls: ['../../../quote.component.css']
})
export class AdminPreQualifierComponent implements OnInit {
  agreedQuestions = [];

  constructor(private quoteService: AlQuoteService, private route: ActivatedRoute, private router: Router,
    private sessionSt: LocalStorageService, private adminQuoteService: QuoteService, private userService: UserInfoService) { }

  ngOnInit(): void {
    this.userService.getUserDetails();
    // GET AGREED QUESTIONS
    this.adminQuoteService.getAgreedQues(environment.ALPACA_ID)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.agreedQuestions = response.result.agreed_questions;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  agreedBtnAction() {
    if (this.route.snapshot.params.quote_id) {
      if (this.route.snapshot.params.quote_type) {
        this.router.navigate(['/alpaca-admin/quote', this.route.snapshot.params.quote_id,this.route.snapshot.params.quote_type]);
      } else {
        this.router.navigate(['/alpaca-admin/quote', this.route.snapshot.params.quote_id]);
      }
    } else {
      this.router.navigate(['/alpaca-admin/quote']);
    }

  }
}
